import React, { Component } from 'react';
import { connect } from 'react-redux';
import authService from 'services/api/authService';
import ConsentContainer from 'containers/pages/public/ConsentContainer/ConsentContainer';
import api from 'services/api/api';
import _ from 'lodash';

export class GuardComponent extends Component {
  doForceUpdate() {
    this.forceUpdate();
  }

  render() {
    const { children, _currentUser } = this.props;
    if (
      _currentUser &&
      (_currentUser.isConsentPending == true || _currentUser.isABNConsentPending == true)
    ) {
      return <ConsentContainer />;
    } 
    return <div>{children}</div>;
  }
}

export default connect(
  state => {
    const _currentUser = authService.getCurrentUser(state);
    return { _currentUser };
  },
  dispatch => {
    return { dispatch };
  }
)(GuardComponent);
