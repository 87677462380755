import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import Button from '../../controls/ButtonV2';
import InputField from '../../controls/V2/InputField';
import InputMaskField from '../../controls/V2/InputMaskField';
import RadioCardField from '../../controls/V2/RadioCardField';
import { Tooltip } from 'gm-element-react';
import { required, shouldValidABNOption, todayDate, sameValue } from 'services/validate';
import gaTrack, { GA_TR_ABN_PAGE } from 'services/gaTrack';
import 'element-theme-default';
import './ABNForm.scss';

const validateOptionRequired = required('Option');
const validShouldValidABNOption = shouldValidABNOption();
const validateDateRequired = required('Date');
const validateTodayDate = todayDate(
  "Date is incorrect. Please enter today's date in MM/DD/YYYY format",
  'MM/DD/YYYY'
);
const validateSignatureRequired = required('signature');

const validate = (values) => {
  const {
    isPatient,
    isNotPatient,
    firstName,
    lastName,
    guardianFirstName,
    guardianLastName,
    abnSignature,
  } = values;
  const errors = {};
  const hasPatient = isPatient || !isNotPatient;
  const guardianFullName = hasPatient
    ? `${firstName || ''} ${lastName || ''}`
    : `${guardianFirstName || ''} ${guardianLastName || ''}`;
  if (_.toLower(guardianFullName) !== _.toLower(abnSignature)) {
    errors['abnSignature'] = "The signature doesn't look right";
  }
  return errors;
};

const ABNForm = ({handleSubmit, onSubmit, fullName, valid, submitting,goBack,touch}) => {

  useEffect(()=>{
    window.scrollTo(0, 0);
    gaTrack(GA_TR_ABN_PAGE);
  },[]);

  const getABNVariants=()=> {
    const ABNVariantsObject = {
      option1: {
        label: 'Option 1',
        value: 'option1',
        description: (
          <div>
            I want the Genetic Counseling listed above. You may ask to be paid now, but I also want
            Medicare billed for an official decision on payment, which is sent to me on a Medicare
            Summary Notice (MSN). I understand that if Medicare doesn’t pay, I am responsible for
            payment, but I can appeal to Medicare by following the directions on the MSN.{' '}
            <strike>
              If Medicare does pay, you will refund any payments I made to you, less co-pays or
              deductibles.
            </strike>
          </div>
        ),
      },
      option2: {
        label: 'Option 2',
        value: 'option2',
        description: (
          <div>
            I want the Genetic Counseling listed above, but do not bill Medicare. You may ask to be
            paid now as I am responsible for payment. I cannot appeal if Medicare is not billed.
          </div>
        ),
      },
      option3: {
        label: 'Option 3',
        value: 'option3',
        description: (
          <div>
            I don’t want the Genetic Counseling listed above. I understand with this choice I am not
            responsible for payment, and I cannot appeal to see if Medicare would pay.
          </div>
        ),
      },
    };

    let ABNVariants = [];
    ABNVariants.push(ABNVariantsObject['option1']);
    ABNVariants.push(ABNVariantsObject['option2']);
    ABNVariants.push(ABNVariantsObject['option3']);
    return ABNVariants;
  }

  return (
    <section className="abn-container">
      <section className="abn-wrapper">
        <section className="abn-header">
          <div className="genome-header-logo">
            <img className="genome-header-img" src={require('images/logo.svg')} />
          </div>
        </section>
        <form
          className="abn-form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <section className="abn-body">
            <section className="web">
              <div className="form-row">
                <div className="form-label">
                  A. Notifier: <span>Genome Medical</span>
                </div>
              </div>
              <div className="form-row">
                <div className="form-col">
                  <div className="form-label" data-hj-suppress>
                    B. Patient Name: <span>{fullName}</span>
                  </div>
                </div>
                <div className="form-col right">
                  <div className="form-label">
                    C. Identification Number: <span>N/A</span>
                  </div>
                </div>
              </div>
            </section>
            <h3>Advance Beneficiary Notice of Noncoverage (ABN)</h3>
            <p className="note">
              <b>NOTE</b>
            </p>
            <p className="note">
              If Medicare doesn’t pay for D. Genetic Counseling below, you may have to pay.
              Medicare does not pay for everything, even some care that you or your health care
              provider have good reason to think you need. We expect Medicare may not pay for the{' '}
              Genetic Counseling below.
            </p>
            <div className="abn-table">
              <div className="col-sec first">
                <div className="abn-cell">
                  {' '}
                  <b>D.</b>
                </div>
                <div className="abn-cell second">
                  <span>Genetic Counseling provided by a Genetic Counselor via Telehealth</span>
                </div>
              </div>
              <div className="col-sec second">
                <div className="abn-cell">
                  {' '}
                  <b>E. Reason Medicare May Not Pay:</b>
                </div>
                <div className="abn-cell second">
                  <span>
                    Medicare does not cover genetic counseling provided by a Genetic Counselor.
                  </span>
                  <br />
                  <span>Medicare also may not cover Telemedicine</span>
                </div>
              </div>
              <div className="col-sec third">
                <div className="abn-cell">
                  {' '}
                  <b>F. Estimated Cost</b>
                </div>
                <div className="abn-cell second">
                  <span>No more than $179 per session</span>
                </div>
              </div>
            </div>
            <p>
              <b>WHAT YOU NEED TO DO NOW:</b>
            </p>
            <ol>
              <li>Read this notice, so you can make an informed decision about your care.</li>
              <li>Ask us any questions that you may have after you finish reading.</li>
              <li>
                Choose an option below about whether to receive the Genetic Counseling listed
                above.
              </li>
            </ol>
            <p>
              <b className="medium">
                {' '}
                Note: If you choose Option 1 or 2, we may help you to use any other insurance that
                you might have, but Medicare cannot require us to do this.
              </b>
            </p>

            <section className="sub-sec">
              <h6>G. OPTIONS: </h6>
              <h5>Check only one box. We cannot choose a box for you.</h5>
              <Field
                name="abnOption"
                component={RadioCardField}
                children={getABNVariants()}
                onChange={() => touch('abnoption')}
                validate={[validateOptionRequired, validShouldValidABNOption]}
              />
            </section>
            <section className="sub-sec">
              <p className="additional">
                <b>H. Additional Information: </b>This supplier doesn’t accept payment from
                Medicare for the item(s) listed in the table above. If I checked Option 1 above, I
                am responsible for paying the supplier’s charge for the item(s) directly to the
                supplier. If Medicare does pay, Medicare will pay me the Medicare-approved amount
                for the item(s), and this payment to me may be less than the supplier’s charge.
              </p>
            </section>
            <section className="sub-sec">
              <p className="additional">
                This notice gives our opinion, not an official Medicare decision. If you have
                other questions on this notice or Medicare billing, call 1-800-MEDICARE (
                <a href="tel:+18006334227">1-800-633-4227</a>/TTY:
                <a href="tel:+18774862048">1-877-486-2048</a>).
              </p>
              <p className="additional" style={{ marginTop: '8px' }}>
                Signing below means that you have received and understand this notice. You also
                receive a copy.
              </p>
            </section>
            <section className="sub-sec">
              <div className="form-row signature-form">
                <div className="form-col">
                  <div className="form-lbl required" style={{ display: 'inline-flex' }}>
                    Signature
                    <Tooltip
                      className="signature-tooltip"
                      effect="dark"
                      content={<span>Please type in your full name</span>}
                      placement="top"
                    >
                      <img className="arrow-left" src={require('images/more-info.svg')} />
                    </Tooltip>
                  </div>
                  <div className="form-input">
                    <Field
                      name="abnSignature"
                      type="text"
                      placeholder="Full Name"
                      trim={true}
                      component={InputField}
                      validate={[validateSignatureRequired]}
                    />
                  </div>
                </div>
                <div className="form-col">
                  <div className="form-lbl required" style={{ display: 'inline-flex' }}>
                    Date
                    <Tooltip
                      className="signature-tooltip"
                      effect="dark"
                      content={<span>Please enter today's date in MM/DD/YYYY format</span>}
                      placement="top"
                    >
                      <img className="arrow-left" src={require('images/more-info.svg')} />
                    </Tooltip>
                  </div>
                  <div className="form-input">
                    <Field
                      name="abnDate"
                      placeholder="MM/DD/YYYY"
                      component={InputMaskField}
                      mask="99/99/9999"
                      maskChar={null}
                      validate={[validateDateRequired, validateTodayDate]}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="sub-sec">
              <p className="cms">
                CMS does not discriminate in its programs and activities. To request this
                publication in an alternative format, please call: 1-800-MEDICARE or email:{' '}
                <a href="mailto:AltFormatRequest@cms.hhs.gov">AltFormatRequest@cms.hhs.gov.</a>
              </p>
            </section>
            <section className="sub-sec">
              <p className="notice">
                {' '}
                According to the Paperwork Reduction Act of 1995, no persons are required to
                respond to a collection of information unless it displays a valid OMB control
                number. The valid OMB control number for this information collection is 0938-0566.
                The time required to complete this information collection is estimated to average
                7 minutes per response, including the time to review instructions, search existing
                data resources, gather the data needed, and complete and review the information
                collection. If you have comments concerning the accuracy of the time estimate or
                suggestions for improving this form, please write to: CMS, 7500 Security
                Boulevard, Attn: PRA Reports Clearance Officer, Baltimore, Maryland 21244-1850.
              </p>
            </section>
          </section>
          <section className="abn-footer">
            <div className="form-row">
              <div className="form-col">
                <div className="form-label">Form CMS-R-131 (Exp. 06/30/2023)</div>
              </div>
              <div className="form-col">
                <div className="form-label">Form Approved OMB No. 0938-0566</div>
              </div>
            </div>
            <div className="buttons-containers">
              <div className="rights-buttons">
                <Button type="submit" className="agree-button" disabled={submitting || !valid}>
                  I Agree and Continue
                </Button>
                <a
                  className="button-back"
                  key="consent-back"
                  onClick={() => goBack(valid)}
                >
                  Go Back
                </a>
              </div>
            </div>
          </section>
        </form>
      </section>
    </section>
  );
}

ABNForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
};

const reduxABNForm = reduxForm({
  form: 'sign-up-form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(ABNForm);

// Decorate with connect to read form values
const selector = formValueSelector('sign-up-form'); // <-- same as form name
export default connect((state) => {
  const {
    firstName,
    lastName,
    isPatient,
    guardianFirstName,
    guardianLastName,
    isPatientUnborn,
    isNotPatient,
  } = selector(
    state,
    'firstName',
    'lastName',
    'isPatient',
    'guardianFirstName',
    'guardianLastName',
    'isPatientUnborn',
    'isNotPatient'
  );
  const hasPatient = isPatient || !isNotPatient;
  return {
    fullName: isPatientUnborn
      ? `Baby ${guardianLastName || ''}`
      : `${firstName || ''} ${lastName || ''}`,
    guardianFullName: hasPatient
      ? `${firstName || ''} ${lastName || ''}`
      : `${guardianFirstName || ''} ${guardianLastName || ''}`,
  };
})(reduxABNForm);
