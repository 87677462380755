import { isEmpty, get } from 'lodash';

export function dehydrateSignUp(params) {
  if (params.soft_registration_uuid) {
    const values = {
      email: params.email,
      password: params.password,
      timezone: params.timezone,
      gender: params.sex,
      phones: params && params.primaryPhone && patientPhones(params),
      dob: params && params.dob,
      first_name: params.firstName,
      last_name: params.lastName,
      partner_id: params.partnerId,
      consent_to_treat: params.consentToTreat,
      reason_for_visit: params.areaInterest,
      warm_referral_src: params.warm_referral_src,
      warm_referral_url: params.warm_referral_url,
      soft_registration_uuid: params.soft_registration_uuid,
      has_guardian: false,
      sex_assigned_at_birth: params.sexAssignedAtBirth,
    };
    return values;
  } else {
    const isGuardian = params.isPatient ? false : true;
    const isPatientUnborn = params.isPatientUnborn || false;

    let usersGuardian;
    if (isGuardian || isPatientUnborn) {
      usersGuardian = {
        first_name: params.guardianFirstName,
        last_name: params.guardianLastName,
        phones: params.guardianPrimaryPhone && guardianPhones(params),
        relationship: params.guardianRelationship,
        preferred_name: params.guardianPreferredName,
        pronouns: params.guardianPronouns,
      };

      if (isGuardian && isPatientUnborn) usersGuardian.dob = params.guardianDob;
    }

    const values = {
      email: params.email,
      password: params.password,
      timezone: params.timezone,
      gender: isGuardian && isPatientUnborn ? undefined : params.sex,
      phones: params && params.primaryPhone && patientPhones(params),
      dob: params && params.dob,
      first_name: isGuardian && isPatientUnborn ? 'Baby' : params.firstName,
      last_name: isGuardian && isPatientUnborn ? params.guardianLastName : params.lastName,
      partner_id: params.partnerId,
      consent_to_treat: params.consentToTreat,
      reason_for_visit: params.areaInterest,
      warm_referral_src: params.warm_referral_src,
      warm_referral_url: params.warm_referral_url,
      has_guardian: isGuardian,
      is_unborn: isPatientUnborn,
      users_guardian: usersGuardian,
      is_medicare_beneficiary: params.isMedicareBeneficiary,
      abn_option: params.isMedicareBeneficiary ? params.abnOption : undefined,
      abn_signature: params.isMedicareBeneficiary ? params.abnSignature : undefined,
      abn_date: params.isMedicareBeneficiary ? params.abnDate : undefined,
      preferred_name: params.preferredName,
      pronouns: params.pronouns,
      sex_assigned_at_birth: params.sexAssignedAtBirth,
      race: params.race,
      ethnicity: params.ethnicity,
      secondary_races: params.secondary_races,
    };

    if (params.eligibility) {
      values.eligibility_token = params.eligibility.eligibility_token;
      values.eligibility_data = params.eligibility.additionalData;
    }
    if (params.selection) {
      values.selection_token = params.selection.selection_token;
      values.selection_data = params.selection.additionalData;
    }

    return values;
  }
}

export function patientPhones(params) {
  let patientPhones = [];
  if (!isEmpty(get(params, 'primaryPhone', ''))) {
    patientPhones.push({
      category: 'primary',
      type: get(params, 'primaryPhoneType', null),
      value: get(params, 'primaryPhone', null),
      consent_to_text: get(params, 'primaryPhoneConsentToText', false),
    });
  }
  if (!isEmpty(get(params, 'secondaryPhone', ''))) {
    patientPhones.push({
      category: 'secondary',
      type: get(params, 'secondaryPhoneType', null),
      value: get(params, 'secondaryPhone', null)
    });
  }
  return !isEmpty(patientPhones) ? patientPhones : undefined;
}

export function guardianPhones(params) {
  let guardianPhones = [];
  if (!isEmpty(get(params, 'guardianPrimaryPhone', ''))) {
    guardianPhones.push({
      category: 'primary',
      type: get(params, 'guardianPrimaryPhoneType', null),
      value: get(params, 'guardianPrimaryPhone', null),
      consent_to_text: get(params, 'guardianPrimaryPhoneConsentToText', false) || false,
    });
  }
  if (!isEmpty(get(params, 'guardianSecondaryPhone', ''))) {
    guardianPhones.push({
      category: 'secondary',
      type: get(params, 'guardianSecondaryPhoneType', null),
      value: get(params, 'guardianSecondaryPhone', null),
    });
  }
  return !isEmpty(guardianPhones) ? guardianPhones : undefined;
}

export function dehydrateSignIn(params) {
  return {
    email: params.email,
    password: params.password,
    partner_id: params && params.partnerId,
    check_user_exist: params && params.checkUserExist,
  };
}


export function dehydrateSoftSignIn(params) {
  return {
    email: params.email,
    first_name: params.firstName,
    last_name: params.lastName,
    phone: params.phone,
  };
}

export function dehydrateSetPassword(params) {
  return {
    token: params.token,
    password: params.password,
    abn_signature: params.abnSignature ? params.abnSignature : undefined,
    abn_date: params.abnDate ? params.abnDate : undefined,
  };
}
