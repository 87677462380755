import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import './LoginPage.scss';
import { connect } from 'react-redux';
import { login } from 'ducks/auth/email';
import track, { TR_USER_LOGIN, TR_USER_VISIT_LOGIN_PAGE } from 'services/track';
import LoginForm from './LoginForm';
import gaTrack, { GA_TR_USER_LOGIN_PAGE } from '../../../../services/gaTrack';
import api from '../../../../services/api/api';

const LoginPage = ({ dispatch, location, history }) => {

  useEffect(() => {
    if (api.hasToken()) {
      history.push('/patient/home');
    }

    track(TR_USER_VISIT_LOGIN_PAGE);
    gaTrack(GA_TR_USER_LOGIN_PAGE);
  }, []);

  const initialValues = (() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    if (email) {
      return { email };
    }
    return {};
  })();

  const onLogin = (data) => {
    track(TR_USER_LOGIN, { email: data['email'] });
    return dispatch(login(data));
  };

  return (
    <div className="login-page">
      <div className="form-wrapper">
        <i className="genome-logo" />
        <span className="login-title">Patient Sign-in</span>
        <div className="login-desc">Access your Genome Medical account by signing in below</div>

        <LoginForm
          onSubmit={onLogin}
          initialValues={initialValues}
        />
      </div>
    </div>
  );

};

LoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default connect()(withRouter(LoginPage));
