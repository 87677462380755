
import React, { useState } from 'react';
import _ from 'lodash';
import CreateAccountPage from './CreateAccountPage';
import SchedulingIntroPage from './SchedulingIntroPage';

const SCHEDULEINTROPAGE = "SCHEDULEINTROPAGE";
const CREATEACCOUNTPAGE = "CREATEACCOUNTPAGE";


const CreateAccountContainer = props => {
  const [activeStage, setActiveStage]= useState(SCHEDULEINTROPAGE);

    return (
      <>
        {activeStage == SCHEDULEINTROPAGE &&
          <SchedulingIntroPage
          setActiveStage={()=>setActiveStage(CREATEACCOUNTPAGE)}
          />}
        {activeStage == CREATEACCOUNTPAGE &&
          <CreateAccountPage {...props} />}
      </>

    );
}


export default CreateAccountContainer
