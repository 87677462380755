import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Tooltip } from 'gm-element-react';
import { Field, reduxForm } from 'redux-form';
import { required, numberOnly, Inches } from 'services/validate';
import Button from 'components/forms/controls/ButtonV2';
import RadioButtonField from 'components/forms/controls/V2/RadioButtonField';
import InputField from 'components/forms/controls/V2/InputField';
import Alert from 'components/widgets/Alert/Alert';
import 'element-theme-default';

const current_smoking_status = [
  { label: 'Current every day smoker', value: 'Current every day smoker' },
  { label: 'Current some day smoker', value: 'Current some day smoker' },
  {
    label: 'Former smoker, quit within the last 10 years',
    value: 'Former smoker, quit within the last 10 years',
  },
  {
    label: 'Former smoker, quit more than 10 years ago',
    value: 'Former smoker, quit more than 10 years ago',
  },
  { label: 'Never smoker', value: 'Never smoker' },
];

const validateRequiredFeet = required('Enter feet', true);
const validateNumberOnly = numberOnly('Feet should be numeric');
const validateRequiredInches = required('Enter inches', true);
const validateInchesNumberOnly = numberOnly('Inches should be numeric');
const validateInches = Inches('Inches should be between 0-11.');
const validateRequiredPounds = required('Enter pounds', true);
const validateWeightNumberOnly = numberOnly('Weight should be numeric');

const validateRequired = required();

class GrailQualificationQnAFrom extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { handleSubmit, submitting, formError, valid, affiliationIsIllumina } = this.props;
    return (
      <section className="grail-quali-container">
        <section className="grail-quali-wrapper">
          <section className="grail-quali-header">
            {affiliationIsIllumina && <Button path="/patient/home">&lt; Back to Home Page</Button>}
            <div className="genome-header-logo">
              <img className="genome-header-img" src={require('images/galleri-logo.svg')} />
            </div>
            <div className="genome-header-title">
              <h2 className="grail-quali-title">Health History Questionnaire</h2>
              <h4>
                Thank you for your interest in the Galleri multi-cancer early detection test. Before
                proceeding, we would like to collect some of your personal and family history.
              </h4>
            </div>
          </section>
          <section className="grail-quali-body">
            <form
              className="grail-quali-form"
              autoComplete="off"
              onSubmit={handleSubmit(this.props.onSubmit)}
              noValidate
            >
              <div className="form-section">
                <div className="body-section">
                  <div className="row-section">
                    <div className="col-section">
                      <div className="label-section">
                        <div className="label-no">1</div>
                        <div className="label-content">
                          <div>
                            Have you been diagnosed or treated for cancer within the past three
                            years?
                            <div className="sub-text">
                              &nbsp;(this excludes basal or squamous cell carcinomas of the skin)
                            </div>
                          </div>
                          <div className="note">
                            <b>Note: </b>If you had breast cancer and are currently taking an
                            anti-estrogen/hormonal therapy (e.g., Tamoxifen, Arimidex, Aromasin),
                            you are eligible to take the Galleri test if you finished all of your
                            other treatment within the past 3 years. Please click NO below if you
                            fall into this category.
                          </div>
                        </div>
                      </div>
                      <div className="input-section">
                        <Field
                          name="q1"
                          component={RadioButtonField}
                          options={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                          ]}
                          validate={[validateRequired]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row-section">
                    <div className="col-section">
                      <div className="label-section">
                        <div className="label-no">2</div>
                        <div className="label-content">
                          <div>Are you currently pregnant?</div>
                        </div>
                      </div>
                      <div className="input-section">
                        <Field
                          name="q2"
                          component={RadioButtonField}
                          options={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                          ]}
                          validate={[validateRequired]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row-section">
                    <div className="col-section">
                      <div className="label-section">
                        <div className="label-no">3</div>
                        <div className="label-content">
                          <div>Have you ever tested positive for HIV?</div>
                        </div>
                      </div>
                      <div className="input-section">
                        <Field
                          name="q3"
                          component={RadioButtonField}
                          options={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                          ]}
                          validate={[validateRequired]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row-section">
                    <div className="col-section">
                      <div className="label-section">
                        <div className="label-no">4</div>
                        <div className="label-content">
                          <div>
                            {' '}
                            Do you have a history of a solid organ transplant and are you currently
                            taking immunosuppressive medications?
                          </div>
                        </div>
                      </div>
                      <div className="input-section">
                        <Field
                          name="q4"
                          component={RadioButtonField}
                          options={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                          ]}
                          validate={[validateRequired]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row-section">
                    <div className="col-section">
                      <div className="label-section">
                        <div className="label-no">5</div>
                        <div className="label-content">
                          <div>
                            Do you have a family history of a first degree relative who has had
                            cancer?
                            <div className="sub-text">
                              &nbsp;(this excludes basal or squamous cell carcinomas of the skin)
                            </div>
                          </div>
                          <div className="note">
                            <b>Note: </b>First degree relatives include your biological parents,
                            full siblings and biological children.
                          </div>
                        </div>
                      </div>
                      <div className="input-section">
                        <Field
                          name="q5"
                          component={RadioButtonField}
                          options={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                          ]}
                          validate={[validateRequired]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row-section q5">
                    <div className="col-section">
                      <div className="label-section">
                        <div className="label-no">6</div>
                        <div className="label-content">
                          <div>What is your current smoking status?</div>
                        </div>
                      </div>
                      <div className="input-section">
                        <Field
                          name="q6"
                          component={RadioButtonField}
                          options={current_smoking_status}
                          validate={[validateRequired]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row-section q6">
                    <div className="col-section">
                      <div className="label-section">
                        <div className="label-no">7</div>
                        <div className="label-content">
                          <div>What is your current height and weight?</div>
                        </div>
                      </div>
                      <div className="input-section">
                        <div className="multi-input-section">
                          <div className="common-input-label-small">Height (ft’ in)</div>
                          <div className="two-inputs-section">
                            <Field
                              name="q7"
                              type="text"
                              placeholder="Feet"
                              trim={true}
                              component={InputField}
                              validate={[validateRequiredFeet, validateNumberOnly]}
                            />
                            <Field
                              name="q8"
                              type="text"
                              placeholder="Inches"
                              trim={true}
                              component={InputField}
                              validate={[
                                validateRequiredInches,
                                validateInchesNumberOnly,
                                validateInches,
                              ]}
                            />
                          </div>
                          <div className="common-input-label-small">Weight (lbs)</div>
                          <Field
                            name="q9"
                            type="text"
                            placeholder="Weight"
                            trim={true}
                            component={InputField}
                            validate={[validateRequiredPounds, validateWeightNumberOnly]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grail-quali-footer">
                <Alert message={formError} />
                <div className="sign-button-wrapper">
                  <div className="btn-section">
                    <Tooltip
                      placement="top"
                      content="You must answer all questions to proceed"
                      effect="dark"
                      disabled={valid}>
                      <Button
                        className="forgot-button"
                        type="submit"
                        disabled={submitting || !valid}
                      >
                        <span>Submit</span>
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </section>
      </section>
    );
  }
}

GrailQualificationQnAFrom.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default reduxForm({
  form: 'grail-quali-form',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(GrailQualificationQnAFrom);
