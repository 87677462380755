import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { loadSchedulingIfNeeded } from 'ducks/scheduling';
import { loadAllAddress } from 'ducks/user';
import ReschedulePageBody from './ReschedulePageBody';
import gaTrack, { GA_TR_RESCHEDULE_APPOINTMENT_PAGE } from '../../../../../services/gaTrack';

import './ReschedulePage.scss';

class ReschedulePage extends Component {
  static propTypes = {
    appointment: PropTypes.object.isRequired,
    scheduling: PropTypes.object.isRequired,
    loadSchedulingIfNeeded: PropTypes.func.isRequired,
  };

  componentDidMount() {
    gaTrack(GA_TR_RESCHEDULE_APPOINTMENT_PAGE);
    this.props.loadSchedulingIfNeeded();
    this.props.loadAllAddress();
  }

  render() {
    const { history } = this.props;
    const { loaded } = this.props.appointment;
    const { isSchedulingLoaded } = this.props.scheduling;
    const { allAddressLoaded } = this.props.user;
    const isVisible = loaded && isSchedulingLoaded && allAddressLoaded;
    return (
      <div className="scp-reschedule-container full-height">
        <div className="scp-reschedule-page">
          {isVisible && <ReschedulePageBody history={history} />}
        </div>
      </div>
    );
  }
}

export default connect(
  ({ scheduling, appointment, user }) => ({ scheduling, appointment, user }),
  (dispatch) => ({
    loadSchedulingIfNeeded: () => dispatch(loadSchedulingIfNeeded()),
    loadAllAddress: () => dispatch(loadAllAddress()),
  })
)(ReschedulePage);
