import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ForgotPasswordForm from 'components/forms/ForgotPasswordForm';
import { forgotPassword, clearForgotPassword } from 'ducks/auth/password';
import Alert from 'components/widgets/Alert/Alert';
import './ForgotPasswordPage.scss';
import gaTrack, { GA_TR_USER_FORGOT_PAGE } from '../../../../services/gaTrack';

const ForgotPasswordPage = ({ clearForgotPassword, forgotPassword, password }) => {

  useEffect(() => {
    gaTrack(GA_TR_USER_FORGOT_PAGE);
    return () => {
      clearForgotPassword();
    }
  }, []);

  const handleSubmit = ({ email, captcha }) => {
    return forgotPassword({ email, captcha });
  };

  return (
    <section className="forgot-password-page">
      {password && password.forgotPasswordEmailSent ? (
        <div className="form-wrapper">
          <i className="genome-logo" />
          <span className="forgot-title">Almost done</span>
          <div className="forgot-desc">
            Please check your inbox for an email with instructions to reset the password.
          </div>
          <div className="email-sent-letter">
            <i className="icon ion-ios-email-outline" />
          </div>
        </div>
      ) : (
        <div className="form-wrapper">
          <i className="genome-logo" />
          <span className="forgot-title">Reset Password</span>
          <div className="forgot-desc">
            Enter the email address associated with your account. We will email you a link to
            reset your password
          </div>
          <ForgotPasswordForm onSubmit={handleSubmit} />
        </div>
      )}
      <Alert message={password.forgotPasswordError} />
    </section>
  );
}


ForgotPasswordPage.PropTypes = {
  password: PropTypes.object,
  forgotPassword: PropTypes.func.isRequired,
  clearForgotPassword: PropTypes.func.isRequired,
}

export default connect(
  state => ({ password: state.auth.password }),
  dispatch => ({
    forgotPassword: ({ email, captcha }) => dispatch(forgotPassword({ email, captcha })),
    clearForgotPassword: () => dispatch(clearForgotPassword()),
  })
)(ForgotPasswordPage);
