import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { canShowSurveyQuestionnaire } from 'ducks/answers';
import authService from 'services/api/authService';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import './PatientCards.scss';
import { CurbsideConsultation, CURBSIDE_15_CONSULATION_DURATION } from 'constants/ServiceTypes';
import checklistIcon from './pre-appt-checklist.png';
import RBAC from 'components/high-order-components/RBAC/RBAC';

class Questionnaire extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    appointment: PropTypes.object,
    appointmentLoaded: PropTypes.bool,
    isReviewQn: PropTypes.bool,
    showQuestionnaire: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
  }

  get decidedGoal() {
    const { goal, isAnswerReviewQn, isAnswerLoaded } = this.props;
    if (goal == 'incomplete' && isAnswerLoaded && !isAnswerReviewQn) {
      return 'incomplete';
    } else if (goal == 'complete' && isAnswerLoaded && isAnswerReviewQn) {
      return 'complete';
    }
    return null;
  }

  get formattedDateAndTime() {
    const {
      appointment: { consultation, startTime, endTime, timezoneAbb },
    } = this.props;
    const mStartTime = moment(startTime);
    const mEndTime = moment(endTime);
    const formattedStart =
      mStartTime.format('mm') === '00' ? mStartTime.format('h') : mStartTime.format('h:mm');

    const correctedEnd =
      consultation.type === CurbsideConsultation
        ? mStartTime.add(CURBSIDE_15_CONSULATION_DURATION, 'minutes')
        : mEndTime;
    const formattedEnd =
      moment(correctedEnd).format('mm') === '00'
        ? moment(correctedEnd).format('h A')
        : moment(correctedEnd).format('h:mm A');

    return `${formattedStart}-${formattedEnd} ${timezoneAbb}`;
  }

  get differentFromCurrentToScheduleTime() {
    const {
      appointment: { mStartTime },
    } = this.props;

    return mStartTime.diff(moment().startOf('day'), 'days');
  }

  get isChecklistPending() {
    const { isReviewQn, showQuestionnaire } = this.props;

    return showQuestionnaire || isReviewQn;
  }

  get text() {
    if (this.decidedGoal == 'incomplete') {
      return 'To help us make the most of our time together, please fill out the online questionnaire about your personal and family history. This should take a total of about 5 minutes.';
    } else if (this.decidedGoal == 'complete') {
      return 'You can review and update your completed questionnaire until the time of your appointment.  This will help us make the most of our time together.';
    }
    return null;
  }

  get type() {
    if (this.decidedGoal == 'incomplete') {
      return <span className="blue">Incomplete Task</span>;
    } else if (this.decidedGoal == 'complete') {
      return <span className="violet">Upcoming Appointment</span>;
    }
    return null;
  }

  get title() {
    if (this.decidedGoal == 'incomplete') {
      return 'Complete your online questionnaire';
    } else if (this.decidedGoal == 'complete') {
      return 'Review your online questionnaire';
    }
    return null;
  }

  get actionButton() {
    const { goal, isAnswerReviewQn, isAnswerLoaded } = this.props;
    if (goal == 'complete' && isAnswerLoaded && isAnswerReviewQn) {
      return <ButtonV2 path={`/patient/questionnaire`}>Review Questionnaire</ButtonV2>;
    } else {
      return (
        <div className="link">
          <Link to="/patient/questionnaire">Launch the Questionnaire</Link>
        </div>
      );
    }
  }

  render() {
    const {
      appointment,
      appointment: { consultation },
      showQuestionnaire,
    } = this.props;
    if (_.isEmpty(appointment) || !showQuestionnaire || _.isNil(this.decidedGoal)) return null;

    return (
      <div className="upcoming-appointment">
        <div className="content">
          <div className="static">
            <div className="left">
              <div className="tag">{this.type}</div>
              <div className="title">{this.title}</div>
              <div className="time" data-hj-suppress>
                {`${consultation.name} ${this.formattedDateAndTime}`}
              </div>
              <div className="text" data-hj-suppress>
                {this.text}
              </div>
            </div>
            <div className="right">
              <img className="visual-content" src={checklistIcon} alt="upload your card" />
            </div>
          </div>
          <div className="action-items left">{this.actionButton}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    answers: { loaded, completed },
  } = state;

  return {
    currentUser: authService.getCurrentUser(state),
    appointment: state.appointment.data,
    appointmentLoaded: state.appointment.loaded && !state.appointment.loading,
    isAnswerLoaded: loaded,
    isAnswerReviewQn: completed,
    showQuestionnaire: canShowSurveyQuestionnaire(state),
  };
};

export default RBAC(['nonScp'], connect(mapStateToProps)(Questionnaire));
