import _ from 'lodash';
import api from 'services/api/api';
import Context from '../Context';
import RedirectAction from './RedirectAction';
import { REDIRECT_AFTER_LOGIN } from 'ducks/misc/redirectActions';

export default class RedirectAfterLogin extends RedirectAction {
  constructor() {
    super(null, null);

    this.isLogged = api.hasToken();
    this.outsideRedirectTo = null;

    this.holdLoggedIn = (state) => {
      const { holdLoggedIn } = state.user;
      return holdLoggedIn;
    };
  }

  setOutsideRedirect(context: Context) {
    const { redirectActions } = context.getState();
    this.outsideRedirectTo = redirectActions.actions[REDIRECT_AFTER_LOGIN];
  }

  execute(context: Context = null): void {
    const { state } = context.getParams();

    if (this.outsideRedirectTo) {
      this.to = this.outsideRedirectTo;
    } else {
      this.to = '/patient/home';
    }

    if (this.to) {
      this.isLogged = true;
      super.execute(context);
    }
  }

  canExecute(context: Context = null): boolean {
    const { state, router } = context.getParams();
    const { me } = state.user;
    const { email } = state.registrationWizard.clientData;
    const astrikeScopeAvailable = api.hasTokenIncludeScope('*');
    // User already logged or not signed yet
    if (!astrikeScopeAvailable || this.isLogged || _.isEmpty(me) || !_.isNil(email)) return false;

    if (this.holdLoggedIn(state)) return false;

    this.setOutsideRedirect(context);

    return true;
  }
}
