//@flow
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from 'components/forms/controls/Button';
import CommonField from 'components/forms/controls/CommonField';
import CheckboxGroup from 'components/forms/controls/CheckboxGroup';
import CustomCombobox from 'components/forms/controls/CustomCombobox';
import FilesField from 'components/forms/controls/FilesField';
import DatePicker from 'components/forms/controls/DatePicker';
import ReCaptcha from 'components/forms/controls/ReCaptcha';
import ReferralsScheme, {
  sectionPatientInfo,
  sectionAdditionalInfo,
  // thankYouStep,
  answers,
} from 'constants/ReferralsScheme';
import { GetStates } from 'services/geo';
import { GetStatesForDropdown } from 'services/utils';
import { required, referText } from 'services/validate';

const states = GetStates();
const statesDict = GetStatesForDropdown(states);

const validateRecaptcha = required();

class ReferAdditionalInformation extends Component {
  static fieldsOrder = [
    'insuranceCompany',
    'policyHolderName',
    'policyHolderDOB',
    'policyID',
    'patientAddressLine1',
    'streetAddressLine2',
    'additionalCity',
    'zipCode',
    'ancestry',
  ];

  state = {
    filesError: false,
  };

  onFilesChanged = (files) =>
    this.setState({
      filesError: !!files.find((file) => file.error),
    });

  onSubmit = (data) =>
    this.props.onSubmit({
      data: {
        ...data,
        patientState: statesDict[data.patientState],
      },
      scheme: ReferralsScheme[sectionAdditionalInfo],
      nextSection: null,
      order: ReferAdditionalInformation.fieldsOrder,
      metadata: {
        patientState: data.patientState,
      },
    });

  givenPermission = () => {
    const { givenPermission: o } = this.props;
    return o && o.toLowerCase && o.toLowerCase() === 'yes';
  };

  goBack = () => {
    this.props.gotoStage(sectionPatientInfo);
  };

  render() {
    const { submitting, className, initialValues } = this.props;
    const { questions } = ReferralsScheme[sectionAdditionalInfo];

    return (
      <div className={className}>
        <h3 className="section-name">Additional Information</h3>
        <p className="text-center">Providing the information on this page is optional.</p>
        <p className="mb30">
          <strong>Your Patient's Insurance information</strong>
        </p>
        <p>
          Please provide this information below or upload an image of the front and back side of the
          insurance card.
        </p>

        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field
            name="insuranceCompany"
            type="text"
            component={CommonField}
            label={questions.insuranceCompany}
            initialValues={initialValues.insuranceCompany}
            validate={[referText]}
          />
          <Field
            name="policyHolderName"
            type="text"
            component={CommonField}
            label={questions.policyHolderName}
            initialValues={initialValues.policyHolderName}
            validate={[referText]}
          />

          <Field
            name="policyHolderDOB"
            type="text"
            component={DatePicker}
            label={questions.policyHolderDOB}
            initialValues={initialValues.policyHolderDOB}
            validate={[referText]}
          />

          <Field
            name="policyID"
            type="text"
            component={CommonField}
            label={questions.policyID}
            initialValues={initialValues.policyID}
            validate={[referText]}
          />

          <p className="mt15 ">Or upload image of insurance card (front and back) below:</p>
          <Field name="files" component={FilesField} onFilesChanged={this.onFilesChanged} />

          <h3 className="section-name">Additional Patient Information</h3>

          <Field
            name="patientAddressLine1"
            type="text"
            component={CommonField}
            label={questions.patientAddressLine1}
            initialValues={initialValues.patientAddressLine1}
            validate={[referText]}
          />
          <Field
            name="streetAddressLine2"
            type="text"
            component={CommonField}
            label={questions.streetAddressLine2}
            initialValues={initialValues.streetAddressLine2}
            validate={[referText]}
          />
          <Field
            name="additionalCity"
            type="text"
            component={CommonField}
            label={questions.additionalCity}
            initialValues={initialValues.additionalCity}
            validate={[referText]}
          />
          <Field
            name="additionalState"
            type="text"
            component={CustomCombobox}
            label={questions.additionalState}
            items={states}
            placeholder={''}
            initialValues={initialValues.additionalState}
          />
          <Field
            name="zipCode"
            type="text"
            component={CommonField}
            label={questions.zipCode}
            initialValues={initialValues.zipCode}
            validate={[referText]}
          />
          <Field
            name="ancestry"
            type="text"
            component={CheckboxGroup}
            label={questions.ancestry}
            items={answers.ancestry}
            initialValues={initialValues.ancestry}
          />
          <br />
          <Field
            className="captcha-field"
            name="captcha"
            component={ReCaptcha}
            label={questions.captcha}
            horisontal={true}
            validate={[validateRecaptcha]}
          />

          <div className="buttons-block submit-block">
            <span className="back-button" onClick={this.goBack}>
              Back
            </span>
            <Button
              className="button-next"
              type="submit"
              disabled={submitting || this.state.filesError}
            />
          </div>
        </form>
      </div>
    );
  }
}

ReferAdditionalInformation.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  givenPermission: PropTypes.string,
  initialValues: PropTypes.object,
  gotoStage: PropTypes.func,
};

export default reduxForm({ form: 'refer-additional-information', forceUnregisterOnUnmount: true })(
  ReferAdditionalInformation
);
