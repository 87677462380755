import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSessionCouponCode, setCouponCodeReadOnly } from 'ducks/scheduling';
import webinarService from 'services/api/webinarService';
import authService from 'services/api/authService';
import eligibilityService from 'services/api/eligibilityService';
import selectionService from 'services/api/selectionService';
import schedulingService, { GM_HELIX_COUPON_CODE } from 'services/api/schedulingService';
import {
  TestConsultation,
  ReviewConsultation,
  CurbsideConsultation,
  GetStartedConsultation,
} from 'constants/ServiceTypes';
import {
  setReferralSource,
  clearReferralSource,
  setWarmReferralSource,
  setWarmReferralURL,
  clearWarmReferralSource,
  clearWarmReferralURL,
  clearLogoLink,
  setLogoLink,
  setReferralPartnerName,
} from 'services/misc/ReferralSource';
import fetchHOC from 'components/high-order-components/FetchHOC';
import _ from 'lodash';
import { setStage } from 'ducks/registrationWizard';
import gaTrack, { GA_TR_AFFILIATION_REGISTRATION_PAGE } from '../../../../services/gaTrack';

class RegistrationEntryPoint extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    partners: PropTypes.object.isRequired,
    location: PropTypes.object,
  };

  PreviousURL() {
    const url = document.referrer;
    let url_src = url
      .replace('http://', '')
      .replace('https://', '')
      .replace('www.', '')
      .split(/[/?#]/)[0];
    return url_src == 'genomemedical.com' ? url : null;
  }

  componentDidMount() {
    gaTrack(GA_TR_AFFILIATION_REGISTRATION_PAGE);
    const {
      match: { params },
      location: { search },
      history,
      dispatch,
    } = this.props;
    let newUrl = '/register{service}/account';
    let service = null;
    const partner = params.partner && params.partner.toLowerCase();
    const queryParams = new URLSearchParams(search);
    const query = {
      service: queryParams.get('service'),
      code: queryParams.get('code'),
      referral_url: queryParams.get('referral_url'),
      payment_mode: queryParams.get('payment_mode'),
    };
    this.clearSessionStorage();
    dispatch(setStage(undefined));

    let PreviousURL = this.PreviousURL();
    if (!_.isEmpty(PreviousURL)) {
      setLogoLink(PreviousURL);
    }
    if (query && query.referral_url) {
      let warm_referral_src = query.referral_url
        .replace('http://', '')
        .replace('https://', '')
        .replace('www.', '')
        .split(/[/?#]/)[0];
      setWarmReferralURL(query.referral_url);
      setWarmReferralSource(warm_referral_src);
    }

    if (query && query.code) {
      dispatch(setSessionCouponCode(query.code, true));
      dispatch(setCouponCodeReadOnly(true));
    }

    if (partner) {
      this.props.partners.fetch().then(() => {
        const { partners } = this.props;
        const partnersData = _.get(partners, 'data', []);
        let knownPartner = partnersData.find(
          (a) =>
            a.registration_url &&
            a.registration_url !== null &&
            a.registration_url.toLowerCase() === partner
        );
        const showMedicareQuestion = knownPartner?.showMedicareQuestion;
        if (_.isEmpty(knownPartner)) {
          // no idea what to do here.
          //TODO: maybe it makes sense to show some error
          history.replace('/');
          return;
        } else if (
          knownPartner &&
          knownPartner.programType &&
          knownPartner.programType.toLowerCase() === 'scp'
        ) {
          history.replace(`/scp/registration/${partner}`);
          return;
        } else if (
          knownPartner.self_registration_enabled &&
          knownPartner.self_registration_enabled === true
        ) {
          if (knownPartner.name === 'DTC_Helix') {
            dispatch(setSessionCouponCode(GM_HELIX_COUPON_CODE, true));
          }
          if (
            knownPartner.name === 'Illumina' ||
            knownPartner.name === 'GenomicHealthEmployee' ||
            knownPartner.name === 'Novartis' ||
            knownPartner.name === 'Novartis_Galleri'
          ) {
            eligibilityService.saveReferral(knownPartner.name);
            newUrl = '/register{service}/eligibility';
          }
          if (knownPartner.name === 'Genentech') {
            selectionService.saveReferral(knownPartner.name);
            newUrl = '/register{service}/selection';
          }

          if (query.service) {
            switch (query.service) {
              case 'testing-guidance':
                service = TestConsultation;
                break;
              case 'results-review':
                service = ReviewConsultation;
                break;
              case 'curbside-chat15':
                service = CurbsideConsultation;
                break;
              case 'getstarted':
                service = GetStartedConsultation;
                break;
            }
          }
          if (query.payment_mode) {
            let defaultPaymentMode;
            switch (query.payment_mode) {
              case 'self-pay':
                defaultPaymentMode = 'self-pay';
                break;
              case 'insurance':
                defaultPaymentMode = 'insurance';
                break;
            }
            if (defaultPaymentMode) {
              schedulingService.setDefaultPaymentMode(defaultPaymentMode);
            }
          }
          if (knownPartner && knownPartner.display_name)
            setReferralSource(knownPartner.display_name);
          if (knownPartner && knownPartner.name) setReferralPartnerName(knownPartner.name);
          authService.saveAffiliationId(knownPartner.id);
          this.savePreselectedService(service);
        }
        this.redirectToScheduling(newUrl, service, showMedicareQuestion);
      });
    } else {
      this.redirectToScheduling(newUrl, service);
    }
  }

  savePreselectedService(service) {
    if (service) authService.savePreselectedService(service);
    else authService.resetPreselectedService();
  }

  redirectToScheduling(url, service, showMedicareQuestion = '') {
    const { history } = this.props;
    let redirectTo = url.replace('{service}', service ? '/' + service : '');
    if (showMedicareQuestion !== '') {
      const queryParams = new URLSearchParams();
      queryParams.append('showMedicareQuestion', showMedicareQuestion.toString());
      const queryString = queryParams.toString();
      redirectTo =
        url.replace('{service}', service ? `/${service}` : '') +
        (queryString ? `?${queryString}` : '');
    }
    history.replace(redirectTo);
  }

  clearSessionStorage() {
    eligibilityService.clearReferral();
    selectionService.clearReferral();
    eligibilityService.clearVerificationData();
    selectionService.clearVerificationData();
    schedulingService.clearCouponCode();
    clearReferralSource();
    clearWarmReferralSource();
    clearWarmReferralURL();
    clearLogoLink();
    authService.resetAffiliationId();
    authService.resetPreselectedService();
  }

  render() {
    return <div />;
  }
}

export default connect()(
  fetchHOC({
    partners: () => webinarService.getPartners(),
  })(RegistrationEntryPoint)
);
