import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';

import SignupForm from '../../../../components/forms/TestRequest/SignupForm/SignupForm';
import schedulingService from '../../../../services/api/schedulingService';
import TestRequestService from '../../../../services/api/testRequestService';
import BreadcrumbProgress from 'components/forms/TestRequest/BreadcrumbProgress/BreadcrumbProgress';

import './TestRequestWizardPage.scss';
import LabInsuranceForm from 'components/forms/TestRequest/LabInsurnace/LabInsuranceForm';
import PayemtInfoForm from 'components/forms/TestRequest/Payemtninfo/PaymentInfoForm';
import { getCountryState } from '../../../../ducks/enums';
import ShippingAddressPage from '../../../../components/scp/forms/WizardForm/ShippingAddressPage/ShippingAddressPage';
import {
  getTestRequestData,
  setTestRequestData,
  clearTestRequestData,
} from '../../../../ducks/testRequestWizard';
import { loadLabTest } from '../../../../ducks/geneticTest';
import { closeLoader, showLoader } from '../../../../ducks/ui';
import webinarService from '../../../../services/api/webinarService';
import { setupStripeIntentFromEmail } from '../../../../ducks/user';
import ConfirmAppointmentForm from '../../../../components/forms/TestRequest/ConfirmAppointmentForm/ConfirmAppointmentForm';
import ConfirmationPage from '../../../../components/forms/TestRequest/ConfirmationPage/ConfirmationPage';
import authService from '../../../../services/api/authService';
import EmailAlreadyExist from '../../../../components/forms/TestRequest/EmailAlreadyExist/EmailAlreadyExist';
import usersService from '../../../../services/api/usersService';
import { getHomeAddress, getShippingAddress, HOME } from '../../../../services/utils';
import {
  dehydrateGeneTestOrder,
  dehydratePayment,
  dehydrateRegister,
} from '../../../../services/api/transformers/geneticTestTransformers';
import { processGeneTestOrderError } from '../../../../services/api/transformers/errorTransformers';
import { clearFormError, setSubmitFailure } from '../../../../ducks/registrationWizard';
import { formatOrderQues } from './AOEUtils';
import PrivacyPolicyForm from '../../../../components/forms/TestRequest/PrivacyPolicy/PrivacyPolicyForm';
import NateraConsents from '../../../../components/forms/TestRequest/NateraConsents/NateraConsents';

const SIGNUP_FORM = 'signup-form';
const GM_PRIVACY_POLICY = 'gm-privacy-policy';
const NATERA_CONSENTS = 'natera-consents';
const LAB_INSURANCE = 'lab-insurance';
const PAYMENT = 'payment';
const SHIPPING_ADDRESS = 'shipping address';
const REVIEW = 'review';
const CONFIRMATION = 'confirmation';
const EMAIL_EXIST = 'email-already-exists';

export const workflow = [
  {
    id: SIGNUP_FORM,
    name: 'Signup',
    noProgress: true,
  },
  {
    id: GM_PRIVACY_POLICY,
    name: 'GM privacy policy',
    noProgress: true,
  },
  {
    id: NATERA_CONSENTS,
    name: 'Natera consent',
    noProgress: true,
  },
  {
    id: EMAIL_EXIST,
    name: 'Email already exist',
    noProgress: true,
  },
  {
    id: LAB_INSURANCE,
    name: 'Insurance',
  },
  {
    id: PAYMENT,
    name: 'Payment',
  },
  {
    id: SHIPPING_ADDRESS,
    name: 'Address',
  },
  {
    id: REVIEW,
    name: 'Review',
  },
  {
    id: CONFIRMATION,
    name: 'Confirmation',
    noProgress: true,
  },
];

export const SIGNUP_INITIALVALUES = {
  firstName: '',
  lastName: '',
  email: '',
  primaryPhone: '',
  primaryPhoneConsentToText: true,
  dob: '',
  sexAssignedAtBirth: '',
  aoeForm: {},
};

const TestRequestWizardPage = ({
  dispatch,
  partnerData,
  testUUID,
  history,
  user: { stripeIntentFromEmail, stripeIntentFromEmailLoading },
  labUUID,
  getStripeIntent,
  testDetail,
  formError,
  CountriesStateEnums,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [activeStage, setActiveStage] = useState(SIGNUP_FORM);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (!testUUID) {
      history.push('/login');
    }
    setIsMobile(schedulingService.isMobile());
    window.addEventListener('resize', onResize);
    if (partnerData && partnerData.uuid) {
      dispatch(getCountryState(partnerData.uuid));
    }
    const TestRequestData = getTestRequestData();
    const activeStage = _.get(TestRequestData, ['activeStage'], SIGNUP_FORM);
    if (activeStage) {
      updateStage(activeStage);
    }
  }, []);

  const onResize = () => {
    setViewMode();
  };

  const setViewMode = () => {
    if (isMobile && !schedulingService.isMobile()) {
      setIsMobile(false);
    } else if (!isMobile && schedulingService.isMobile()) {
      setIsMobile(true);
    }
  };

  const clearSessionStorage = () => {
    clearTestRequestData();
    authService.resetAffiliationId();
    authService.resetAffiliation();
    TestRequestService.clearGeneticTestUUID();
    TestRequestService.clearTestRequestLabUUID();
  };

  const removeErrorFromFields = (objName) => {
    const _errors = { ...errors };
    if (_errors && _errors[objName]) {
      delete _errors[objName];
      setErrors(_errors);
    }
  };

  const onSubmitSignupForm = (values) => {
    removeErrorFromFields('patient');
    dispatch(showLoader());
    return usersService
      .matchedPatient(values?.email)
      .then((res) => {
        if (!res.exist) {
          setTestRequestData({
            register: values,
          });
          updateStage(GM_PRIVACY_POLICY);
        } else {
          updateStage(EMAIL_EXIST);
        }
        dispatch(closeLoader());
        return res;
      })
      .catch(() => {
        dispatch(closeLoader());
      });
  };

  const submitPrivacyInformation = (values) => {
    return new Promise((resolve) => {
      resolve(values);
    }).then(() => {
      updateStage(NATERA_CONSENTS);
    });
  };

  const submitNateraConsents = (values) => {
    return new Promise((resolve) => {
      resolve(values);
    }).then(() => {
      updateStage(LAB_INSURANCE);
    });
  };

  const onInusranceFormSubmit = (values) => {
    return new Promise((resolve) => {
      resolve(values);
    }).then((values) => {
      if (values.isSelfPay) {
        setTestRequestData({
          lab_insurance: {
            isSelfPay: values.isSelfPay,
          },
        });
      } else {
        setTestRequestData({
          lab_insurance: values,
        });
      }
      removeErrorFromFields('insurance');
      updateStage(PAYMENT);
      return values;
    });
  };

  const onPaymentFormSubmit = (values) => {
    const { requireShippingAddress } = partnerData;
    const stripeSetupIntent = _.get(stripeIntentFromEmail, ['id']);
    return new Promise((resolve) => {
      resolve(values);
    }).then((values) => {
      removeErrorFromFields('home');
      setTestRequestData({
        address: values,
        payment: { stripeSetupIntent },
      });
      if (requireShippingAddress) updateStage(SHIPPING_ADDRESS);
      else updateStage(REVIEW);
    });
  };

  const onAddressFormSubmit = (values) => {
    return new Promise((resolve) => {
      resolve(values);
    }).then((values) => {
      removeErrorFromFields('delivery');
      setTestRequestData({
        address: values,
      });
      updateStage(REVIEW);
    });
  };

  const onReviewSubmit = (values) => {
    const testRequestData = getTestRequestData();
    const registerData = _.get(testRequestData, ['register'], {});
    const labInsData = _.get(testRequestData, ['lab_insurance'], {});
    const addressesData = _.get(testRequestData, ['address'], []);
    const paymentData = _.get(testRequestData, ['payment'], []);
    const formatedAOE = formatOrderQues(testRequestData);
    return new Promise((resolve) => {
      resolve(values);
    }).then((values) => {
      const req = {
        patient: dehydrateRegister({
          ...registerData,
          userAddresses: addressesData,
          insurance: labInsData,
          partnerId: _.get(partnerData, ['id']),
        }),
        payment: dehydratePayment({
          ...paymentData,
          useInsurance: !labInsData?.isSelfPay,
        }),
        gene_test_order: dehydrateGeneTestOrder({
          labUUID: labUUID,
          testUUID: testUUID,
          fields: formatedAOE,
        }),
      };
      dispatch(showLoader());
      dispatch(clearFormError());
      usersService
        .createGeneTestOrder(req)
        .then((res) => {
          updateStage(CONFIRMATION);
          dispatch(closeLoader());
          return res;
        })
        .catch((err) => {
          if (err && err.status == 422) {
            const error = processGeneTestOrderError(err);
            setErrors(error.fields);

            if (error.fields.patient) updateStage(SIGNUP_FORM);
            else if (error.fields.insurance) updateStage(LAB_INSURANCE);
            else if (error.fields.home) updateStage(PAYMENT);
            else if (error.fields.delivery) updateStage(SHIPPING_ADDRESS);
            dispatch(closeLoader());
          } else {
            dispatch(setSubmitFailure(err.message));
            dispatch(closeLoader());
          }
        });
    });
  };

  const sameBillingShipping = (shippingAddress) => {
    if (_.isEmpty(shippingAddress)) return true;
    return _.get(shippingAddress, 'addressType', []).includes(HOME);
  };

  const updateStage = (activeStage) => {
    setTestRequestData({
      activeStage,
    });
    setActiveStage(activeStage);
  };

  const onBack = () => {
    const { requireShippingAddress } = partnerData;
    switch (activeStage) {
      case GM_PRIVACY_POLICY:
        updateStage(SIGNUP_FORM);
        break;
      case NATERA_CONSENTS:
        updateStage(GM_PRIVACY_POLICY);
        break;
      case LAB_INSURANCE:
        updateStage(NATERA_CONSENTS);
        break;
      case PAYMENT:
        updateStage(LAB_INSURANCE);
        break;
      case SHIPPING_ADDRESS:
        updateStage(PAYMENT);
        break;
      case REVIEW: {
        if (requireShippingAddress) updateStage(SHIPPING_ADDRESS);
        else updateStage(PAYMENT);
        break;
      }
    }
  };

  if (!partnerData) return null;
  const TestRequestData = getTestRequestData();
  const { preSelectedCountry, countryIsNonUS, allowedCountries, requireShippingAddress } =
    partnerData;
  const registerInitialValues = _.get(TestRequestData, ['register'], {});
  const labInsurnaceInitialValues = _.get(TestRequestData, ['lab_insurance'], {});
  const allAddress = _.get(TestRequestData, ['address'], []);
  const homeAddress = getHomeAddress([...allAddress]);
  const shippingAddress = getShippingAddress([...allAddress]);

  const paymentInitialValues = {
    ...homeAddress,
    addressCountry: _.get(homeAddress, 'addressCountry', preSelectedCountry),
  };
  const TestRequestClass = [SIGNUP_FORM, GM_PRIVACY_POLICY, NATERA_CONSENTS].includes(activeStage);

  return (
    <>
      <div
        className={classnames('scp-schedule-container', isMobile && 'mobile-schedule-container')}
      >
        <div
          className={classnames('full-height', {
            'scheduling-page test-request': !TestRequestClass,
          })}
        >
          <BreadcrumbProgress activeStage={activeStage} workflow={workflow} />
          {activeStage == SIGNUP_FORM && (
            <SignupForm
              isMobile={isMobile}
              onSubmit={onSubmitSignupForm}
              initialValues={{
                ...SIGNUP_INITIALVALUES,
                ...registerInitialValues,
              }}
              errors={errors}
              dispatch={dispatch}
            />
          )}
          {activeStage === GM_PRIVACY_POLICY && (
            <div className="test-request-privacy-policy">
              <PrivacyPolicyForm onSubmit={submitPrivacyInformation} goBack={() => onBack()} />
            </div>
          )}
          {activeStage === NATERA_CONSENTS && (
            <NateraConsents onSubmit={submitNateraConsents} goBack={() => onBack()} />
          )}
          {activeStage == LAB_INSURANCE && (
            <LabInsuranceForm
              toPreviousStep={() => {
                onBack();
              }}
              initialValues={{ ...labInsurnaceInitialValues }}
              onSubmit={onInusranceFormSubmit}
              errors={errors}
              dispatch={dispatch}
            />
          )}
          {activeStage == PAYMENT && (
            <PayemtInfoForm
              toPreviousStep={() => {
                onBack();
              }}
              initialValues={{
                ...paymentInitialValues,
              }}
              CountriesStateEnums={CountriesStateEnums}
              onSubmit={onPaymentFormSubmit}
              getStripeIntent={getStripeIntent}
              email={_.get(
                TestRequestData,
                ['register', 'email'],
                'arpit.makhiyaviya+dev_12june@genomemedical.com'
              )}
              stripeIntent={stripeIntentFromEmail}
              stripeIntentLoading={stripeIntentFromEmailLoading}
              allowedCountries={allowedCountries}
              countryIsNonUS={countryIsNonUS}
              allAddress={allAddress}
              user={{
                fullName: `${_.get(TestRequestData, ['register', 'firstName'])} ${_.get(
                  TestRequestData,
                  ['register', 'lastName']
                )}`,
              }}
              errors={errors}
              dispatch={dispatch}
              testDetail={testDetail}
            />
          )}
          {SHIPPING_ADDRESS == activeStage && requireShippingAddress && (
            <ShippingAddressPage
              toPreviousStep={() => {
                onBack();
              }}
              initialValues={{
                shippingAddressLine1: _.get(
                  shippingAddress,
                  'addressLine1',
                  _.get(homeAddress, 'addressLine1', '')
                ),
                shippingAddressLine2: _.get(
                  shippingAddress,
                  'addressLine2',
                  _.get(homeAddress, 'addressLine2', '')
                ),
                shippingAddressCity: _.get(
                  shippingAddress,
                  'addressCity',
                  _.get(homeAddress, 'addressCity', '')
                ),
                shippingAddressState: _.get(
                  shippingAddress,
                  'addressState',
                  _.get(homeAddress, 'addressState', '')
                ),
                shippingAddressZipCode: _.get(
                  shippingAddress,
                  'addressZipCode',
                  _.get(homeAddress, 'addressZipCode', '')
                ),
                shippingAddressCountry: _.get(
                  shippingAddress,
                  'addressCountry',
                  _.get(homeAddress, 'addressCountry', 'US')
                ),
                shippingSameAsHomeAddress: sameBillingShipping(shippingAddress),
              }}
              onSubmit={onAddressFormSubmit}
              allAddress={allAddress}
              user={{
                fullName: `${_.get(TestRequestData, ['register', 'firstName'])} ${_.get(
                  TestRequestData,
                  ['register', 'lastName']
                )}`,
              }}
              CountriesStateEnums={CountriesStateEnums}
              isMobile={isMobile}
              isTestRequestWizard={true}
              description={
                'Genome Medical needs to collect a shipping address so that Natera can send a genetic test kit to you.'
              }
              errors={errors}
              dispatch={dispatch}
              action="test"
            />
          )}
          {activeStage == REVIEW && (
            <ConfirmAppointmentForm
              toPreviousStep={() => {
                onBack();
              }}
              onSubmit={onReviewSubmit}
              formError={formError}
              testDetail={testDetail}
            />
          )}
          {activeStage == CONFIRMATION && <ConfirmationPage isMobile={isMobile} />}
          {activeStage == EMAIL_EXIST && <EmailAlreadyExist />}
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => {
    return {
      CountriesStateEnums: _.get(state, ['enums', 'countryState', 'data']),
      geneticTest: _.get(state, ['geneticTest']),
      user: _.get(state, ['user']),
      partnerData: authService.getAffiliation(),
      testUUID: TestRequestService.getGeneticTestUUID(),
      labUUID: TestRequestService.getTestRequestLabUUID(),
      testDetail: TestRequestService.getGeneticTestDetail(),
      formError: _.get(state, ['registrationWizard', 'formError']),
    };
  },
  (dispatch) => {
    return {
      dispatch,
      loadLabTest: (data) => dispatch(loadLabTest(data)),
      showLoader: () => dispatch(showLoader()),
      closeLoader: () => dispatch(closeLoader()),
      partners: () => webinarService.getPartners(),
      getStripeIntent: (data) => dispatch(setupStripeIntentFromEmail(data)),
    };
  }
)(TestRequestWizardPage);
