import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import './TestOrderRequestConfirm.scss';
import TestOrderProgressBar from 'components/scp/TestOrderProgressBar/TestOrderProgressBar';
import TestOrderRequestConfirmDetail from 'components/scp/TestOrderRequestConfirmDetail/TestOrderRequestConfirmDetail';
import { loadAllAddress } from 'ducks/user';
import { loadCurrentSCPEncounter } from '../../../../../ducks/scpencounters';
import { loaderSession } from 'ducks/ui';
import { testOrderDetailPageTitles, orderStatuses } from 'constants/TestOrderConstants';
import RedWarningIcon from './warning-red.svg';
import gaTrack, { GA_TR_TESTORDER_PAGE } from 'services/gaTrack';
import { getShippingAddress } from '../../../../../services/utils';
class TestOrderRequestConfirmContainer extends Component {
  componentDidMount() {
    gaTrack(GA_TR_TESTORDER_PAGE);
    if (_.isEmpty(this.props.allAddress)) {
      this.props.loadAllAddress();
    }
    if (!this.props.currentScpEncounterLoaded)
      loaderSession(this.props.dispatch, this.props.loadCurrentSCPEncounter());
    this.navigateToHomeIfnotValidEncounter();
  }

  componentDidUpdate() {
    this.navigateToHomeIfnotValidEncounter();
  }

  navigateToHomeIfnotValidEncounter = () => {
    const { currentScpEncounterLoaded, currentScpEncounter, gotoHome, gotoShippingWizardPage } =
      this.props;
    if (
      currentScpEncounterLoaded &&
      currentScpEncounter &&
      currentScpEncounter.order &&
      _.isNil(_.get(currentScpEncounter, ['order', 'status']))
    ) {
      if (!currentScpEncounter.isTestOrderSubType) {
        gotoHome();
      } else if (currentScpEncounter.needConsent) {
        gotoShippingWizardPage();
      } else {
        gotoHome();
      }
    } else if (
      currentScpEncounterLoaded &&
      currentScpEncounter &&
      currentScpEncounter.order &&
      !_.isNil(_.get(currentScpEncounter, ['order', 'status'])) &&
      _.get(currentScpEncounter, ['order', 'status']) == 'error'
    ) {
      gotoHome();
    }
  };

  get statusHeader() {
    const { currentScpEncounter } = this.props;
    const orderstatus = _.get(currentScpEncounter, ['order', 'status'], null);
    const orderStatusTitle = testOrderDetailPageTitles[orderstatus];
    return orderstatus === orderStatuses.cancelled ? (
      <Fragment>
        <img src={RedWarningIcon} className="warning-icon" alt="red-warning" />
        {orderStatusTitle}
      </Fragment>
    ) : (
      <Fragment>{orderStatusTitle}</Fragment>
    );
  }

  render() {
    const { user, allAddress, currentScpEncounter, gotoHome, currentScpEncounterLoaded } =
      this.props;
    const valid =
      currentScpEncounterLoaded &&
      currentScpEncounter &&
      currentScpEncounter.isTestOrderSubType &&
      currentScpEncounter.subType;
    return (
      <div className="scp-test-order-confirm-container">
        <h2 className="test-order-header">Test Status</h2>
        <div className="scp-test-order-confirm-page full-height no-footer">
          {valid && (
            <div className="scp-test-order-confirm-wrapper">
              <a className="go-home" onClick={() => gotoHome()}>{`< Back to Home Page`}</a>
              <h2 className="test-order-status-header">{this.statusHeader}</h2>
              <TestOrderProgressBar currentScpEncounter={currentScpEncounter} />
              <TestOrderRequestConfirmDetail
                shippingAddress={getShippingAddress([...allAddress])}
                personalInfo={_.get(user, 'me', {})}
                currentScpEncounter={currentScpEncounter}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, scpEncounters } = state;
  return {
    user,
    scpEncounters,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { history } = ownProps;
  const { user, scpEncounters } = stateProps;
  const { dispatch } = dispatchProps;

  let { allAddress } = user;
  let { currentScpEncounter, currentScpEncounterLoading, currentScpEncounterLoaded } =
    scpEncounters;

  return {
    user,
    dispatch,
    allAddress,
    currentScpEncounter,
    currentScpEncounterLoading,
    currentScpEncounterLoaded,
    loadAllAddress: () => dispatch(loadAllAddress()),
    loadCurrentSCPEncounter: () => dispatch(loadCurrentSCPEncounter()),
    gotoHome: () => history.push('/patient/home'),
    gotoShippingWizardPage: () => history.push(`/patient/testrequest/address`),
  };
};

TestOrderRequestConfirmContainer.propTypes = {
  loadAllAddress: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  loadCurrentSCPEncounter: PropTypes.func.isRequired,
  allAddress: PropTypes.array,
  currentScpEncounter: PropTypes.object,
  user: PropTypes.object,
  currentScpEncounterLoading: PropTypes.bool,
  currentScpEncounterLoaded: PropTypes.bool,
  gotoHome: PropTypes.func.isRequired,
  gotoShippingWizardPage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null, mergeProps)(TestOrderRequestConfirmContainer);
