import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import CareCoordinator from 'components/widgets/CareCoordinator/CareCoordinator';
import ConnectedDropzone from 'components/forms/documents/ConnectedDropzone';

import './QuestionarieSurveyForm.scss';
import { connect } from 'react-redux';
import { loadSurveyScript } from 'ducks/misc/surveyScript';
import { getStore } from 'App';
import { setCompleted } from 'ducks/answers';

class QuestionarieSurveyForm extends Component {
  isSurveyMounted: false;

  componentDidMount() {
    if (this.props.surveyScript.loaded) this.doMountSurvey();
    else if (!this.props.surveyScript.loading) this.props.dispatch(loadSurveyScript());
  }

  componentDidUpdate(prevProps) {
    const prevLoaded = prevProps.surveyScript.loaded && prevProps.appointment.loaded;
    const curLoaded = this.props.surveyScript.loaded && this.props.appointment.loaded;
    if (!prevLoaded && curLoaded) {
      this.doMountSurvey();
    }
  }

  doMountSurvey = () => {
    if (
      this.isSurveyMounted ||
      !this.props.surveyScript.loaded ||
      !this.props.appointment.loaded ||
      _.isEmpty(this.props.appointment.data)
    )
      return;
    if (!this.props.surveys || this.props.surveys.length === 0) {
      console.error('No survey to fill!'); // eslint-disable-line
      this.props.history.push('/');
      return;
    }
    const survey = this.props.surveys[0];
    if (!survey.token)
      if (!survey.isSurveyJSSurvey || this.props.surveys.data.length === 0) {
        console.error('Survey has no token!'); // eslint-disable-line
        this.props.history.push('/');
        return;
      }
    let appoitmentObject = _.get(this, 'props.appointment.data', {});
    let me = _.get(this, 'props.me', {});
    this.isSurveyMounted = true;

    window.GmSetupSurvey({
      isIframe: false,
      elementId: '#gm-questionnaire',
      alias: survey.surveyAlias,
      token: survey.token,
      appointmentId: this.props.appointment.data.id,
      onComplete: this.surveyCompleted,
      pageChangedHandler: this.renderDropzone,
      onValueChanged: this.renderDropzone,
      userInfo: {
        'First Name': this.props.me.firstName,
        'Last Name': this.props.me.lastName,
        'E-mail': this.props.me.email,
      },
      patientPayload: {
        first_name: _.get(me, 'firstName', ''),
        last_name: _.get(me, 'lastName', ''),
        sex: _.get(me, 'gender', ''),
        dob: _.get(me, 'dateOfBirth', ''),
        pcp: _.get(me, 'referral_provider.name', ''),
        affiliation: _.get(me, 'affiliation', ''),
        date_of_visit: _.get(appoitmentObject, 'startTime', ''),
        gc: _.get(appoitmentObject, 'provider.fullName', ''),
        state: _.get(appoitmentObject, 'state', ''),
        modality: _.get(appoitmentObject, 'type', ''),
      },
    });

    this.renderDropzone();
  };

  surveyCompleted = () => {
    this.props.dispatch(setCompleted(true));
    setTimeout(() => this.props.history.push('/patient'), 1000);
  };

  renderDropzone = () => {
    setTimeout(() => {
      const hasDropArea = document.querySelector('#results-uploader');
      if (hasDropArea) {
        const prefix = hasDropArea.getAttribute('prefix');
        ReactDOM.render(this.getDropZone(prefix), document.querySelector('#results-uploader'));
      }
    }, 1000);
  };

  getDropZone(prefix) {
    return (
      <Provider store={getStore()}>
        <ConnectedDropzone
          description="Patient test result"
          store={this.props.store}
          prefix={prefix}
        />
      </Provider>
    );
  }

  render() {
    this.renderDropzone();
    return (
      <div className="scp-questionnarie">
        <section className="questionarie-survey-form full-height">
          <div className="questionnaire-container">
            <div className="left-block">
              <CareCoordinator />
            </div>
            <div data-hj-suppress>
              <div id="gm-questionnaire" className="qn-frame-container" />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

QuestionarieSurveyForm.propTypes = {
  surveys: PropTypes.array,
  loaded: PropTypes.bool,
  me: PropTypes.object,
};

export default connect(({ surveyScript, answers, appointment, user }) => ({
  surveyScript,
  surveys: answers.surveys,
  me: user.me,
  appointment,
}))(QuestionarieSurveyForm);
