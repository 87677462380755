import _ from 'lodash';

//! TODO: refactor this. Move functions to ducks and call via dispatch.
// The current approach is crooked and in fact it was inventing a bicycle with squared wheels

export const isFetching = state =>
  state.user.meLoading || state.appointment.loading;

export const appointmentsLoaded = state => state.appointment.loaded;

export const hasAppointment = state =>
  appointmentsLoaded(state) && !_.isEmpty(state.appointment.data);

// true - if appointments is from the package
// false - if it's not
// null - necessary data is not loaded
export const isAppointmentFromPackage = state => {
  if (_.isEmpty(state.appointment.data) || !state.userServicePackages.loaded) return null;
  return !!(
    !_.isEmpty(state.userServicePackages.data) &&
    _(
      state.userServicePackages.data.find(p =>
        p.prepaidConsultations.find(c => c.appointmentId === state.appointment.data.id)
      )
    )
  );
};

export const canApplyCouponCode = state => {
  const { consultationTypes, servicePackages } = state.scheduling;
  return !_.isEmpty(consultationTypes) && !_.isEmpty(servicePackages);
};
