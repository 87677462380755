import PropTypes from 'prop-types';
import React, { Component } from 'react';
import selectionService from 'services/api/selectionService';
import schedulingservices from 'services/api/schedulingService';
import { connect } from 'react-redux';
import FancyPageFrame from 'components/widgets/FancyPageFrame/V2/FancyPageFrame';
import SimpleWebinarMessage from '../../webinar/SimpleWebinar/SimpleWebinarMessage/V2/SimpleWebinarMessage';
import gaTrack, { GA_TR_THANKYOU_PAGE } from '../../../../services/gaTrack';

const message = {
  uninterpretedGenomicData:
    'Thank you for confirming your selection to obtain your raw uninterpreted data. ',
  possibleMedicallyActionableFindings:
    'Thank you for confirming your selection to be notified about medically actionable findings. ',
  both: 'Thank you for confirming your selection to be notified about medically actionable findings and to obtain your raw uninterpreted data.',
};

class ThankYouSelectionPage extends Component {
  state = { uninterpretedGenomicData: false, possibleMedicallyActionableFindings: false };

  componentDidMount() {
    gaTrack(GA_TR_THANKYOU_PAGE);
    const { gotoHomeAddress, goToSelectService } = this.props;
    const selectionData = selectionService.getVerificationData();
    schedulingservices.clearWizardClientData();
    if (selectionData && selectionData.additionalData) {
      this.setStateData(selectionData);
    } else {
      goToSelectService();
    }
  }
  setStateData(selectionData) {
    const { uninterpretedGenomicData, possibleMedicallyActionableFindings } =
      selectionData.additionalData;
    this.setState({ uninterpretedGenomicData, possibleMedicallyActionableFindings });
  }
  renderCustomContent = (text) => {
    return (
      <SimpleWebinarMessage
        iconClass="success"
        title="Thank You!"
        subcomponent={
          <div data-hj-suppress>
            {' '}
            {text} <br />
            <br />
            You will receive an email from Genome Medical with information on next steps.
          </div>
        }
      />
    );
  };
  render() {
    const { uninterpretedGenomicData, possibleMedicallyActionableFindings } = this.state;

    return (
      <div className="simple-webinar">
        <FancyPageFrame>
          {uninterpretedGenomicData && possibleMedicallyActionableFindings
            ? this.renderCustomContent(message['both'])
            : uninterpretedGenomicData
            ? this.renderCustomContent(message['uninterpretedGenomicData'])
            : possibleMedicallyActionableFindings
            ? this.renderCustomContent(message['possibleMedicallyActionableFindings'])
            : ''}
        </FancyPageFrame>
      </div>
    );
  }
}
ThankYouSelectionPage.propTypes = {
  gotoHomeAddress: PropTypes.func.isRequired,
};
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    gotoHomeAddress: () => ownProps.history.push('/scheduling/home-address'),
    goToSelectService: () => ownProps.history.push('/scheduling/select-service'),
  };
};
export default connect(null, null, mergeProps)(ThankYouSelectionPage);
