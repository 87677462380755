import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Header from 'components/header/Header';
import { REDIRECT_AFTER_LOGIN } from 'ducks/misc/redirectActions';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';
import { withRouter } from 'react-router';

export class PublicLayout extends Component {
  static propTypes = {
    children: PropTypes.element,
    redirectActions: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  componentDidUpdate() {
    const {
      redirectActions: { actions },
      match: { params },
    } = this.props;
    if (params.activationToken) return;
    if (actions[REDIRECT_AFTER_LOGIN] && !this.redirected) this.redirectAfterSignIn();
  }

  redirected = false;

  redirectAfterSignIn = () => {
    const { history } = this.props;
    this.redirected = true;
    history.push('/login');
  };

  render() {
    const { children } = this.props;
    return (
      <div className="main-container">
        <Header signInClick={this.redirectAfterSignIn} currentPage={window.location.pathname} />
        {children}
        <GlobalLoader />
      </div>
    );
  }
}

export default connect((state) => ({
  redirectActions: state.redirectActions,
}))(withRouter(PublicLayout));
