import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { loadCurrentAppointment } from 'ducks/appointment';
import { loadAppointments } from 'ducks/appointments';
import { loadMeOutreach } from 'ducks/meOutreach';
import authService from 'services/api/authService';
import api from 'services/api/api';
import gaTrack, { GA_TR_HOME_PAGE } from 'services/gaTrack';

import { loadDocuments } from 'ducks/documents';
import {
  loadInsurance,
  getHomeInfo,
  requestVerificationMethod,
  grailGalleriReset,
} from 'ducks/user';
import {
  UploadPhoto,
  UpcomingAppointment,
  IncompleteTask,
  TestOrder,
  ViewDocument,
  Questionnaire,
  VerifyEmail,
  InsuranceDeductible,
  FollowupAppointment,
  ScheduleNewAppointment,
  ZingtreeQuestionnaire,
  GrailGalleri,
  BioPharmaTrailAuthorization,
} from 'components/widgets/SCP/PatientCards';
import TestInfoBoxContainer from 'components/widgets/SCP/TestInfoBox/TestInfoBoxContainer';
import VideoModal from 'components/modals/SCP/VideoModal/VideoModal';
import './Home.scss';
import { documentTypesNames, INS_FRONT_CARD, INS_BACK_CARD } from 'constants/DocumentsConstants';
import {
  TODAY,
  FROM_ONE_TO_SEVEN_DAYS,
  AFTER_SEVEN_DAYS,
  NO_APPOINTMENT,
  IN_PROGRESS,
  SCH_NEW_APPT_HIGH_PRIORITY,
  SCH_NEW_APPT_LOW_PRIORITY,
  FOLLOWUP_HIGH_PRIORITY_CARD,
  FOLLOWUP_LOW_PRIORITY_CARD,
} from 'constants/HomeAppointmentCardGoals';
import { setModalMode } from 'ducks/modals';
import ModalNames from 'constants/ModalNames';
import GrailStatusEnums from 'constants/GrailStatus';
import { isInsuranceDeductiblePeriod } from 'services/utils';
import Alert from 'components/widgets/Alert/Alert';
import { ConsentStatus } from '../../../../../constants/CommonConstants';
import schedulingService from '../../../../../services/api/schedulingService';

const isInsDeductiblePeriod = isInsuranceDeductiblePeriod();
const FETCHING_INTERVAL_MS = 30000;
const APPOINTMENT_STATUS = {
  booked: 1,
  completed: 1,
};

class HomePage extends Component {
  state = {
    videoURL: '',
    isMobile: false,
  };

  static propTypes = {
    currentUser: PropTypes.object,
    appointment: PropTypes.object,
    documents: PropTypes.array,
    currentScpEncounter: PropTypes.object,
    currentScpEncounterLoaded: PropTypes.bool,
    encounters: PropTypes.array,
    videoModal: PropTypes.object,
    location: PropTypes.object,
    requestEmailSended: PropTypes.bool,
    verifyEmail: PropTypes.func.isRequired,
    grailGalleriSuccess: PropTypes.isRequired,
    grailGalleriReset: PropTypes.func.isRequired,
  };

  componentDidMount() {
    gaTrack(GA_TR_HOME_PAGE);

    this.setState({ isMobile: schedulingService.isMobile() });
    window.addEventListener('resize', this.onResize);

    this.fetchingAppointment();
    this.props.loadDocuments();
    this.props.getHomeInfo();
    this.props.loadInsurance();
    this.props.loadMeOutreach();
    this.props.loadAppointments();
  }
  componentDidUpdate() {
    this.fetchingAppointment();
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.setViewMode();
  };

  setViewMode() {
    const { isMobile } = this.state;
    if (isMobile && !schedulingService.isMobile()) {
      this.setState({ isMobile: false });
    } else if (!isMobile && schedulingService.isMobile()) {
      this.setState({ isMobile: true });
    }
  }

  findAppointment = (id) => {
    const { appointments } = this.props;
    if (!_.isArray(appointments)) {
      return undefined;
    }
    const appointment = _.find(appointments, (obj) => {
      return obj.id == id;
    });
    return appointment;
  };

  fetchingAppointment = () => {
    const { appointment, loadAppointment } = this.props;
    if (!this.interval && !_.isEmpty(appointment) && !appointment.isComplete) {
      this.interval = setInterval(() => {
        const needToRequest = moment().add(15, 'minutes').isAfter(appointment.mStartTime);
        needToRequest ? loadAppointment() : Promise.resolve();
      }, FETCHING_INTERVAL_MS);
    }
  };

  InsuranceCardFilter = (documents, type = INS_FRONT_CARD) => {
    const filterstring =
      documentTypesNames.insuranceCard.toLowerCase() + '-' + type.toLowerCase() + '-';
    const filtercards = documents
      ? _.filter(documents, function (doc) {
          return (
            doc &&
            doc.type &&
            doc.type.name &&
            doc.type.name.toLowerCase() == documentTypesNames.insuranceCard.toLowerCase() &&
            doc.fileName.toLowerCase().indexOf(filterstring) !== -1
          );
        })
      : [];
    const sorteddocument = filtercards ? _.sortBy(filtercards, (val) => val.createdAt) : [];
    const insuranceCard = _.last(sorteddocument);
    return insuranceCard;
  };

  get InsuranceCardFront() {
    const { documents } = this.props;
    return this.InsuranceCardFilter(documents, INS_FRONT_CARD);
  }

  get InsuranceCardBack() {
    const { documents } = this.props;
    return this.InsuranceCardFilter(documents, INS_BACK_CARD);
  }

  get isFrontBackInsuranceCard() {
    return !_.isEmpty(this.InsuranceCardFront) && !_.isEmpty(this.InsuranceCardBack);
  }

  get isAlertUploadInsuranceCard() {
    const aptmntStatus = _.get(this, ['props', 'appointment', 'latestStatus', 'status'], '');
    const aptmntPaymentType = _.get(this, ['props', 'appointment', 'payment', 'payorType'], '');

    return (
      aptmntStatus in APPOINTMENT_STATUS &&
      aptmntPaymentType === 'insurance' &&
      !this.isFrontBackInsuranceCard
    );
  }

  get isAlertUploadInsuranceForTest() {
    const { currentScpEncounterLoaded, currentScpEncounter } = this.props;
    if (!currentScpEncounterLoaded) return false;
    const { isTestOrderSubType, useInsuranceForTest } = {
      ...currentScpEncounter,
    };

    return isTestOrderSubType && useInsuranceForTest && !this.isFrontBackInsuranceCard;
  }

  openModal = (videoURL) => {
    this.setState({ videoURL: videoURL }, () => {
      this.props.toogleModal(ModalNames.videoModal, { open: true });
    });
  };

  closeModal = () => {
    this.props.toogleModal(ModalNames.videoModal, { open: false });
  };

  showPreTestConsultationVideo = () => {
    const { homeInfo } = this.props;
    const consultation = _.get(homeInfo, 'consultation', []);
    const consultationType = _.get(consultation, ['0', 'consultationType'], '');
    if (consultation.length == 1) {
      return ['testing_guidance'].includes(consultationType);
    } else {
      return consultation.find((el) =>
        ['testing_guidance', 'results_review', 'follow_up'].includes(el.consultationType)
      );
    }
  };

  showPostTestConsultationVideo = () => {
    const { homeInfo } = this.props;
    const consultation = _.get(homeInfo, 'consultation', []);
    const consultationType = _.get(consultation, ['0', 'consultationType'], '');
    if (consultation.length == 1) {
      return ['results_review', 'c_d_t_consultation', 'follow_up'].includes(consultationType);
    } else {
      return consultation.find((el) =>
        ['testing_guidance', 'results_review', 'follow_up'].includes(el.consultationType)
      );
    }
  };

  verifyEmail = () => {
    const email = _.get(this, ['props', 'currentUser', 'email'], '');
    return this.props.verifyEmail({ email });
  };

  userIsGrailDisqualify = () => {
    return authService.getGrailDisqualification();
  };

  render() {
    const {
      currentUser,
      currentScpEncounterLoaded,
      documents,
      videoModal,
      requestEmailSended,
      insurance,
      highPriorityOutreach,
      lowPriorityOutreach,
      showScheduleNewAppointment,
      dispatch,
      grailGalleriSuccess,
      grailGalleriReset,
      exceptionalSpecialties,
      isOutreachAvailable,
      history,
    } = this.props;
    const { videoURL, isMobile } = this.state;
    if (currentUser != null && currentScpEncounterLoaded) {
      const {
        welcomeUserName,
        grailStatus,
        affiliation,
        grailGalleriOrderCreatedAt,
        showInsuranceCardToPP,
        isGrailFlowAffiliation,
        affiliationIsIllumina,
      } = currentUser;
      const { currentScpEncounter, encounters } = this.props;
      const { order, isTestOrderSubType, needAppointment, needConsent } = {
        ...currentScpEncounter,
      };

      const showConfirmNotice = currentUser && !currentUser.confirmed;
      const userConsentStatus = _.get(currentUser,['latestPatientToReconatctStatusObject','user_consent','status'],null);
      const labIsNatera = _.get(currentScpEncounter,['labName']) == 'Natera';
      const showTestInfoIntoMobile = labIsNatera && isMobile && !isOutreachAvailable;

      return (
        <div className="scp-home-container full-height">
          <div>
            <div className="title" data-hj-suppress>
              Welcome
              <span className="user-name">{welcomeUserName ? `, ${welcomeUserName}` : ''}!</span>
            </div>
            <div className="cards-wrapper">
              {grailGalleriSuccess && (
                <Alert
                  type={'success'}
                  message={
                    <div>
                      Your Galleri test request was successfully submitted. A Genome Medical
                      physician will review your information to determine if the test is right for
                      you. If appropriate, the test will be ordered and you will receive an email
                      within 1-2 business days with information on next steps.
                      <div className="galleri-note">
                        No further action is required at this time.
                      </div>
                    </div>
                  }
                  onClose={grailGalleriReset}
                  fade={true}
                  fadeOutTime={60 * 60 * 1000}
                />
              )}
            </div>
            <div className="cards-wrapper multi-section">
              <div className="patient-cards">
                {!_.isNil(userConsentStatus) && userConsentStatus === ConsentStatus.SKIPPED && (
                  <BioPharmaTrailAuthorization />
                )}
                {grailStatus &&
                  (grailStatus == GrailStatusEnums.SHOW_CARD ||
                    (!this.userIsGrailDisqualify() &&
                      affiliationIsIllumina &&
                      grailStatus == GrailStatusEnums.QUALIFICATION_REQUIRED)) && (
                    <GrailGalleri grailGalleriOrderCreatedAt={grailGalleriOrderCreatedAt} />
                  )}

                {showConfirmNotice && currentUser.email && (
                  <VerifyEmail
                    email={currentUser.email}
                    verifyEmail={this.verifyEmail}
                    requestEmailSent={requestEmailSended}
                  />
                )}

                {!_.isNil(insurance) && !_.isEmpty(insurance) && isInsDeductiblePeriod && (
                  <InsuranceDeductible />
                )}

                <UpcomingAppointment goal={IN_PROGRESS} />

                <UpcomingAppointment goal={TODAY} />

                <Questionnaire goal={'incomplete'} />

                <ZingtreeQuestionnaire />

                {this.isAlertUploadInsuranceCard && showInsuranceCardToPP && (
                  <UploadPhoto goal={'appointment'} dispatch={dispatch} />
                )}

                {this.isAlertUploadInsuranceForTest &&
                  !this.isAlertUploadInsuranceCard &&
                  showInsuranceCardToPP && <UploadPhoto goal={'test'} dispatch={dispatch} />}

                {(needAppointment || needConsent) && (
                  <IncompleteTask goal={needAppointment ? 'appointment' : 'test'} />
                )}

                <UpcomingAppointment goal={FROM_ONE_TO_SEVEN_DAYS} />

                <Questionnaire goal={'complete'} />

                {isTestOrderSubType &&
                  !_.isEmpty(order.status) &&
                  _.get(order, 'status') != 'error' && <TestOrder status={order.status} />}

                {documents && _.get(documents, 'length', 0) > 0 && <ViewDocument />}

                <UpcomingAppointment goal={AFTER_SEVEN_DAYS} />

                {!isGrailFlowAffiliation && (
                  <UpcomingAppointment goal={NO_APPOINTMENT} />
                )}
                {_.isArray(highPriorityOutreach) &&
                  _.map(highPriorityOutreach, (outreach) => {
                    return (
                      <FollowupAppointment
                        goal={FOLLOWUP_HIGH_PRIORITY_CARD}
                        meOutreach={outreach}
                        dispatch={dispatch}
                        exceptionalSpecialties={exceptionalSpecialties}
                        affiliation={affiliation}
                        isGrailFlowAffiliation={isGrailFlowAffiliation}
                      />
                    );
                  })}

                {showScheduleNewAppointment && (
                  <ScheduleNewAppointment
                    goal={
                      _.isEmpty(highPriorityOutreach)
                        ? SCH_NEW_APPT_HIGH_PRIORITY
                        : SCH_NEW_APPT_LOW_PRIORITY
                    }
                  />
                )}

                {_.isArray(lowPriorityOutreach) &&
                  _.map(lowPriorityOutreach, (outreach) => {
                    return (
                      <FollowupAppointment
                        goal={FOLLOWUP_LOW_PRIORITY_CARD}
                        meOutreach={outreach}
                        dispatch={dispatch}
                        exceptionalSpecialties={exceptionalSpecialties}
                        affiliation={affiliation}
                        isGrailFlowAffiliation={isGrailFlowAffiliation}
                      />
                    );
                  })}
              </div>

              <div
                className={classnames(
                  'test-info-box',
                  showTestInfoIntoMobile && 'natera-affilitaion'
                )}
              >
                <TestInfoBoxContainer
                  history={history}
                  type="test"
                  encounters={encounters}
                  className="mgb8px"
                />
              </div>
            </div>
            <VideoModal
              videoURL={videoURL}
              show={videoModal && videoModal.open}
              onCancel={this.closeModal}
            />
          </div>
        </div>
      );
    }

    return null;
  }
}

HomePage.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: authService.getCurrentUser(state),
    appointment: state.appointment.data,
    documents: state.documents && state.documents.data,
    currentScpEncounter: state.scpEncounters.currentScpEncounter,
    currentScpEncounterLoaded: state.scpEncounters.currentScpEncounterLoaded,
    encounters: state.scpEncounters.scpEncounters,
    videoModal: state.modals.videoModal,
    homeInfo: state.user && state.user.homeInfo && state.user.homeInfo,
    requestEmailSended: state.user.requestEmailSended,
    insurance: state.user && state.user.insurance,
    meOutreach: state.meOutreach.data && state.meOutreach.data,
    appointments: state.appointments.data,
    appointmentsLoaded: state.appointments.loaded,
    grailGalleriSuccess: state.user.grailGalleriSuccess,
    exceptionalSpecialties: state.user && state.user.exceptionalSpecialties,
  };
};

const mergeProps = (stateProps, dispatchProps) => {
  const {
    currentUser,
    appointment,
    documents,
    currentScpEncounter,
    currentScpEncounterLoaded,
    encounters,
    videoModal,
    homeInfo,
    requestEmailSended,
    insurance,
    meOutreach,
    appointments,
    appointmentsLoaded,
    grailGalleriSuccess,
    exceptionalSpecialties,
  } = stateProps;
  const { dispatch } = dispatchProps;
  const isGrailFlowAffiliation = currentUser?.isGrailFlowAffiliation;
  const isAppointmentAvailable = !_.isNil(appointment) && !_.isEmpty(appointment);
  const isAppointmentsAvailable = !_.isNil(appointments) && _.get(appointments, ['length'], 0) > 0;
  const isOutreachAvailable = !_.isNil(meOutreach) && _.get(meOutreach, ['length'], 0) > 0;
  const isAppointmentBooked =
    isAppointmentAvailable &&
    !appointment.isComplete &&
    !appointment.isPast &&
    !appointment.isMissed;
  const findAppointment = (id) => {
    if (!isAppointmentsAvailable) {
      return undefined;
    }
    const aptmnt = _.find(appointments, (obj) => {
      return obj.id == id;
    });
    return aptmnt;
  };

  const outreachAppointment = isOutreachAvailable
    ? _.reduce(
        meOutreach,
        (outreachAppt, outreach) => {
          const app = findAppointment(outreach.appointmentID);
          if (!_.isNil(app) && !_.isEmpty(app) && app) {
            outreachAppt.push({ ...outreach, appointment: app });
          } else {
            outreachAppt.push({ ...outreach });
          }
          return outreachAppt;
        },
        []
      )
    : [];

  const sortedOutreachAppointment = _.orderBy(
    outreachAppointment || [],
    (or) => _.get(or, 'mLatestOutreachStartTime'),
    ['desc']
  );

  const filterHighPriorityOutreach = (outreach) => {
    const outreachDateDiffToToday = _.get(outreach, 'outreachDateDiffToToday', 0);
    const upcomingAppointment = _.get(outreach, ['upcomingAppointment']);
    if (
      outreachDateDiffToToday >= 0 &&
      outreachDateDiffToToday <= 30 &&
      upcomingAppointment == false
    ) {
      return true;
    }
    return false;
  };
  const highPriorityOutreach = _.filter(sortedOutreachAppointment || [], (obj) => {
    return !isAppointmentBooked && filterHighPriorityOutreach(obj);
  });
  const lowPriorityOutreach = _.filter(sortedOutreachAppointment || [], (obj) => {
    return !isAppointmentBooked && !filterHighPriorityOutreach(obj);
  });
  const showScheduleNewAppointment =
    !isGrailFlowAffiliation &&
    !isAppointmentBooked &&
    appointmentsLoaded &&
    isOutreachAvailable &&
    _.isEmpty(highPriorityOutreach);

  return {
    currentUser,
    appointment,
    documents,
    currentScpEncounter,
    currentScpEncounterLoaded,
    encounters,
    videoModal,
    homeInfo,
    requestEmailSended,
    insurance,
    appointments,
    dispatch,
    isOutreachAvailable,
    highPriorityOutreach,
    lowPriorityOutreach,
    showScheduleNewAppointment,
    outreachAppointment,
    grailGalleriSuccess,
    exceptionalSpecialties,
    loadAppointment: () => dispatch(loadCurrentAppointment()),
    loadDocuments: () => dispatch(loadDocuments()),
    toogleModal: (name, data) => dispatch(setModalMode(name, data)),
    getHomeInfo: () => dispatch(getHomeInfo()),
    verifyEmail: ({ email }) => dispatch(requestVerificationMethod({ email })),
    loadInsurance: () => dispatch(loadInsurance()),
    loadMeOutreach: () => dispatch(loadMeOutreach()),
    loadAppointments: () => dispatch(loadAppointments()),
    grailGalleriReset: () => dispatch(grailGalleriReset()),
  };
};

export default compose(connect(mapStateToProps, null, mergeProps), withRouter)(HomePage);
