// @flow
import _ from 'lodash';
import { hydrateStatus } from './appointmentTransformers';
import { hydratePaymentMethod } from 'models/User';


export function hydratePayment(payment: Object) {
  if (payment === null) return {};
  const paymentMethod = payment.payment_method && hydratePaymentMethod(payment.payment_method);
  const actualPaymentMethod =
    payment.actual_payment_method && hydratePaymentMethod(payment.actual_payment_method);
  const latestStatus = payment.latest_status && hydrateStatus(payment.latest_status);
  return _.omitBy(
    {
      paymentMethod,
      actualPaymentMethod,
      latestStatus,
      couponCodes: payment.coupon_codes,
      totalCharge: payment.total_charge,
      payorType: payment.payor_type,
    },
    _.isUndefined
  );
}