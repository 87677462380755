import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Modal from 'react-bootstrap/lib/Modal';
import { openChat } from 'services/utils';
import gmiMinimizedLogo from '../../../../images/minimized_logo.png';
import burgerIcon from './menu-icon.svg';
import closeIcon from './close-icon.svg';
import arrow from './../arrow.svg';
import emailIcon from './../../../forms/ResendPasswordLinkForm/email.svg';
import chatIcon from '../../../../images/common/chat.svg';
import phoneIcon from '../../../../images/common/phone-purple.svg';
import './MobileMenu.scss';

export default class MobileMenu extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    onLogin: PropTypes.func,
    onLogout: PropTypes.func.isRequired,
    hideSignIn: PropTypes.bool,
  };

  state = { menuOpened: false, openContactUsMenu: false };

  getItems = () => {
    const { pathname, hideSignIn, hideMenuAndProfile } = this.props;
    let liItems = [];
    liItems = [...this.props.items].map((o, i) => (
      <li
        className={classnames('item', { active: o.active })}
        key={i}
        onClick={this.onLinkClick}
        data-link={o.path}
      >
        {o.name}
      </li>
    ));
    hideMenuAndProfile &&
      liItems.push(
        <li
          className={classnames('item', 'profile-item', {
            active: pathname === '/patient/profile',
          })}
          key={7}
          onClick={this.onLinkClick}
          data-link={'/patient/profile'}
        >
          {'Profile'}
        </li>
      );
    liItems.push(
      <li className={classnames('item contactus-item')} key={8} onClick={this.toggleContactUsMenu}>
        {'Contact Us'} <img src={arrow} className="contactus-arrow" />
        <ul style={{ display: this.state.openContactUsMenu ? 'block' : 'none' }}>
          <li className="sub-menu">
            <a href="tel:+18776880992">
              <img src={phoneIcon} alt="Call" className="contactus-icon" /> Call
            </a>
          </li>
          <li className="sub-menu" onClick={openChat}>
            <img src={chatIcon} alt="chat" className="contactus-icon" /> Chat
          </li>
          <li
            className="sub-menu"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <a href="mailto:clinical@genomemedical.com">
              <img src={emailIcon} alt="Email" className="contactus-icon" /> Email
            </a>
          </li>
        </ul>
      </li>
    );
    !hideSignIn &&
      liItems.push(
        <li className={classnames('item')} key={9} onClick={this.props.onLogout}>
          {'Sign Out'}
        </li>
      );
    return liItems;
  };

  toggleContactUsMenu = () => {
    this.setState({ openContactUsMenu: !this.state.openContactUsMenu });
  };

  openMenu = () => {
    this.setState({ menuOpened: true });
  };

  closeMenu = () => {
    this.setState({ menuOpened: false });
  };

  onLinkClick = (e) => {
    const path = e.currentTarget.getAttribute('data-link');
    this.closeMenu();
    this.props.history.push(path);
  };

  onLoginClick = () => {
    this.closeMenu();
    this.props.onLogin();
  };

  get hasItems() {
    const items = this.getItems();
    return !_.isEmpty(items);
  }

  render() {
    return (
      <header className={classnames('scp-mobile-menu', 'ninetyDays')}>
        <div className="middel-block">
          <Link to="/">
            <img src={gmiMinimizedLogo} className="header-logo" />
          </Link>
        </div>
        {this.hasItems && <img className="open-button" src={burgerIcon} onClick={this.openMenu} />}
        <Modal
          className="scp-menu-modal md-full"
          show={this.state.menuOpened}
          onHide={this.closeMenu}
          animation={false}
          bsClass="scp-mobile-menu-modal modal"
        >
          <Modal.Body>
            <div className="modal-panel">
              <img className="close-button" src={closeIcon} onClick={this.closeMenu} />
            </div>
            <div className="menu-list">
              <ul>{this.getItems()}</ul>
            </div>
          </Modal.Body>
        </Modal>
      </header>
    );
  }
}
