import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from 'services/api/api';
import {
  addAction,
  REDIRECT_AFTER_LOGIN,
  OPEN_SWITCH_TO_PHONE,
  OPEN_SWITCH_TO_VIDEO,
  DOCUMENT_VIEW,
  MANUAL_CONSENT_OUTREACH,
} from 'ducks/misc/redirectActions';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';
import gaTrack, {
  GA_TR_PATIENT_SURVEY_PAGE,
  GA_TR_APPOINTMENT_SWITCH_TO_PHONE_PAGE,
} from '../../services/gaTrack';

import LoginPage from './public/LoginPage/LoginPage';
import _ from 'lodash';

class RedirectFromOutside extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object,
    redirectActions: PropTypes.object,
  };

  componentDidMount() {
    const { dispatch, history } = this.props;
    const redirect = (url) => {
      api.hasToken() ? history.push(url) : dispatch(addAction(REDIRECT_AFTER_LOGIN, url));
    };
    switch (this.props.name) {
      case 'questionnaire':
        gaTrack(GA_TR_PATIENT_SURVEY_PAGE);
        redirect('/patient/questionnaire');
        break;
      case 'switch-to-phone':
        gaTrack(GA_TR_APPOINTMENT_SWITCH_TO_PHONE_PAGE);
        dispatch(addAction(OPEN_SWITCH_TO_PHONE));
        redirect('/patient');
        break;
      case 'switch-to-video':
        gaTrack(GA_TR_APPOINTMENT_SWITCH_TO_PHONE_PAGE);
        dispatch(addAction(OPEN_SWITCH_TO_VIDEO));
        redirect('/patient');
        break;
      case 'document-view':
        dispatch(addAction(DOCUMENT_VIEW));
        redirect(`/patient/results`);
        break;
      case 'appointment-outreach':
        if (!_.isNil(_.get(this, ['props', 'match', 'params', 'appointmentId']))) {
          redirect(
            `/patient/outreach/${_.get(this, ['props', 'match', 'params', 'appointmentId'])}`
          );
        } else {
          redirect(`/patient/outreach`);
        }

        break;
      case 'consent-outreach':
        dispatch(addAction(MANUAL_CONSENT_OUTREACH));
        redirect('/patient/home');
        break;
      case 'patient':
      case 'login':
        redirect('/patient');
    }
  }

  render() {
    return this.props.name ? <LoginPage /> : <GlobalLoader />;
  }
}

export default connect()(RedirectFromOutside);
