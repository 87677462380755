import _ from 'lodash';
import React from 'react';
import classnames from 'classnames';
import AccessTimeIcon from './access_time.svg';
import './NoConsultationErrorScreen.scss';
import BreadcrumbProgress from '../../../../../components/scp/registration/BreadcrumbProgress';

const NoConsultationErrorScreen = (props) => {
  const { isMobile, activeStage, workflow, onBack, user, location, onLogout, hasConsultation } =
    props;

  return (
    <div className={classnames('scp-schedule-container', isMobile && 'mobile-schedule-container')}>
      <div className="scp-scheduling-page full-height no-footer">
        <BreadcrumbProgress
          activeStage={activeStage}
          workflow={workflow}
          onBack={onBack}
          isSchedulingWizardPage={true}
          user={user}
          location={location}
          onLogout={onLogout}
          hasConsultation={hasConsultation}
        />
        <div className="no-consultation-section">
          <div className="info-section">
            <h3 className="header">
              <img src={AccessTimeIcon} className="access-time-icon" alt="access-time" />
              <span>Please check back later</span>
            </h3>
          </div>
          <div className="body-section">
            <div>
              You are currently unable to schedule this type of appointment, possibly because your
              genetic testing results are not yet ready. You will receive an email from Genome
              Medical once your results are ready and will then be able to schedule your review
              appointment.
            </div>
            <br />
            <div>
              If you have any questions, please contact Genome Medical at{' '}
              <span className="contact-number">877-688-0992.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoConsultationErrorScreen;
