import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import SelectField from 'components/forms/controls/V2/SelectField';
import InputField from 'components/forms/controls/V2/InputField';
import Button from 'components/forms/controls/ButtonV2';
import InputMaskField from 'components/forms/controls/V2/InputMaskField';
import { required, validZipCode, todayDate, sameValue } from 'services/validate';
import Alert from 'components/widgets/Alert/Alert';
import { GetStaticPath } from 'services/utils';

const validateStateRequired = required('State');
const validateAddressLine1 = required('Address Line 1');
const validateCity = required('City');
const validateZipCodeRequired = required('Zip Code');
const validateZipCode = validZipCode('Zip Code');
const validateDateRequired = required('Date');
const validateSignatureRequired = required('Signature');
const validateTodayDate = todayDate(
  "Date is incorrect. Please enter today's date in MM/DD/YYYY format",
  'MM/DD/YYYY'
);

const validate = (values) => {
  const {
   addressFullName,
   consent_signature
  } = values;
  const errors = {};
  const errorSameValue = sameValue(addressFullName, "The signature doesn't look right")(consent_signature);
  if(consent_signature && errorSameValue){
    errors['consent_signature'] = errorSameValue
  }

  return errors;
};

function ConsentForm(props) {
  const {
    submitting,
    valid,
    handleSubmit,
    states,
    isAnyCheckboxChecked,
    initialValues: { addressFullName },
    onSubmit,
    formError,
  } = props;

  return (
    <form className="galleri-consent-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="main-fields-container">
        <div className="narrow-fields-container">
          <div className="fields-container">
            <div className="fields-title">SHIP LAB KIT TO</div>
            <div className="delivery-address">
              <div className="form-row">
                <div className="form-col">
                  <div className="label-section required">Address Line 1</div>
                  <Field
                    name="addressLine1"
                    placeholder="123 Main Street"
                    type="text"
                    component={InputField}
                    validate={[validateAddressLine1]}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-col">
                  <div className="label-section">Address Line 2</div>
                  <Field
                    name="addressLine2"
                    placeholder="Apt 123"
                    type="text"
                    component={InputField}
                  />
                </div>
              </div>
              <div className="form-row-container">
                <div className="form-row">
                  <div className="form-col city-col">
                    <div className="label-section required">City</div>
                    <Field
                      name="addressCity"
                      type="text"
                      placeholder="Enter"
                      component={InputField}
                      validate={[validateCity]}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-col state-col">
                    <div className="label-section required">State</div>
                    <Field
                      name="addressState"
                      component={SelectField}
                      placeholder="Select"
                      options={(states || []).map((obj) => {
                        return {
                          label: obj.value,
                          value: obj.value,
                          key: obj.value,
                        };
                      })}
                      validate={[validateStateRequired]}
                    />
                  </div>
                  <div className="form-col zip-col">
                    <div className="label-section required">Zip Code</div>
                    <Field
                      name="addressZipCode"
                      type="text"
                      placeholder="Enter"
                      component={InputField}
                      label="Zipcode"
                      validate={[validateZipCodeRequired, validateZipCode]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="consent-container">
            <div className="title">Informed Consent to Testing</div>
            <div className="content-text inline">
              This document provides information about GRAIL’s multi-cancer early detection blood
              test, Galleri®. It should supplement any discussions with a healthcare provider
              regarding the purposes, benefits, risks, and limitations of the test. The test can
              only be ordered by licensed healthcare providers, and undergoing this test is your
              choice. We encourage you to ask any questions you may have about this test.
            </div>
            <div className="content-text border">
              <div className="content-label test-purpose">
                <b>Purpose of the Test</b>
              </div>
              <div className="content-desc">
                The Galleri test is recommended for use in adults with an elevated risk for cancer,
                such as those aged 50 or older. The Galleri test is intended to be used in addition
                to, and not replace, other cancer screening tests your healthcare provider
                recommends. Use of Galleri is not recommended in individuals who are pregnant, 21
                years old or younger, or undergoing active cancer treatment.
                <br /> <br />
                The purpose of the Galleri test is to detect cancer signals that may be present in
                the blood at the time a blood sample is provided. All cells in your body, including
                cancer cells, release DNA fragments into the bloodstream; these fragments are called
                cell-free DNA (cfDNA). The cfDNA from cancer cells is different from the cfDNA of
                healthy cells. cfDNA from cancer cells has specific methylation patterns that
                identify it as cancer. The test analyzes DNA methylation in small fragments of
                circulating cell-free DNA in your bloodstream to determine if there are fragments
                that may have come from cancer cells. These abnormal DNA methylation patterns can be
                detected before symptoms emerge. If a cancer signal is detected, the test is also
                designed to identify where in your body the signal may be coming from. The test
                detects cancer signals from DNA in the blood but does not diagnose cancer.
                Information from this test is intended to help healthcare providers determine
                appropriate follow-up tests to confirm whether cancer is present.
              </div>
            </div>
            <div className="content-text">
              <div className="content-label">
                <b>Testing Process</b>
              </div>
              <div className="content-desc">
                GRAIL, LLC. (located in California and North Carolina, U.S.), the company that
                created and performs the Galleri test, will test your blood sample to look for
                circulating cell-free DNA that may have come from cancer cells. This may indicate
                the presence of cancer and if it is present, the location of the cancer. GRAIL will
                report the results of the test to your healthcare provider, who will share the test
                results with you. Depending on the test results and other clinical and risk factors,
                you and your healthcare provider may decide what additional follow-up tests, using
                clinically established procedures, are needed to confirm if cancer is present based
                on the results of the Galleri test.
              </div>
            </div>
            <div className="content-text">
              <div className="content-label">
                <b>Limitations of the Test</b>
              </div>
              <div className="content-desc">
                The Galleri test does not detect all cancers and should be used in addition to
                routine cancer screening tests recommended by a healthcare provider. Galleri is
                intended to detect cancer signals and predict where in the body the cancer signal is
                coming from. Results should be interpreted by a healthcare provider in the context
                of medical history, cancer risk factors, and clinical signs and symptoms. A test
                result of “cancer signal not detected” does not rule out cancer. A test result of
                "cancer signal detected" requires confirmatory diagnostic evaluation by medically
                established procedures (e.g., imaging) to confirm cancer. If cancer is not confirmed
                with further testing, it could mean that cancer is not present or testing was
                insufficient to detect cancer, including due to the cancer being located in a
                different part of the body. False-positive (a cancer signal detected when cancer is
                not present) and false-negative (a cancer signal not detected when cancer is
                present) test results do occur. The test is prescription (Rx) only.
              </div>
            </div>
            <div className="content-text">
              <div className="content-label">
                <b>Laboratory/Test Information</b>
              </div>
              <div className="content-desc">
                GRAIL’s clinical laboratory is certified under the Clinical Laboratory Improvement
                Amendments of 1988 (CLIA) and accredited by the College of American Pathologists.
                The Galleri test was developed, and its performance characteristics were determined
                by GRAIL. The Galleri test has not been cleared or approved by the Food and Drug
                Administration. GRAIL’s clinical laboratory is regulated under CLIA to perform
                high-complexity testing. The Galleri test is intended for clinical purposes.
              </div>
            </div>
            <div className="content-text">
              <div className="content-label">
                <b>Disclosure of Test Results</b>
              </div>
              <div className="content-desc">
                Your Galleri test results are confidential; these results, as well as your leftover
                blood sample and medical information, may be disclosed to the following limited
                group of individuals and organizations: <br />
                <ul>
                  <li>Your healthcare providers(or their designated representative); and/or</li>
                  <li>Genome Medical Services; and/or</li>
                  <li>
                    Your health insurance company (if GRAIL seeks to receive payment from your
                    insurer instead of from you directly or if your health insurance company needs
                    information about your test result in order to provide coverage for any
                    follow-up care).
                  </li>
                </ul>
                <br />
                You may also request your Galleri test results by contacting GRAIL Customer Service
                at <a href="tel:+1 833-694-2553">833-694-2553</a> or{' '}
                <a href="mailto:customerservice@grail.com">customerservice@grail.com</a>. However,
                given the complex nature of genetic testing, we strongly recommend that you obtain
                the clinical results directly from your healthcare provider and that you discuss
                test results with your healthcare provider.
              </div>
            </div>
            <div className="content-text">
              <div className="content-label">
                <b>Secondary Data Uses</b>
              </div>
              <div className="content-desc">
                The individuals or entities listed above may generate, keep, use, and/or share your
                test result information that has been removed of information that readily identifies
                you in accordance with applicable federal law. In addition to your Galleri test
                results, your leftover blood sample and medical information (including certain
                follow-up clinical information) that have been removed of information that readily
                identifies you may be shared within GRAIL and with third parties. These secondary
                uses may:
                <ul>
                  <li>
                    Improve or evaluate the performance, safety, effectiveness, or quality of
                    GRAIL’s products;
                  </li>
                  <li>
                    Help develop guidelines for health care providers to follow when other patients
                    receive a “Cancer Signal Detected” result from the test; and/or
                  </li>
                  <li>
                    Be used for test validation, research, and/or other future purposes, unless
                    prohibited by applicable law.
                  </li>
                </ul>
              </div>
            </div>
            <div className="content-text">
              <div className="content-label">
                <b>Retention of Samples</b>
              </div>
              <div className="content-desc">
                GRAIL may choose to destroy the sample at the end of the testing process or retain
                the processed blood sample indefinitely and use the blood sample without information
                that readily identifies you for certain activities, including research purposes, to
                the extent allowed by applicable law. You are not entitled to compensation for the
                use of your blood sample or rights to any products or discoveries resulting from use
                of your blood sample. If you do not want your leftover blood sample to be retained
                or used in the manner described in this consent, you may request that your sample be
                destroyed by contacting the GRAIL Customer Service Department: GRAIL Customer
                Service Department at <a href="tel:+1 833-694-2553">833-694-2553</a> or{' '}
                <a href="mailto:customerservice@grail.com">customerservice@grail.com</a>.
              </div>
            </div>
            <div className="content-text">
              <div className="content-label">
                <b>Ownership of Results</b>
              </div>
              <div className="content-desc">
                By agreeing to the Galleri test, you release your rights to biological samples
                collected and retained for testing and your rights to any results generated from the
                testing unless you specifically contact GRAIL Customer Service Department at{' '}
                <a href="tel:+1 833-694-2553">833-694-2553</a> or{' '}
                <a href="mailto:customerservice@grail.com">customerservice@grail.com</a> to request
                that your sample be destroyed.
              </div>
            </div>
            <div className="content-text">
              <div className="content-label">
                <b>Patient Acknowledgement</b>
              </div>
              <div className="content-desc">
                By requesting the Galleri test, I confirm that I have read the Galleri Patient
                Information above and any other information provided to me by Genome Medical or
                GRAIL (the maker of the Galleri test), and understand the purpose, risks, benefits,
                and alternatives of this test. I have discussed any questions I may have with a
                Genome Medical provider, and I understand that if I have any future questions or
                concerns related to this test, I will speak with a Genome Medical provider. I
                understand that my leftover blood sample may be retained indefinitely by GRAIL and I
                may ask in writing for the sample to be destroyed by contacting GRAIL Customer
                Service at <a href="mailto:customerservice@grail.com">customerservice@grail.com</a>.
              </div>
            </div>
            <div className="content-text">
              <div className="agree-text">
                I hereby agree to take part in this testing and consent to the collection of my
                specimen and the performance of the Galleri test. I understand that I may download
                the{' '}
                <a
                  href={
                    GetStaticPath() +
                    'patient/grailgalleri/eQB2YJl6YY4XeqCZ9YPpy5Z19WcbuW45qE1RrtP7SI82eANryggdsEggkjk8vUUEKVZXTUrUlRETDe5vGmlXHu3pl0kw4PPWYUeTCSmSdxmhTVdxJvaCjyJ5o70TITDC.pdf'
                  }
                  target="_blank"
                >
                  Patient Information Sheet
                </a>{' '}
                to keep for my records.
              </div>
            </div>
          </div>
          <div className="signature-container">
            <div className="form-row signature-form">
              <div className="form-col sign-col">
                <div className="label-section required">Signature (please print):</div>
                <Field
                  name="consent_signature"
                  type="text"
                  placeholder="John Smith"
                  component={InputField}
                  validate={[
                    validateSignatureRequired
                  ]}
                />
              </div>
            </div>
            <div className="form-row signature-form consented-at-field">
              <div className="form-col date-col">
                <div className="label-section required">Date</div>
                <Field
                  name="consented_at"
                  placeholder="MM/DD/YYYY"
                  component={InputMaskField}
                  mask="99/99/9999"
                  maskChar={null}
                  validate={[
                    validateDateRequired,
                    validateTodayDate
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        {formError && <Alert message={formError} />}
        <div className="buttons">
          <Button
            className="button button-next"
            type="submit"
            disabled={submitting || !valid || !isAnyCheckboxChecked}
          >
            I CONSENT
          </Button>
        </div>
      </div>
    </form>
  );
}

ConsentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  isAnyCheckboxChecked: PropTypes.bool.isRequired,
  states: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formError: PropTypes.isRequired,
};

export default reduxForm({
  form: 'grail-galleri-form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(ConsentForm);
