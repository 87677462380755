import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import InputField from 'components/forms/controls/V2/InputField';
import SelectField from 'components/forms/controls/V2/SelectField';
import validPostalCode from 'postcode-validator';

import { isEmpty, required, validPostalOrZipCode } from 'services/validate';
import track, { TR_PAYMENT_SUBMIT } from 'services/track';
import { HOME, getCountryStates, getCountriesOption } from 'services/utils';
import FilledCardBlock from './FilledCardBlock';
import { PAYMENTBYINSURANCE } from '../../../../../constants/CommonConstants';
import CouponApplierForm from '../CouponApplierForm/CouponApplierForm';
import Alert from 'components/widgets/Alert/Alert';
import { hydrateHomeAddressFields } from '../../../../../services/api/transformers/addressTransformers';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../../../../../containers/pages/patient/SCP/ProfilePage/CheckoutForm';
import { setSubmitFailure } from '../../../../../ducks/registrationWizard';
import { closeLoader, showLoader } from '../../../../../ducks/ui';
import schedulingService from '../../../../../services/api/schedulingService';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const SCP_PAYMENT_FORM_NAME = 'scp-payment-information-form';

const validateCountryRequired = required('Country');
const validateAddressLine1 = required('Address Line 1');
const validateCity = required('City');

const validate = (values) => {
  const errors = {};
  const zipCodeLabel = values.addressCountry === 'CA' ? 'Postal Code' : 'Zip Code';
  const StateLabel = values.addressCountry === 'CA' ? 'Province' : 'State';

  if (isEmpty(values.addressState)) {
    errors.addressState = `${StateLabel} is required`;
  }
  if (isEmpty(values.addressZipCode)) {
    errors.addressZipCode = `${zipCodeLabel} is required`;
  } else if (!validPostalCode.validate(values.addressZipCode, values.addressCountry)) {
    errors.addressZipCode = `${zipCodeLabel} is invalid`;
  }
  return errors;
};

class PaymentForm extends Component {
  state = {
    updatingCard: false,
    initialState: {},
    isSubmit: false,
    validateCreditCard: false,
    stripe: undefined,
    elements: undefined,
  };

  handleComponentState = (label, value) => {
    this.setState({ [label]: value });
  };

  onFormSubmit = async (data) => {
    const isZeroPrice = this.props.newPrice === 0;
    const paymentInfo = this.getPaymentInfo();
    if (!isZeroPrice && (_.isEmpty(paymentInfo) || this.state.updatingCard)) {
      this.setState({ isSubmit: true });
      await this.handlePaymentSubmit(data);
    } else {
      await this.props.onSkip();
    }
  };

  setAddress = () => {
    const { allAddress, user } = this.props;
    let newAddress = [...allAddress];
    let homeAddress = hydrateHomeAddressFields({
      ...this.state.initialState,
      fullName: _.get(user, ['me', 'fullName'], ''),
    });

    const oldHomeAddressIndex = newAddress.findIndex(
      (el) => el.addressType && el.addressType.includes(HOME)
    );
    if (oldHomeAddressIndex > -1) {
      const oldAddressObj = newAddress[oldHomeAddressIndex];
      let oldType = [..._.get(oldAddressObj, 'addressType', [])];
      if (oldType.length > 1) {
        let homeIndex = oldType.indexOf(HOME);
        if (homeIndex > -1) {
          oldType.splice(homeIndex, 1);
          newAddress[oldHomeAddressIndex] = { ...oldAddressObj, addressType: oldType };
          newAddress.push({ ...homeAddress, addressType: [HOME], addressId: undefined });
        }
      } else {
        newAddress[oldHomeAddressIndex] = {
          ...homeAddress,
          addressType: [HOME],
        };
      }
    } else {
      newAddress.push({ ...homeAddress, addressType: [HOME], addressId: undefined });
    }
    return newAddress;
  };

  handlePaymentSubmit = async (data) => {
    const { stripe, elements } = this.state;
    const { dispatch, onSubmit, handlePaymentMethod, user } = this.props;
    const addresses = this.setAddress();
    await onSubmit({
      addresses: addresses,
      paymentInfo: {
        ...data,
        cardholderName: user.me.fullName,
      },
    }).then((res) => {
      if (stripe) {
        dispatch(showLoader());
        stripe
          .confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
              payment_method_data: {},
            },
          })
          .then(function (result) {
            if (result.error) {
              dispatch(setSubmitFailure(result.error.message));
              dispatch(closeLoader());
            } else {
              handlePaymentMethod({
                ...data,
                setupIntentId: _.get(user, ['stripeIntent', 'id']),
                addressId: _.get(res, 'addressId', ''),
              });
            }
          });
      }
    });
  };

  updateCard = () => {
    const { initialValues } = this.props;
    this.setState({ updatingCard: true, initialState: initialValues }, () => {
      this.props.initialize({
        ...this.state.initialState,
      });
    });
  };

  get insurancePrice() {
    const { insurance, companies } = this.props;
    const insuranceCompany = companies.find(
      (c) => c.id === insurance?.primaryInsurance?.insuranceCompanyId
    );

    return insuranceCompany ? insuranceCompany.price : '';
  }

  componentDidMount() {
    const { initialValues, getStripeIntent } = this.props;
    if (initialValues) {
      this.setState({
        initialState: initialValues,
      });
    }
    getStripeIntent();
  }

  handleChange = (value, key) => {
    this.setState({ initialState: Object.assign({}, this.state.initialState, { [key]: value }) });
  };

  handleCountryChange = (value, key) => {
    if (this.state.initialState.addressCountry !== value) {
      let obj = {
        [key]: value,
        addressLine1: '',
        addressLine2: '',
        addressCity: '',
        addressState: '',
        addressZipCode: '',
      };
      if (this.props?.initialValues?.addressCountry == value) {
        obj['addressLine1'] = _.get(this.props, ['initialValues', 'addressLine1'], '');
        obj['addressLine2'] = _.get(this.props, ['initialValues', 'addressLine2'], '');
        obj['addressCity'] = _.get(this.props, ['initialValues', 'addressCity'], '');
        obj['addressState'] = _.get(this.props, ['initialValues', 'addressState'], '');
        obj['addressZipCode'] = _.get(this.props, ['initialValues', 'addressZipCode'], '');
      }
      this.setState({ initialState: Object.assign({}, this.state.initialState, obj) }, () => {
        this.props.untouch('addressLine1');
        this.props.untouch('addressLine2');
        this.props.untouch('addressCity');
        this.props.untouch('addressState');
        this.props.untouch('addressZipCode');
        this.props.initialize({
          ...this.state.initialState,
        });
      });
    }
  };

  getPaymentInfo = () => {
    const { initialValues } = this.props;
    let paymentInfo = { ...initialValues };
    delete paymentInfo['addressLine1'];
    delete paymentInfo['addressLine2'];
    delete paymentInfo['addressState'];
    delete paymentInfo['addressCity'];
    delete paymentInfo['addressZipCode'];
    delete paymentInfo['addressCountry'];
    delete paymentInfo['addressFullName'];
    delete paymentInfo['addressId'];
    delete paymentInfo['addressType'];
    delete paymentInfo['addressUUID'];

    return paymentInfo;
  };

  btDisabled = (submitting, valid) => {
    const { newPrice } = this.props;
    const paymentInfo = this.getPaymentInfo();
    const isZeroPrice =
      newPrice === 0 ||
      (this.props.insurance &&
        this.insurancePrice === 0 &&
        this.props.paymentby === PAYMENTBYINSURANCE);
    const isCardFilled = !this.state.updatingCard && !_.isEmpty(paymentInfo);
    if (isCardFilled || isZeroPrice) {
      return submitting || !valid;
    } else {
      return submitting || !valid || !this.state.validateCreditCard;
    }
  };

  isHomeAddressAvailable = () => {
    const { allAddress } = this.props;

    const homeAddress = allAddress.find(
      (el) => el && el.addressType && el.addressType.includes(HOME)
    );
    return !!homeAddress;
  };

  get payWithInsurance() {
    return !_.isEmpty(this.props.insurance) && schedulingService.getUseInsurance();
  }

  render() {
    const {
      user: { stripeIntent, stripeIntentLoading },
      user,
      CountriesStateEnums: { countries, states },
      isMobile,
    } = this.props;
    const clientSecret = _.get(stripeIntent, ['secret']);
    const allowedCountries = _.get(user, ['me', 'allowedCountries'], ['US']);
    const countryIsNonUS = _.get(user, ['me', 'countryIsNonUS'], false);

    const options = {
      clientSecret: clientSecret,
      appearance: {
        variables: {
          fontFamily: "'RionaSans', Arial, Helvetica, sans-serif",
          spacingUnit: '6px',
        },
        rules: {
          '.Label': {
            fontSize: '12px',
            textTransform: 'capitalize',
          },
          '.Input': {
            padding: isMobile ? '11px 8px' : '8px 8px',
            fontSize: '13px',
            border: '1px solid #bac3ce',
          },
          '.Input:focus': {
            borderColor: '#70cbf1',
          },
          '.Input--invalid': {
            backgroundColor: 'rgba(254, 145, 137, 0.5)',
            boxShadow: 'none',
          },
          '.Error': {
            fontSize: '12px',
            color: '#ff0000',
          },
        },
      },
    };
    const {
      submitting,
      initialValues,
      newPrice,
      handleSubmit,
      toPreviousStep,
      formError,
      valid,
    } = this.props;
    const paymentInfo = this.getPaymentInfo();
    const isCardFilled = !this.state.updatingCard && !submitting && !_.isEmpty(paymentInfo);
    const isZeroPrice =
      newPrice === 0 ||
      (this.props.insurance &&
        this.insurancePrice === 0 &&
        this.props.paymentby === PAYMENTBYINSURANCE);
    const showOutNetworkBlock =
      this.props.paymentby === PAYMENTBYINSURANCE && this.insurancePrice > 0;
    const StateLabel = this.state.initialState.addressCountry === 'CA' ? 'Province' : 'State';
    const zipCodeLabel =
      this.state.initialState.addressCountry === 'CA' ? 'Postal Code' : 'Zip Code';

    return (
      <Fragment>
        <form
          className="scp-payment-personal-form"
          onSubmit={handleSubmit(this.onFormSubmit) || track(TR_PAYMENT_SUBMIT)}
        >
          <div className="payment-sections">
            <div className="payment-section">
              {isZeroPrice && (
                <Fragment>
                  <div className="no-payment-section">
                    <div className="section-header">Credit or Debit Card</div>
                    <div className="not-required-text marg-btm-32">Not required</div>
                    <div className="section-header">Billing Address</div>
                    <div className="not-required-text">Not required</div>
                  </div>
                </Fragment>
              )}
              {!(isCardFilled || isZeroPrice) && (
                <Fragment>
                  <Alert message={formError} />
                  <div className="card-section">
                    <div className="section-header">Credit or Debit Card</div>
                    {!stripeIntentLoading && (
                      <div className="form-row">
                        <Elements stripe={stripePromise} options={options}>
                          <CheckoutForm
                            handleComponentState={this.handleComponentState}
                            defaultValues={{
                              billingDetails: {
                                name: _.get(user, ['me', 'fullName'], ''),
                                address: {
                                  country: this.props.initialValues.addressCountry,
                                  postal_code: this.props.initialValues.addressZipCode,
                                },
                              },
                            }}
                          />
                        </Elements>
                      </div>
                    )}
                  </div>
                  {!this.payWithInsurance && (
                    <div className="bill-add-section">
                      <div className="section-header">Billing Address</div>
                      {countryIsNonUS && (
                        <div className="form-row">
                          <div className="form-col full-col">
                            <div className="label-section required">Country</div>
                            <Field
                              name="addressCountry"
                              component={SelectField}
                              placeholder="Select"
                              options={getCountriesOption(countries, allowedCountries)}
                              validate={[validateCountryRequired]}
                              onChange={(e, value) =>
                                this.handleCountryChange(value, 'addressCountry')
                              }
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-row">
                        <div className="form-col full-col">
                          <div className="label-section required">Address Line 1</div>
                          <Field
                            name="addressLine1"
                            placeholder="123 Main Street"
                            type="text"
                            component={InputField}
                            validate={[validateAddressLine1]}
                            onChange={(e, value) => this.handleChange(value, 'addressLine1')}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-col full-col">
                          <div className="label-section">Address Line 2</div>
                          <Field
                            name="addressLine2"
                            placeholder="Apt 123"
                            type="text"
                            component={InputField}
                            onChange={(e, value) => this.handleChange(value, 'addressLine2')}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-col city-col">
                          <div className="label-section required">City</div>
                          <Field
                            name="addressCity"
                            type="text"
                            placeholder="Enter"
                            component={InputField}
                            validate={[validateCity]}
                            onChange={(e, value) => this.handleChange(value, 'addressCity')}
                          />
                        </div>
                        <div className="form-col state-col">
                          <div className="label-section required">{StateLabel}</div>
                          <Field
                            name="addressState"
                            component={SelectField}
                            placeholder="Select"
                            options={getCountryStates(
                              states,
                              this.state.initialState.addressCountry,
                              'code',
                              'code'
                            )}
                            // validate={[required(StateLabel)]}
                            onChange={(e, value) => this.handleChange(value, 'addressState')}
                            typeahead={true}
                            clearable={true}
                          />
                        </div>
                        <div className="form-col zip-col">
                          <div className="label-section required">{zipCodeLabel}</div>
                          <Field
                            name="addressZipCode"
                            type="text"
                            placeholder="Enter"
                            component={InputField}
                            label="Zipcode"
                            // validate={[required(zipCodeLabel), validPostalOrZipCode(zipCodeLabel, this.state.initialState.addressCountry ||
                            //   this.props.initialValues.addressCountry)]}
                            onChange={(e, value) => this.handleChange(value, 'addressZipCode')}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
              <div className="payment-info-block">
                {isCardFilled && !isZeroPrice && (
                  <FilledCardBlock
                    paymentMethod={initialValues}
                    user={user}
                    onUpdate={this.updateCard}
                  />
                )}
              </div>
            </div>
            <div className="coupon-section">
              {<CouponApplierForm {...this.props} />}
            </div>
          </div>
          <div className="infosection">
            {!(isCardFilled || isZeroPrice) && (
              <section className="holding-aptmnt-container">
                <div className="holding-aptmnt-txt-wrapper">
                  <h4>No charge until appointment is completed</h4>
                  <p className="holding-aptmnt-text">
                    To hold your appointment, please provide your credit card information. You will
                    NOT be charged until you have completed your appointment. We request your credit
                    or debit card issuer to place an authorization request or hold on your card for
                    $0.50. The primary reason for an authorization request or hold is to validate
                    the card you are using for payment. Please always check the amount of
                    availability on your card in advance of paying by credit or debit card.
                  </p>
                </div>
              </section>
            )}

            {isCardFilled && !isZeroPrice && (
              <section className="holding-aptmnt-container">
                <div className="holding-aptmnt-txt-wrapper">
                  <h4>No charge until appointment is completed</h4>
                  <p className="holding-aptmnt-text">
                    To hold your appointment, please provide your credit card information. You will
                    NOT be charged until you have completed your appointment. We request your credit
                    or debit card issuer to place an authorization request or hold on your card for
                    $0.50. The primary reason for an authorization request or hold is to validate
                    the card you are using for payment. Please always check the amount of
                    availability on your card in advance of paying by credit or debit card.
                  </p>
                </div>
              </section>
            )}
            {showOutNetworkBlock && (
              <section className="holding-aptmnt-container">
                <div className="holding-aptmnt-txt-wrapper">
                  <h4>Out-of-network benefits</h4>
                  <p className="holding-aptmnt-text">
                    If you have out-of-network benefits with your insurance plan, you may be
                    eligible to receive a full or partial refund of your $50 deposit, depending on
                    the outcome of your claim. However, if your insurance denies the claim or
                    applies it toward your deductible/coinsurance, your $50 deposit will be applied
                    toward the balance and you will receive a statement from Genome Medical. You
                    will never pay more than our $250 self-pay rate for your genetic counseling
                    session.
                  </p>
                </div>
              </section>
            )}
          </div>
          <div className="action-buttons">
            {toPreviousStep && (
              <button onClick={toPreviousStep} className="btn-back">
                Back
              </button>
            )}
            <button
              disabled={this.btDisabled(submitting, valid)}
              type="submit"
              className="btn-next"
            >
              Next
            </button>
          </div>
        </form>
      </Fragment>
    );
  }
}

PaymentForm.propTypes = {
  formError: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  insurance: PropTypes.object,
  companies: PropTypes.array,
  loading: PropTypes.bool,
  newPrice: PropTypes.number,
  onSkip: PropTypes.func,
  submitting: PropTypes.bool,
  toPreviousStep: PropTypes.func.isRequired,
  paymentby: PropTypes.string,
  allAddress: PropTypes.array,
  homeAddress: PropTypes.object,
  user: PropTypes.object,
};

export default reduxForm({
  form: SCP_PAYMENT_FORM_NAME,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  validate,
})(PaymentForm);
