import PropTypes from 'prop-types';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import classnames from 'classnames';

const ReCaptcha =(props)=>{

  const { horisontal, label, className, meta: { touched, error }, bottomError,input } = props;

  const onChange = value => {
    input.onChange(value);
  };

  return (
    <div
      className={classnames('gm-form-field', className, {
        horisontal,
        error: !bottomError && touched && error,
      })}>
      <div className={classnames('gm-form-label', { unstyled: horisontal })}>{label}</div>
      <div className="gm-form-control">
          <ReCAPTCHA sitekey="6Ler2WwUAAAAALUVtRP2bMHC4YjhfD_Gkw3Fv6XD" onChange={onChange} />
        {bottomError &&
          touched &&
          error && <label className="gm-form-label label-bottom error-label">{error}</label>}
      </div>
    </div>
  );

}

ReCaptcha.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  horisontal: PropTypes.bool,
  className: PropTypes.string,
  bottomError: PropTypes.bool,
};

export default ReCaptcha
