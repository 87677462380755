import api from './api';
import _ from 'lodash';
import { hydrateProvider } from './transformers/schedulingTransformers';


class WebinarService {
  getPartners() {
    return api.get('/partners').then(data => this.hydratePartners(data));
  }

  getPartnerCurrentWebinar(partnerName) {
    return api
      .get(`/partners/${partnerName}/webinars/current`)
      .then(data => this.hydrateBaseWebinar(data));
  }

  //---------------------------------------------------------------------------

  hydratePartners = data =>
    data.map(
      ({
        id,
        name,
        price_per_webinar_package,
        self_registration_enabled,
        registration_url,
        display_name,
        program_type,
        soft_reg_required,
        show_medicare_question,
        intro_page_version,
        uuid,
        allowed_countries,
        require_shipping_address
      }) => ({
        id,
        name,
        price: price_per_webinar_package,
        registration_url,
        self_registration_enabled,
        display_name,
        programType: program_type,
        softRegRequired: soft_reg_required,
        showMedicareQuestion: show_medicare_question,
        introPageVersion: intro_page_version,
        uuid,
        allowedCountries:  allowed_countries,
        countryIsNonUS : allowed_countries && _.isArray(allowed_countries) && allowed_countries.find(a=>a != 'US'),
        preSelectedCountry: allowed_countries && this.preSelectedCountry(allowed_countries),
        requireShippingAddress:  require_shipping_address
      })
    );

  preSelectedCountry = (countries) => {
    if (countries && countries.length > 0) {
      return countries[0];
    }
    return 'US';
  }

  hydrateBaseWebinar = data => ({
    id: data.id,
    title: data.title,
    startsAt: data.starts_at,
    registrationExpiresAt: data.registration_expires_at,
    registrationClosed: data.registration_closed,
    maxNumberOfAttendees: data.max_number_of_attendees,
    numberOfAttendees: data.number_of_attendees,
    completed: data.completed,
    timezone: data.timezone,
    presenter: data.presenter ? hydrateProvider(data.presenter) : null,
    postAction: data.post_action,
    postActionFeedback: data.post_action_feedback,
    testResultsAvailable: data.test_results_available,
    couponCode: data.coupon_code,
  });

  hydrateWebinar = data => ({
    ...this.hydrateBaseWebinar(data),
    videoSessionUrl: data.video_session_url,
    recordingUrl: data.recording_url,
    surveyUrl: data.survey_url,
  });

}

export default new WebinarService();
