import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import QuestionarieSurveyForm from './QuestionarieSurveyForm';
import QuestionarieZingtreeForm from './QuestionarieZingtreeForm';
import gaTrack, { GA_TR_QUESTIONNRIE_PAGE } from '../../../../services/gaTrack';
import RBAC from 'components/high-order-components/RBAC/RBAC';

class QuestionnairePage extends Component {
  componentDidMount() {
    gaTrack(GA_TR_QUESTIONNRIE_PAGE);
  }
  render() {
    const { surveyInfoLoaded, surveys, history } = this.props;
    const survey = _.get(surveys, ['0'], {});
    const isSurveyJSSurvey = _.get(survey, ['isSurveyJSSurvey'], false);
    const isZingtreeSurvey = _.get(survey, ['isZingtreeSurvey'], false);
    if (isSurveyJSSurvey) {
      return (
        <div className="scp-schedule-container">
          <h2 className="mobile-title">Questionnaire</h2>
          <div className="qn-page full-height">
            {surveyInfoLoaded && <QuestionarieSurveyForm history={history} />}
          </div>
        </div>
      );
    } else if (isZingtreeSurvey) {
      return (
        <div className="scp-schedule-container">
          <h2 className="mobile-title">Questionnaire</h2>
          <div className="qn-page full-height">
            {surveyInfoLoaded && <QuestionarieZingtreeForm history={history} />}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default RBAC(
  ['nonScp'],
  connect(({ answers }) => ({
    surveyInfoLoaded: answers.loaded,
    surveys: answers.surveys,
  }))(QuestionnairePage)
);
