import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import Application from 'services/application/Application';
import api from 'services/api/api';
import authService from 'services/api/authService';
import PublicLayout from 'containers/layouts/PublicLayout';
import PublicLayoutV2 from 'containers/layouts/PublicLayoutV2';
import PatientLayout from 'containers/layouts/PatientLayout';
import SCPPatientLayout from 'containers/layouts/SCPPatientLayout';
import { isFetching, appointmentsLoaded, hasAppointment } from './LayoutProps';
import CheckAuthActionPage from 'containers/pages/CheckAuthActionPage';
import { setConsentSource } from 'services/misc/ReferralSource';

export const PUBLIC_LAYOUT_TYPE = 'public';
export const PUBLIC_LAYOUT_TYPE_V2 = 'publicV2';
export const SCP_PATIENT_LAYOUT_TYPE = 'scp_patient';

export class GeneralLayout extends Component {
  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    if (this.props.update) this.props.update(this.context.router);
    const queryParams = new URLSearchParams(search);
    const consentsource = queryParams.get('consentsource');
    if (consentsource) {
      setConsentSource(consentsource);
    }
  }

  /**
   * To prevent blincking while requests are procceding
   */
  shouldComponentUpdate(nextProps) {
    return !nextProps.isFetching;
  }

  get actionToken() {
    const {
      location: { search },
    } = this.props;
    const queryParams = new URLSearchParams(search);
    const action_token = queryParams.get('action_token');
    return action_token;
  }

  componentDidUpdate() {
    if (this.props.update) this.props.update(this.context.router);
  }

  isLoggedIn() {
    return !!this.props.currentUser && !this.props.holdLoggedIn;
  }

  render() {
    const { isLayoutReady, layoutType, children, history } = this.props;

    if (this.actionToken)
      return <CheckAuthActionPage actionToken={this.actionToken} history={history} />;
    if (
      !api.hasToken() ||
      layoutType === PUBLIC_LAYOUT_TYPE ||
      layoutType === PUBLIC_LAYOUT_TYPE_V2
    ) {
      if (layoutType === PUBLIC_LAYOUT_TYPE_V2) {
        return <PublicLayoutV2>{children}</PublicLayoutV2>;
      } else if (!this.isLoggedIn() && layoutType === PUBLIC_LAYOUT_TYPE) {
        return <PublicLayoutV2>{children}</PublicLayoutV2>;
      } else if (layoutType === SCP_PATIENT_LAYOUT_TYPE) {
        return <SCPPatientLayout>{children}</SCPPatientLayout>;
      }
      return <PublicLayout>{children}</PublicLayout>;
    }

    if (isLayoutReady) {
  
      if (layoutType === SCP_PATIENT_LAYOUT_TYPE)
        return <SCPPatientLayout>{children}</SCPPatientLayout>;
      return <PatientLayout>{children}</PatientLayout>;
    }

    return <div />;
  }
}

GeneralLayout.contextTypes = {
  router: PropTypes.object.isRequired,
};

GeneralLayout.propTypes = {
  children: PropTypes.node.isRequired,
  layoutType: PropTypes.string.isRequired,
  isLayoutReady: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
  meLoading: PropTypes.bool,
  isFetching: PropTypes.bool,
  update: PropTypes.func.isRequired,
  location: PropTypes.object,
};

export default compose(
  connect((state, ownProps) => {
    const layoutType = ownProps.layoutType;
    const _currentUser = authService.getCurrentUser(state);
    const _appointmentsLoaded = _currentUser && appointmentsLoaded(state);
    const _hasAppointment = _currentUser && hasAppointment(state);
    const _holdLoggedIn = state.user.holdLoggedIn;
    const _isFetching = isFetching(state);

    const _isLayoutReady =
      (_currentUser && !_currentUser.confirmed &&  _hasAppointment) ||
      _hasAppointment ||
      _appointmentsLoaded;

    return {
      layoutType,
      currentUser: _currentUser,
      holdLoggedIn: _holdLoggedIn,
      isLayoutReady: !!_isLayoutReady && !_holdLoggedIn,
      hasAppointment: _hasAppointment,
      isFetching: _isFetching,
      scheduling: state.scheduling,
      update: (router) => Application.for(router, state, layoutType).update(),
    };
  })
)(withRouter(GeneralLayout));
