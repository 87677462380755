import React, { useEffect,useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import selectionService from 'services/api/selectionService';
import './SelectionForm.scss';
import { required as requiredvalidtion, ChooseOneCheckbox, required } from 'services/validate';
import fetchHoc from 'components/high-order-components/FetchHOC';
import Alert from 'components/widgets/Alert/Alert';
import Button from '../../controls/ButtonV2';

const validateRequired = required('Required field');
const Checkboxfield=({ input: { name, value, onChange }, meta: { touched, error }, label }) =>{
  return (
    <div className="gm-checbox-control">
      <div className="label-cbx">
        <div className="selection-checkbox">
          <input
            type="checkbox"
            name={name}
            checked={value}
            onChange={onChange}
            id={`selection-checkbox-for-${name}`}
          />
          <label for={`selection-checkbox-for-${name}`}>{label}</label>
        </div>
      </div>
      <div className="gm-create-account-error">{touched && error}</div>
    </div>
  );
}
const renderAgreeField=({ input: { name, value, onChange }, meta: { touched, error } })=> {
  return (
    <div>
      <div style={{ marginTop: '24px' }} />
      <h5 className="dividertext">Required Acknowledgement and Confirmation</h5>
      <div style={{ marginTop: '24px' }} />
      <div className="gm-checbox-control">
        <div className="label-cbx">
          <div className="selection-checkbox">
            <input
              type="checkbox"
              name={name}
              checked={value}
              onChange={onChange}
              id={`selection-agree-checkbox-for-${name}`}
            />
            <label for={`selection-agree-checkbox-for-${name}`} className="agree-text">
              By checking this box, I confirm that:
            </label>
          </div>
        </div>
      </div>
      <div className="gm-create-account-error">{touched && error}</div>
      <ul>
        <li>
          I have reviewed the information in the invitation letter and{' '}
          <a
            className="gm-link"
            href="https://www.genomemedical.com/programs/genentech/#faq"
            target="_blank"
          >
            Educational Content
          </a>
          {', '}
          and understand the benefits and risks of requesting raw uninterpreted genomic data
          and/or to be possibly alerted of medically actionable findings,
        </li>
        <li>
          I understand that the genetic data I may receive is 'research data' that should not be
          used by me or my doctor for medical/clinical decision making,
        </li>
        <li>
          I understand that if I request to be notified of medically actionable findings that the
          likelihood of being contacted is small; less than 5 out of 100 participants are expected
          to have a medically actionable finding and therefore, more than 95% of people will not
          be contacted, and
        </li>
        <li>
          I understand that if a medically actionable finding is identified in my gGap data, I
          have the option to submit a new sample for confirmatory testing through a clinical
          laboratory facilitated by Genome Medical.
        </li>
      </ul>
    </div>
  );
}
const renderCheckbox=(config) =>{
  const { label, name, required } = config;
  return (
    <Field
      name={name}
      type="checkbox"
      component={
        name === 'patientDisclaimer'
          ? renderAgreeField
          : Checkboxfield
      }
      label={label}
      validate={required ? validateRequired : null}
    />
  );
}

const SelectionForm = ({config, partner, history,handleSubmit, submitting, formError, valid}) =>{
  const [needSelection, setNeedSelection] = useState(null);

  useEffect(() => {
    const referralName = selectionService.getReferral();
    selectionService
      .getPartnerInfo(referralName)
      .then((response) => {
        const isNeed =response.selection_required;
        if (!isNeed) {
          history.push('/register/account');
        } else {
          setNeedSelection(isNeed);
        }
      })
      .catch(() => {
        history.push('/register/account');
      });

    if (!config.data && !config.loading) config.fetch(partner);
  }, []);

  return (
    needSelection && (
      <section className="selection-check-wrapper">
        <div className="selection-check-section full-height no-footer">
          {config.loaded && (
            <div className="form-wrapper">
              <div className="gm-selection-header">
                <div className="genome-header-logo">
                  <img className="genome-header-img" src={require('images/logo.svg')} />
                </div>
                <h2 className="sign-up-title">Request Access</h2>
              </div>
              <form
                className="gm-selection-check-form"
                autoComplete="off"
                onSubmit={handleSubmit}
                noValidate
              >
                <h3 className="gm-selection-check-header">Please Make Your Selection</h3>
                <h5 className="headingcheckbox">
                  Please make your selection from the two options below. It is important to
                  understand that these two options are separate, and you may request one
                  individually or both of them.
                </h5>
                <div className="gm-selection-check-fields">
                  {config.data.map((item, key) => {
                    const { type, name } = item;
                    const renderer = type == 'boolean' ?renderCheckbox: <div />;
                    return (
                      <div className="gm-selection-check-field" key={key}>
                        {renderer && renderer(item)}
                        {formError && formError.fields && (
                          <Alert message={formError.fields[name]} />
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="gm-selection-footer">
                  <Button className="submit-button" type="submit" disabled={submitting || !valid}>
                    <span>Continue</span>
                  </Button>
                  <div className="selection-warning">
                    This offering is only available to gGap and S4S participants that enrolled
                    between 1/1/2018-12/31/2018.
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </section>
    )
  );
}

SelectionForm.propTypes = {
  partner: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
  config: PropTypes.object.isRequired,
};

const form = reduxForm({
  form: 'selection-check-form',
  validate: ChooseOneCheckbox,
})(SelectionForm);

export default fetchHoc({
  config: (partner) => selectionService.getSelectionConfig(partner),
})(form);
