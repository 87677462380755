import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import authService from 'services/api/authService';
import { setSessionCouponCode, setCouponCodeReadOnly } from 'ducks/scheduling';
import {
  TestConsultation,
  ReviewConsultation,
  CurbsideConsultation,
  GetStartedConsultation,
} from 'constants/ServiceTypes';
import api from 'services/api/api';
import { addAction, REDIRECT_AFTER_LOGIN } from 'ducks/misc/redirectActions';

class RedirectToSchedule extends Component {
  state = { statusMessage: null };

  componentDidMount() {
    const {
      match: { params },
      location: { search },
      dispatch,
      history,
    } = this.props;
    const queryParams = new URLSearchParams(search);
    const code = queryParams.get('code');
    if (params.service) this.savePreselectedService(params.service);
    if (code) {
      dispatch(setSessionCouponCode(code, true));
      dispatch(setCouponCodeReadOnly(true));
    }
    if (api.hasToken()) {
      history.push('/scheduling/select-service');
    } else {
      this.props.dispatch(addAction(REDIRECT_AFTER_LOGIN, '/scheduling/select-service'));
      this.gotoLogin();
    }
  }

  savePreselectedService(preSelectedservice) {
    let service = '';
    if (preSelectedservice) {
      switch (preSelectedservice) {
        case 'testing-guidance':
          service = TestConsultation;
          break;
        case 'results-review':
          service = ReviewConsultation;
          break;
        case 'curbside-chat15':
          service = CurbsideConsultation;
          break;
        case 'getstarted':
          service = GetStartedConsultation;
          break;
      }
      authService.savePreselectedService(service);
    }
  }

  gotoHomeAddress() {
    this.props.history.push(`/scheduling/home-address`);
  }

  goToSelectService() {
    this.props.history.push(`/scheduling/select-service`);
  }

  gotoLogin() {
    this.props.history.push(`/login`);
  }

  render() {
    return <div />;
  }
}

RedirectToSchedule.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default connect()(RedirectToSchedule);
