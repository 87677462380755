import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { CONTACT_EMAIL } from 'services/constants';

const MailtoLink = ({ to = CONTACT_EMAIL, children = CONTACT_EMAIL, className, noStyle }) => (
  <Link
    className={classnames(className, { 'gm-link': !noStyle })}
    to={'mailto:' + to}
    style={{ cursor: 'pointer', color: 'inherit' }}
  >
    {children || to}
  </Link>
);

MailtoLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  noStyle: PropTypes.bool,
};

export default MailtoLink;
