import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './RemindInsuranceCard.scss';
import classnames from 'classnames';
import _ from 'lodash';
import upload from './insurance-upload-icon.svg';

const RemindInsuranceCard = (props) => {
  return (
    <Fragment>
      <div
        className={classnames(
          'header-alert-upload-insur-card',
          _.get(props, 'hasConfirmNotice', false) ? 'has-confirm-notice' : 'no-confirm-notice'
        )}
      >
        <div className="header-alert-upload-insur-card-text">
          <img src={upload} className="upload-insurance outline" alt="upload insurance card" />
          <span>Please upload your insurance card.</span>
          <Link to="/patient/results" className="header-alert-upload-insur-card-link">
            Upload Insurance Card
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default RemindInsuranceCard;
