import _ from 'lodash';
import moment from 'moment';
import validPostalCode from 'postcode-validator';

export const isEmpty = (value) => _([undefined, null, '']).includes(value);

export const required =
  (label = '', exact = false) =>
  (value) =>
    !isEmpty(value) ? undefined : exact ? label : label ? `${label} is required` : 'Required';

export const notEmptyObject =
  (label, exact = false) =>
  (value) =>
    !_.isEmpty(value) ? undefined : exact ? label : label ? `${label} is required` : 'Required';

export const email = (value) => {
  return value &&
    typeof value === 'string' &&
    value.length <= 140 &&
    value.match(/^[a-zA-Z0-9._%+-]+@[-\w]+(\.\w+)+$/gi)
    ? undefined
    : 'Invalid email address';
};

export const optionalEmail = (value) => (value ? email(value) : undefined);

export const patientName = (value) => {
  return value &&
    typeof value === 'string' &&
    value.match(/^(?:'?[A-z]+[-. ']?)+$/) &&
    value.length <= 45
    ? undefined
    : 'Invalid name';
};

export const optionalPatientName = (value) => (value ? patientName(value) : undefined);

export const phone = (value) =>
  value.replace(/[^\d]/g, '').length === 11 ? undefined : 'Invalid phone number';

export const phoneOrEmpty = (value) => (value ? phone(value) : undefined);

export const optionRequired = (label) => (option) =>
  required(label)(option && option.value !== undefined);

export const validName = (label) => (value) =>
  /^[A-Za-z \.]+$/.test(value)
    ? undefined
    : `${label || 'The field'} must contain only letters and dots.`;

export const strongPassword = (value) =>
  !value ||
  value.length < 8 ||
  !value.match(/\d/) ||
  !value.match(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/) ||
  !value.match(/[A-Z]/) ||
  !value.match(/[a-z]/)
    ? 'Password must be at least 8 characters long including at least one uppercase letter, one lowercase letter, one number, and one special character.'
    : undefined;

export const validZipCode = (label) => (value) =>
  /^\b\d{5}(-\d{4})?\b$/.test(value)
    ? undefined
    : `${label || 'Zip code'} must be a valid US 5 or 5+4 digit code.`;

export const validInsuranceZipCode = (label) => (value) => {
  if (value == undefined) {
    return;
  }
  return /^\b\d{5}(-\d{4})?\b$/.test(value)
    ? undefined
    : `${label || 'Zip code'} must be a valid US 5 or 5+4 digit code.`;
};

export const validPostalOrZipCode =
  (label, country = 'US') =>
  (value) =>
    validPostalCode.validate(value, country) ? undefined : `${label} is invalid`;

export const matchPassword = (values) =>
  values.password && values.password !== values.passwordRepeat
    ? { passwordRepeat: "Passwords don't match" }
    : {};

export const matchEmail = (values) =>
  values.email && values.email !== values.confirmEmail
    ? { confirmEmail: 'The email address you entered does not match' }
    : {};

export const descriptionText = (value) =>
  !value || /^[\w.,!?"'’|\/()&$ :;\-+%\s]+$/.test(value)
    ? undefined
    : 'The field must contain only letters, numbers and punctuation marks';

export const trimmedText = (value) => (!value || value.trim() ? undefined : 'Invalid string');

export const questionnaireText = (value) =>
  !value || /^[\w.,!?"'’|\/()&$ :;\-+%\s]+$/.test(value)
    ? undefined
    : 'The field must contain only letters, numbers and punctuation marks';

export const referText = (value) =>
  !value || /^[\w.,!?"'’|\/()&$:;\-+%@#\s]+$/.test(value)
    ? undefined
    : 'The field can contain only letters, numbers and punctuation marks';

export const assistantText = (value) =>
  !value || /^[\w.,!?"'’|\/()&$:;\-+%@#\s]+$/.test(value)
    ? undefined
    : 'The field can contain only letters, numbers and punctuation marks';

export const shouldBeTrue = (value) => (value === true ? undefined : 'Required');

export const validDob = (value) => {
  const wrongDay =
    "The day doesn't look right. Be sure to use a 2-digit number that is a day of the month.";
  if (_.isEmpty(value)) return 'Date of birth is required';
  if (typeof value === 'string') return undefined; // the string is some value from the backend in future
  const month = value.month;
  if (typeof month !== 'number') return 'Month is required';
  const year = parseInt(value.year);
  if (isNaN(year) || year < 1000 || year > 9999)
    return "The year doesn't look right. Be sure to use four digits.";
  const day = parseInt(value.day);
  if (isNaN(day) || day < 1 || day > 31) return wrongDay;
  const date = moment([year, month, day]);
  if (!date.isValid()) return wrongDay; // 31 february and cases like that
  return dateOfBirth("The date doesn't look right. Be sure to use your actual date of birth.")([
    year,
    month,
    day,
  ]);
};

export const dateOfBirth = (message) => (value) => {
  const msg = message || "The date doesn't look right";
  const date = moment(value);
  if (!date.isValid() || date.isBefore(moment().add('year', -120)) || date.isAfter(moment())) {
    return msg;
  }
  return undefined;
};

export const dateIsFuture =
  (message, format = 'MM/DD/YYYY') =>
  (value) => {
    const msg = message || "The date doesn't look right";
    if (value == '') return undefined;
    const date = moment(value, format, true);
    if (!date.isValid()) {
      return 'Date is invalid';
    } else if (date.isSameOrBefore(moment(), 'day')) {
      return msg;
    }
    return undefined;
  };

export const dateIsPast =
  (message, format = 'MM/DD/YYYY') =>
  (value) => {
    const msg = message || "The date doesn't look right";
    if (!value || value == '') return undefined;
    const date = moment(value, format, true);
    if (!date.isValid() || date.isBefore(moment().add('year', -120))) {
      return 'Date is invalid';
    } else if (date.isSameOrAfter(moment(), 'day')) {
      return msg;
    }
    return undefined;
  };

export const dateIsValid =
  (message, format = 'MM/DD/YYYY') =>
  (value) => {
    const msg = message || "The date doesn't look right";
    if (value == '') return undefined;
    const date = moment(value, format, true);
    if (!date.isValid()) {
      return msg;
    }
    return undefined;
  };

export const dateOfBirthOrEmpty =
  (message, format = 'MM/DD/YYYY') =>
  (value) => {
    if (value) {
      const msg = message || "The date doesn't look right";
      const date = moment(value, format, true);
      if (!date.isValid() || date.isBefore(moment().add('year', -120)) || date.isAfter(moment())) {
        return msg;
      }
    }
    return undefined;
  };

export const dateOfBirthStrictOrEmpty =
  (message, format = 'MM/DD/YYYY') =>
  (value) => {
    if (value) {
      const msg = message || "The date doesn't look right";
      const date = moment(value, format, true);
      if (!date.isValid() || date.isBefore(moment().add('year', -120)) || date.isAfter(moment())) {
        return msg;
      }
    }
    return undefined;
  };

export const fileName = (value) =>
  !value || /^[\w.,!?"'’|()&$:;\-+%\s\[\]]+$/.test(value)
    ? undefined
    : 'The field must contain only letters, numbers and punctuation marks';

export const ChooseOneCheckbox = (values) =>
  !(values.uninterpretedGenomicData || values.possibleMedicallyActionableFindings)
    ? {
        possibleMedicallyActionableFindings: 'Please make a selection.',
      }
    : {};

export const shouldBeBoolean =
  (label, exact = false) =>
  (value) =>
    _.isBoolean(value) ? undefined : exact ? label : label ? `${label} is required` : 'Required';

export const shouldValidABNOption = () => (value) => {
  if (value == 'option2') {
    return undefined;
  } else {
    if (value == 'option1') {
      return 'By choosing Option 1, I have chosen to terminate my registration with Genome Medical.';
    } else if (value == 'option3') {
      return 'By choosing Option 3, I have chosen to terminate my registration with Genome Medical.';
    } else {
      return 'choose valid option';
    }
  }
};

export const todayDate =
  (message, format = 'MM/DD/YYYY') =>
  (value) => {
    const msg = message || "The date doesn't look right";
    const date = moment(value, format, true);
    if (date.isValid() && date.isSame(moment(), 'day')) {
      return undefined;
    }
    return msg;
  };

export const sameValue = (val, message) => (value) => {
  return _.toLower(value) == _.toLower(val) ? undefined : message;
};

export const numberOnly = (message) => (value) => {
  if (value == undefined) {
    return;
  }
  return /^\d+$/.test(value) ? undefined : message;
};

export const Inches = (message) => (value) => {
  if (value == undefined) {
    return;
  }
  const inch = _.toNumber(value);
  return !_.isNaN(value) && inch > -1 && inch < 12 ? undefined : message;
};

export const integerNumberOnly = (message) => (value) => {
  let msg = message || 'Invalid integer number';
  if (value == undefined) {
    return;
  }
  return /^-?\d+$/.test(value) ? undefined : msg;
};

export const integerNumberRange = (min, max, message) => (value) => {
  let msg = message || `Enter number between ${min} to ${max}`;
  if (value == undefined) {
    return;
  }
  if (!/^-?\d+$/.test(value)) return 'Invalid integer number';
  else if (min && parseInt(value) < parseInt(min)) return msg;
  else if (max && parseInt(value) > parseInt(max)) return msg;
  return undefined;
};
