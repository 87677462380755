import moment from 'moment';
window.moment = moment; // convenient for testing moment behavior
import _ from 'lodash';

import store from './storage';
import track, { TR_OPEN_CHAT } from 'services/track';
import { CurbsideConsultation, CURBSIDE_15_CONSULATION_DURATION } from 'constants/ServiceTypes';
import { GetTimeZoneById } from 'services/geo';
import { documentTypesNames } from 'constants/DocumentsConstants';

import cardImage from '../images/common/card.svg';
import paymentImage from '../images/common/payment.svg';
import lockImage from '../images/common/lock.svg';

export const GM_FLASH_MESSAGE = 'GM_FLASH_MESSAGE';

export function getCardImage(icon) {
  switch (icon) {
    case 'lock':
      return lockImage;
    case 'payment':
      return paymentImage;
    case 'card':
      return cardImage;
    default:
      return null;
  }
}

export function makeCancelable(promise) {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)));
    promise.catch((error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)));
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
}

export function getDurationString(minutes) {
  if (minutes <= 60) return `${minutes} min`;
  return moment.duration(minutes, 'minutes').humanize();
}

export function getConsultationDuration(consultation) {
  return consultation.type === CurbsideConsultation
    ? CURBSIDE_15_CONSULATION_DURATION
    : consultation && consultation.duration;
}

export function openChat() {
  try {
    window.$zopim.livechat.window.show();
    track(TR_OPEN_CHAT, { url: window.location.href });
  } catch (error) {
    // ignore
  }
}

export function toValidDateString(rawString) {
  const parts = rawString.split('/');
  return `${parts[2]}-${parts[0]}-${parts[1]}`;
}

export function toCustomDateString(dateString) {
  const date = new Date(dateString);
  const dd = `0${date.getDate()}`.substr(-2, 2);
  const mm = `0${date.getMonth()}`.substr(-2, 2);
  return `${mm}/${dd}/${date.getFullYear()}`;
}

export function redirectWithFlash(message) {
  // TODO: implement flash display
  store.set(GM_FLASH_MESSAGE, message);
}

export function getOrigin() {
  return window.location.origin.replace('//portal', '//api');
}

export function getOriginPortal() {
  return window.location.origin.replace('//api', '//portal');
}

export function getOriginApi() {
  return getOrigin() + '/v1';
}

export function getAppointmentTitle(appointment) {
  return `${appointment.consultation.name} with ${appointment.provider.fullName}${
    appointment.provider.title ? ', ' + appointment.provider.title : ''
  }`;
}

export function getAppointmentDateInfo(appointment) {
  const start = moment(appointment.startTime);
  const end = moment(appointment.endTime);
  const diffMin = moment.duration(end.diff(start)).asMinutes();
  return (
    start.format('MMMM D. dddd h:mm-') +
    end.format('h:mm A (') +
    appointment.timezoneAbb +
    '), ' +
    diffMin +
    ' min'
  );
}

export function getLabelsFromValue(value, valuesList) {
  if (!value) return '';
  if (typeof value === 'string')
    return valuesList.filter((o) => o.name === value).map((o) => o.label);
  if (value instanceof Array)
    return valuesList.filter((o) => value.indexOf(o.name) >= 0).map((o) => o.label);
}

export function GetStaticPath() {
  return '/static/';
}

export function preprocessAnswer(answer) {
  if (answer instanceof Array) return answer.join('|');
  if (/^(\d{4})-(\d{2})-(\d{2})$/.test(answer)) return moment(answer).format('MMMM Do YYYY'); // prettify date
  return answer;
}

export function GetStatesForDropdown(states) {
  return _.reduce(
    states,
    (result, s) => {
      result[s.value] = s.label;
      return result;
    },
    {}
  );
}

export function formatPriceFree(price) {
  if (price === 0) return 'Covered by Your Referral Provider';
  const formattedPrice = formatPrice(price);
  return formattedPrice ? '$' + formattedPrice : '';
}

export function formatPrice(price) {
  if (price == null || isNaN(Number(price))) return "0.00";
  return Number(price).toFixed(2);
}


export function reduceSignupFormData(values) {
  const {
    email,
    timezone,
    phone,
    firstName,
    lastName,
    dob,
    sex,
    areaInterest,
    isPatient,
    isPatientUnborn,
    guardianLastName,
    guardianPhone,
  } = values;
  const isGuardian = isPatient ? false : true;
  const displayFirstName = isGuardian && isPatientUnborn ? 'Baby' : firstName;
  const displayLastName = isGuardian && isPatientUnborn ? guardianLastName : lastName;
  const displayPhone = isGuardian && isPatientUnborn ? guardianPhone : phone;

  return {
    email,
    timezone,
    phone: displayPhone,
    firstName: displayFirstName,
    lastName: displayLastName,
    dob,
    sex,
    areaInterest,
  };
}


export function getSCPFormattedDateAndTime(startTime, endTime, timezone) {
  const formattedDate = moment(startTime).format('MMMM D, dddd');
  const formattedStart = moment(startTime).format('h:mm');
  const formattedTimeZone = moment().tz(timezone).format('z');
  const formattedEnd = moment(endTime).format('h:mm A');
  return `${formattedDate} ${formattedStart}-${formattedEnd} ${formattedTimeZone}`;
}

export function getSCPFormattedAppointmentType(consultation, updatedPrice, hidePrice = true) {
  if (!consultation) return '';
  const price = !_.isNil(updatedPrice) ? updatedPrice : consultation.price;
  const duration = getConsultationDuration(consultation);
  const formattedDuration = getDurationString(duration);
  return `${consultation.name} (${formattedDuration}) ${
    !hidePrice ? 'for $' + formatPrice(price) : ''
  }`;
}

export function getFormattedAppointmentType(consultation, duration) {
  if (!consultation) return '';
  const formattedDuration = getDurationString(duration);
  return `${consultation.name} (${formattedDuration})`;
}

export function getFormattedRange(start, end) {
  if (start && end) {
    if (start.month() === end.month())
      return start.date() + '—' + end.date() + ' ' + moment(start).format('MMMM, YYYY');
    return moment(start).format('DD MMMM') + ' — ' + moment(end).format(' DD MMMM, YYYY');
  }
  return moment(start).format('DD MMMM, YYYY');
}

export function getSCPFormattedRange(start, end) {
  if (start && end) return moment(start).format('MMM D') + ' - ' + moment(end).format('MMM D');

  return moment(start).format('DD MMMM, YYYY');
}

export function GetCardType(number) {
  // visa
  let re = new RegExp('^4');
  if (number.match(re) != null) return 'Visa';

  // Mastercard Updated for Mastercard 2017 BINs expansion
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number
    )
  )
    return 'Mastercard';

  // AMEX
  re = new RegExp('^3[47]');
  if (number.match(re) != null) return 'AMEX';

  // Discover
  re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
  if (number.match(re) != null) return 'Discover';

  // Diners
  re = new RegExp('^36');
  if (number.match(re) != null) return 'Diners';

  // Diners - Carte Blanche
  re = new RegExp('^30[0-5]');
  if (number.match(re) != null) return 'Diners - Carte Blanche';

  // JCB
  re = new RegExp('^35(2[89]|[3-8][0-9])');
  if (number.match(re) != null) return 'JCB';

  // Visa Electron
  re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
  if (number.match(re) != null) return 'Visa Electron';

  return '';
}

export function isGoodYearInput(year) {
  if (!year) return true;
  const len = year.length;
  return (
    (len === 0 || ['1', '2'].includes(year[0])) &&
    (len < 2 || /^18|19|20[0-9]{0,2}/.test(year)) &&
    (len < 4 || Number(year) <= new Date().getFullYear())
  );
}

export function isGoodDayInput(day) {
  if (!day) return true;
  const len = day.length;
  return !isNaN(+day) && len < 3 && +day < 32;
}

export function cardNameController(str) {
  if (!str) return str;
  const lenStr = str.substring(0, 255);
  return /^[a-zA-Z]+\s?[a-zA-Z]*$/.test(lenStr) ? lenStr : lenStr.match(/^[a-zA-Z]+\s?[a-zA-Z]+/);
}

export function cvcController(cvc) {
  if (!cvc) return cvc;
  return /^\d{0,4}$/.test(cvc) ? cvc : cvc.match(/^\d{0,4}/);
}

export function zipController(zip) {
  if (!zip) return zip;
  return /^\d{0,5}(-\d{0,4})?$/.test(zip) ? zip : null;
}

export function creditCardController(number) {
  if (!number) return number;
  const newNumber = number.replace(/ /g, '');
  if (/^\d{0,19}$/.test(newNumber)) {
    const matches = newNumber.toString().match(/.{1,4}/g);
    if (matches) {
      return matches.join(' ');
    }
    return newNumber;
  }
  return null;
}

export function phoneController(text) {
  if (!text) return text;
  if (text === '+') return '+1 ';
  let number = text;
  if (text.indexOf('+1') !== 0) number = '+1' + text;

  number = number.replace(/[ \-]/g, '');
  if (/^\+1\d*$/.test(number)) {
    let result = '';
    for (let i = 0; i < 12 && i < number.length; i++) {
      if (i === 2) result += ' ';
      if (i === 5) result += ' ';
      if (i === 8) result += '-';
      result += number[i];
    }
    return result;
  }
  return null;
}

export const isItIframe = window.location !== window.parent.location;
export const isReferralIframe = window.location.pathname === '/referral';

export function findItemByName(collection, name) {
  return collection.find((d) => d.name && d.name.toLowerCase() === name);
}

export function allowedDocumentTypes(documentType) {
  return (
    documentType &&
    documentType.name &&
    (documentType.name.toLowerCase() == documentTypesNames.externalRecords ||
      documentType.name.toLowerCase() == documentTypesNames.geneticTestResults ||
      documentType.name.toLowerCase() == documentTypesNames.pedigree ||
      documentType.name.toLowerCase() == documentTypesNames.patientCommunication ||
      documentType.name.toLowerCase() == documentTypesNames.administrativeMisc)
  );
}

export function allowedDocumentUploadTypes(documentType) {
  return (
    documentType &&
    documentType.name &&
    (documentType.name.toLowerCase() == documentTypesNames.externalRecords ||
      documentType.name.toLowerCase() == documentTypesNames.geneticTestResults ||
      documentType.name.toLowerCase() == documentTypesNames.pedigree ||
      documentType.name.toLowerCase() == documentTypesNames.patientCommunication)
  );
}

export function createNewFileName(docType, firstName, lastName, ext) {
  const name = (docType && docType.name && docType.name.toLowerCase()) || '';
  const firstnameinitial = firstName && firstName.length > 0 ? firstName.substring(0, 1) + '.' : '';
  const myname = ((lastName && lastName) || '') + ',' + firstnameinitial;
  const newname = name + '-' + myname + '-' + moment.utc().format('YYYYMMDD-HHmmss') + ext;
  return newname;
}

export function CreateFileNameForInsuranceCard(docType, firstName, lastName, ext, type) {
  const name = (docType && docType.name && docType.name.toLowerCase()) || '';
  const firstnameinitial = firstName && firstName.length > 0 ? firstName.substring(0, 1) + '.' : '';
  const myname = ((lastName && lastName) || '') + ',' + firstnameinitial;
  const newname =
    name +
    '-' +
    type.toLowerCase() +
    '-' +
    myname +
    '-' +
    moment.utc().format('YYYYMMDD-HHmmss') +
    ext;
  return newname;
}

/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  let j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

export const getOriginPrefix = () => {
  const url = window.location.origin;

  if (url.indexOf('localhost') > -1) {
    return 'dev';
  }

  const regex = new RegExp(`^https:\/\/portal.(\\w+)[.genomemedical.com]+`);
  const matched = url.match(regex);
  return matched ? matched[1] : null;
};

export function getSpecialities() {
  return [
    {
      value: 'Cancer_Somatic',
      label: 'Cancer Somatic',
      description:
        'I or a close family member has/has had cancer, and I am interested in learning about testing the cancer/tumor for treatment/therapy-related decisions.',
    },
    {
      value: 'Cardiology',
      label: 'Cardiology',
      description:
        "I'd like to discuss genetic testing to better understand my personal or family history of heart disease",
    },
    {
      value: 'Cancer',
      label: 'Cancer',
      description: "I'd like to discuss my personal or family history of cancer",
    },
    {
      value: 'Healthy_Screening',
      label: 'Healthy Screening',
      description:
        "I'd like to discuss genetic testing to better understand my future health risks and there is not a specific disease that I am concerned about",
    },
    {
      value: 'Multispecialty',
      label: 'Multispecialty',
      description: 'Multispecialty is reserved for specific Genome Medical Partnerships.',
    },
    {
      value: 'Neurology',
      label: 'Neurology',
      description: "I'd like to discuss my personal or family history of a neurologic condition",
    },
    {
      value: 'Other_Adult_Genetics',
      label: 'Other Adult Genetics',
      description:
        'I have a personal or family history of a medical issue(s) that may have a genetic cause but are not related to any of the other specialty area choices listed here',
    },
    {
      value: 'Pediatrics',
      label: 'Pediatrics',
      description: "I'm concerned that my child's medical issue(s) may have a genetic cause",
    },
    {
      value: 'Pharmacogenomics',
      label: 'Pharmacogenomics',
      description:
        "I have an interest in understanding how genetics may influence my body's response to medications and possible risks of side effects",
    },
    {
      value: 'Prenatal',
      label: 'Prenatal',
      description:
        'I am currently pregnant and interested in genetic services related to this pregnancy',
    },
    {
      value: 'Reproductive',
      label: 'Reproductive',
      description: 'I am planning a pregnancy and interested in a genetic evaluation or screening',
    },
  ];
}

export function getModality() {
  return [
    {
      name: false,
      display_name: 'Video',
    },
    {
      name: true,
      display_name: 'Phone',
    },
  ];
}

export function reduceSoftSignupResponseData(data) {
  const {
    full_account_exists,
    soft_user_exists,
    uuid,
    switch_to_scp,
    survey_session_id,
    survey_tree_id,
  } = data;
  return {
    fullAccountExists: full_account_exists,
    uuid: uuid,
    softAccountExists: soft_user_exists,
    switchToScp: switch_to_scp,
    surveySessionId: survey_session_id,
    surveyTreeId: survey_tree_id,
  };
}

export function reduceEmailVerifyResponseData(data) {
  const { survey_session_id, survey_tree_id } = data;
  return {
    surveySessionId: survey_session_id,
    surveyTreeId: survey_tree_id,
  };
}

export function isInsuranceDeductiblePeriod() {
  const month = moment().format('MMM');
  return ['Dec', 'Jan', 'Feb', 'Mar'].includes(month);
}

export function currentYear() {
  const month = moment().format('MMM');
  try {
    const year = parseInt(moment().format('YYYY'));
    if (month == 'Dec') return year + 1;
    return year;
  } catch (error) {
    return '';
  }
}

export function isProductionOrStaging() {
  return process.env.GM_ENV == 'production' || process.env.GM_ENV == 'staging';
}

export const HOME = 'home';
export const SHIPPING = 'delivery';
export const BILLING = 'billing';

export function getHomeAddress(allAddress) {
  return (
    (allAddress || []).find((el) => el && el.addressType && el.addressType.includes(HOME)) || {}
  );
}
export function getShippingAddress(allAddress) {
  return (
    (allAddress || []).find((el) => el && el.addressType && el.addressType.includes(SHIPPING)) || {}
  );
}
export function getBillingAddress(allAddress) {
  return (
    (allAddress || []).find((el) => el && el.addressType && el.addressType.includes(BILLING)) || {}
  );
}

export function shouldBeMasked(maskedOn = ['staging', 'production']) {
  return new Set(maskedOn).has(process.env.GM_ENV);
}
export function getCountriesOption(countries, allowedCountries) {
  let result = [];
  allowedCountries.map((cou) => {
    const _country = countries && countries.find((a) => a.code == cou);
    if (_country) {
      result.push({
        label: _country.name,
        value: _country.code,
        key: _country.code,
      });
    } else {
      result.push({
        label: cou,
        value: cou,
        key: cou,
      });
    }
  });
  return result;
}

export function getCountryStates(states = [], country, label = 'name', value = 'code') {
  const selectedCountryStates = (states || []).filter((a) => a.countryCode == country);
  return selectedCountryStates.map((state) => {
    return {
      label: state[label],
      value: state[value],
      key: state.code,
    };
  });
}
