import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import ButtonV2 from 'components/forms/controls/ButtonV2';
import api from 'services/api/api';
import './PatientCards.scss';
import incompleteIcon from './incomplete-task.svg';
export default class IncompleteTask extends Component {
  static propTypes = {
    goal: PropTypes.string.isRequired,
  };

  get title() {
    const { goal } = this.props;
    let result = '';
    if (goal == 'appointment') {
      result = 'Let’s finish scheduling your appointment';
    }
    return result;
  }

  get description() {
    const { goal } = this.props;
    let result = '';
    if (goal == 'appointment') {
      result =
        'It seems you did not complete scheduling your appointment. No worries. We have saved your progress! If you have any questions or need any help, do not hesitate to contact our team.';
    } 
    return result;
  }

  get actionButton() {
    const { goal } = this.props;
    let result = '';
    if (goal == 'appointment') {
      result = <ButtonV2 path={"/scheduling/select-service"}>Continue Scheduling</ButtonV2>;
    } 
    return result;
  }

  render() {
    return (
      <Fragment>
        {this.title &&
          this.description &&
          this.actionButton && (
            <div className="upcoming-appointment">
              <div className="content">
                <div className="static">
                  <div className="left">
                    <div className="tag">
                      <span className="blue">Incomplete Task</span>
                    </div>
                    <div className="title">{this.title}</div>
                    <div className="text">{this.description}</div>
                  </div>
                  <div className="right">
                    <img className="visual-content" src={incompleteIcon} alt="Incomeplete Task" />
                  </div>
                </div>
                <div className="action-items left">{this.actionButton}</div>
              </div>
            </div>
          )}
      </Fragment>
    );
  }
}
