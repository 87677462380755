import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import _ from 'lodash';

import ConfirmationNotice from './ConfirmationNotice/ConfirmationNotice';
import RemindInsuranceCard from './RemindInsuranceCard/RemindInsuranceCard';
import MobileMenu from './MobileMenu/MobileMenu';
import UserMenu from './user-menu/UserMenu';
import api from 'services/api/api';
import { getLogoLink } from 'services/misc/ReferralSource';
import authService from 'services/api/authService';
import { logout } from 'ducks/auth/email';
import { canShowSurveyQuestionnaire } from 'ducks/answers';
import { requestVerificationMethod } from 'ducks/user';
import './Header.scss';
import { documentTypesNames, INS_FRONT_CARD, INS_BACK_CARD } from 'constants/DocumentsConstants';

const appointmentStatus = {
  booked: 1,
  completed: 1,
};

class Header extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    signInClick: PropTypes.func,
    currentUser: PropTypes.object,
    location: PropTypes.object.isRequired,
    requestEmailSended: PropTypes.bool,
    showQuestionnaire: PropTypes.bool.isRequired,
    onlyLogo: PropTypes.bool,
    customLogoLink: PropTypes.string,
    appointment: PropTypes.object,
    documents: PropTypes.array.isRequired,
  };

  isLoggedIn() {
    return !!this.props.currentUser && !this.props.holdLoggedIn;
  }

  isLoggedAndConfirmed() {
    return this.isLoggedIn() && this.props.currentUser.confirmed;
  }

  onTouchMove(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  getMenuItems = () => {
    const {
      showQuestionnaire,
      location: { pathname },
      onlyLogo,
    } = this.props;
    let items;
    if (api.hasToken() && !this.isLoggedIn()) return [];
    if (onlyLogo || !api.hasToken()) items = [];
    else {
        items = [
          {
            name: 'My Guide',
            path: '/patient',
            active: pathname === '/patient',
          },
          {
            name: 'Questionnaire',
            path: '/patient/questionnaire',
            hidden: !showQuestionnaire,
          },
          { name: 'My Health', path: '/patient/results' },
          { name: 'Profile', path: '/patient/profile' },
        ];
    }
    return items
      .filter((item) => !item.hidden)
      .map((item) =>
        Object.assign(item, {
          active: item.active === undefined ? pathname.includes(item.path) : item.active,
        })
      );
  };

  renderItems(itemsList) {
    if (!itemsList || itemsList.length === 0) return <li />;
    return itemsList.map((o, i) => (
      <li className={classnames({ active: o.active })} key={i}>
        <Link to={o.path}>{o.name}</Link>
        <div className="line" />
      </li>
    ));
  }

  verifyEmail = () => {
    const { email } = this.props.currentUser;
    return this.props.dispatch(requestVerificationMethod({ email }));
  };

  onLogout = () => this.props.dispatch(logout());
  LogoDisable = () => {
    return this.logoLink == '' ? true : false;
  };

  get logoLink() {
    if (this.props.customLogoLink) return this.props.customLogoLink;
    let url = window.location.origin.toLowerCase();
    //we are making this change because the code was replacing all the portal. string to "" which was failing on dev and qa environments. We want it replaced only for prod.
    // See EHR:864
    if (process.env.GM_ENV == 'production') {
      url = url.replace('portal.', '');
    }
    if (url[url.length - 1] === '/') url = url.slice(0, -1);
    // const referral =
    //   (this.props.currentUser && this.props.currentUser.referral) || getReferralSource();
    // if (referral) {
    //   url += BackUrls[referral] || '';
    // }
    const referral = getLogoLink();
    if (referral) {
      url = getLogoLink() || '';
    } else {
      url = '';
    }
    return url;
  }

  get InsuranceCardFront() {
    const { documents } = this.props;
    return this.InsuranceCardFilter(documents, INS_FRONT_CARD);
  }

  get InsuranceCardBack() {
    const { documents } = this.props;
    return this.InsuranceCardFilter(documents, INS_BACK_CARD);
  }

  InsuranceCardFilter = (documents, type = INS_FRONT_CARD) => {
    const filterstring =
      documentTypesNames.insuranceCard.toLowerCase() + '-' + type.toLowerCase() + '-';
    const filtercards = documents
      ? _.filter(documents, function (doc) {
          return (
            doc &&
            doc.type &&
            doc.type.name &&
            doc.type.name.toLowerCase() == documentTypesNames.insuranceCard.toLowerCase() &&
            doc.fileName.toLowerCase().indexOf(filterstring) !== -1
          );
        })
      : [];
    const sorteddocument = filtercards ? _.sortBy(filtercards, (val) => val.createdAt) : [];
    const insuranceCard = _.last(sorteddocument);
    return insuranceCard;
  };

  isFrontBackInsuranceCard = () => {
    return !_.isEmpty(this.InsuranceCardFront) && !_.isEmpty(this.InsuranceCardBack);
  };

  render() {
    const itemsList = this.getMenuItems();
    const hideSignIn = _.some(this.context.router.routes, (route) => route.hideSignIn);
    const {
      currentUser,
      signInClick,
      requestEmailSended,
      location: { pathname },
      onlyLogo,
      history,
    } = this.props;
    const showConfirmNotice =
      /^\/patient/i.test(pathname) && currentUser && !currentUser.confirmed;

    const aptmntStatus = _.get(this, ['props', 'appointment', 'latestStatus', 'status'], '');
    const aptmntPaymentType = _.get(this, ['props', 'appointment', 'payment', 'payorType'], '');
    const isAlertUploadInsuranceCard =
      aptmntStatus in appointmentStatus &&
      aptmntPaymentType === 'insurance' &&
      !this.isFrontBackInsuranceCard();

    return (
      <div className="header-container">
        {showConfirmNotice && (
          <ConfirmationNotice
            email={currentUser.email}
            verifyEmail={this.verifyEmail}
            requestEmailSended={requestEmailSended}
          />
        )}

        {isAlertUploadInsuranceCard && <RemindInsuranceCard hasConfirmNotice={showConfirmNotice} />}

        <header className="header" onTouchMove={this.onTouchMove}>
          {this.LogoDisable() ? (
            <a className="header-logo" />
          ) : (
            <a href={this.logoLink} className="header-logo" />
          )}

          <ul className="header-list">{this.renderItems(itemsList)}</ul>
          {!hideSignIn && !onlyLogo && (
            <div className="signin">
              {this.isLoggedIn() && (
                <UserMenu user={currentUser} onLogout={this.onLogout} />
              )}
            </div>
          )}
        </header>
        {!onlyLogo && (
          <MobileMenu
            hideSignIn={hideSignIn}
            items={itemsList}
            isLoggedIn={this.isLoggedIn()}
            onLogout={this.onLogout}
            onLogin={signInClick}
            history={history}
          />
        )}
      </div>
    );
  }
}

Header.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const documents = state.documents && state.documents.data;

  return {
    currentUser: authService.getCurrentUser(state),
    holdLoggedIn: state.user.holdLoggedIn,
    requestEmailSended: state.user.requestEmailSended,
    showQuestionnaire: canShowSurveyQuestionnaire(state),
    appointment: state.appointment.data,
    documents,
  };
};

export default compose(connect(mapStateToProps), withRouter)(Header);
