// @flow
import _ from 'lodash';
import api from './api';
import storage, { SessionStore } from 'services/storage';
import track, {
  TR_ACC_CREATED,
  TR_USER_LOGINED,
  TR_ACC_ACTIVATED,
  TR_USER_FORGOT,
} from 'services/track';
import {
  processCreateAccountError,
  processLoginError,
  processForgotPasswordError,
  processChangePasswordError,
  processEmailVerifyError,
  processSetPasswordError,
} from './transformers/errorTransformers';
import {
  dehydrateSignUp,
  dehydrateSignIn,
  dehydrateSetPassword,
} from './transformers/authTransformer';

const GM_PRESELECTED_SERVICE = 'GM_PRESELECTED_SERVICE';
const GM_AFFILIATION_ID = 'GM_AFFILIATION_ID';
const GM_SELECTED_AFFILIATION = 'GM_SELECTED_AFFILIATION';
const GM_SCP_DROP_OUT = 'GM_SCP_DROP_OUT';
const GM_QUESTIONNAIRE_DISQUALIFICATION = 'GM_QUESTIONNAIRE_DISQUALIFICATION';

class AuthService {
  register(params, istokenstored = true): Promise<{ token: string }> {
    const partnerId = this.getAffiliationId();
    if (!_.isNil(partnerId)) {
      params.partnerId = partnerId;
    }
    return api.auth
      .register(dehydrateSignUp(params))
      .then(data => {
        track(TR_ACC_CREATED);
        if (istokenstored && istokenstored === true) api.setToken(data.token, true);
        else data.token = '';
        return data;
      })
      .catch(error => {
        return processCreateAccountError(error, params);
      });
  }

  verifyEmail(uuid, params): Promise<any> {
    return api.auth.verifyEmail(uuid, params).catch(processEmailVerifyError);
  }

  login(params: { email: string, password: string }, rememberMe: boolean): Promise<any> {
    const dropOutData = this.getSCPDropOutData();
    if (!_.isEmpty(dropOutData)) {
      params = Object.assign({}, params, dropOutData);
    }
    return api.auth
      .login(dehydrateSignIn(params))
      .then(data => {
        track(TR_USER_LOGINED, { login: params.email });
        api.setToken(null);
        api.setToken(data.token, !rememberMe);
        return data;
      })
      .catch(processLoginError);
  }

  /**
   * @returns {Promise}
   */
  logout() {
    return new Promise(resolve => {
      api.setToken(null);
      resolve();
    });
  }

  verifyAccount({ token }): Promise<any> {
    return api.auth.verifyAccount({ token }).then(data => {
      track(TR_ACC_ACTIVATED);
      api.setToken(null);
      api.setToken(data.token);
      return data;
    });
  }

  requestAccountActivationEmail({ email }): Promise<any> {
    return api.auth.requestAccountActivateEmail({ email });
  }

  forgotPassword({ email, captcha }): Promise<any> {
    track(TR_USER_FORGOT, {
      login: email,
    });
    return api.auth.forgotPassword({ email, captcha }).catch(processForgotPasswordError);
  }

  // set password for a new user
  setPassword(values): Promise<any> {
    return api.auth.setPassword(dehydrateSetPassword(values)).catch(processSetPasswordError);
  }

  resetPassword({ token, password }): Promise<any> {
    return api.auth.resetPassword({ token, password });
  }

  refreshAccessToken({ token }): Promise<any> {
    return api.auth.refreshAccessToken({ token });
  }

  changePassword({ oldPassword, newPassword }) {
    return api.auth
      .changePassword({
        old_password: oldPassword,
        new_password: newPassword,
      })
      .catch(processChangePasswordError);
  }

  checkAction(actionToken) {
    return api.auth.checkAction({ action_token: actionToken }).then(r => ({
      action: r.action,
      resetToken: r.reset_token,
      email: r.email,
    }));
  }

  checkLink({ email, token, type }) {
    return api.auth.checkLink({ email, token, type });
  }

  resendSetPasswordActivateEmail({ email, captcha }) {
    return api.auth.resendSetPasswordActivateEmail({ email, captcha });
  }

  getCurrentUser(state: Object) {
    if (!api.getToken()) {
      api.setToken(null);
      return null;
    }
    return state.user.me;
  }

  saveAffiliationId(affiliationId: Number | undefined) {
    SessionStore.set(GM_AFFILIATION_ID, affiliationId);
  }

  resetAffiliationId() {
    SessionStore.remove(GM_AFFILIATION_ID);
  }

  getAffiliationId() {
    return SessionStore.get(GM_AFFILIATION_ID);
  }

  saveAffiliation(affiliationId: Number | undefined) {
    SessionStore.set(GM_SELECTED_AFFILIATION, affiliationId);
  }

  resetAffiliation() {
    SessionStore.remove(GM_SELECTED_AFFILIATION);
  }

  getAffiliation() {
    return SessionStore.get(GM_SELECTED_AFFILIATION);
  }

  savePreselectedService(name: string) {
    SessionStore.set(GM_PRESELECTED_SERVICE, name);
  }

  resetPreselectedService() {
    SessionStore.remove(GM_PRESELECTED_SERVICE);
  }

  getPreselectedService() {
    return SessionStore.get(GM_PRESELECTED_SERVICE);
  }

  getSCPDropOutData() {
    const data = SessionStore.get(GM_SCP_DROP_OUT);
    return !_.isEmpty(data) ? data : {};
  }

  saveGrailDisqualification(value: string) {
    SessionStore.set(GM_QUESTIONNAIRE_DISQUALIFICATION, value);
  }

  resetGrailDisqualification() {
    SessionStore.remove(GM_QUESTIONNAIRE_DISQUALIFICATION);
  }

  getGrailDisqualification() {
    return SessionStore.get(GM_QUESTIONNAIRE_DISQUALIFICATION);
  }
}

export default new AuthService();
