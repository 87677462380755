import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BreadcrumbProgress from 'components/scp/registration/BreadcrumbProgress';
import './ShippingWizardPage.scss';
import ShippingAddressForm from '../../../../../components/scp/forms/WizardForm/ShippingAddressForm/ShippingAddressForm';
import ScpTestReviewConfirm from '../../../../../components/scp/forms/WizardForm/TestReviewConfirm/TestReviewConfirm';
import { loadAllAddress } from 'ducks/user';
import { loadCurrentSCPEncounter, updateSCPEncounters } from '../../../../../ducks/scpencounters';
import { createAddress, updateAddress } from 'ducks/registrationWizard';
import PageTitle from 'components/widgets/PageTitle/PageTitle';
import {
  SHIPPING,
  HOME,
  getHomeAddress,
  getShippingAddress,
  getBillingAddress,
} from '../../../../../services/utils';

const workflow = [
  {
    id: 'STAGE_SELECT_TEST',
    name: 'Test',
    path: 'select-test',
    default: false,
  },
  {
    id: 'STAGE_SHIPPING_ADDRESS',
    name: 'Address',
    path: 'address',
    default: true,
  },
  {
    id: 'STAGE_TEST_CONFIRM',
    name: 'Review',
    path: 'review',
    default: false,
  },
];

class ShippingWizardPage extends Component {
  componentDidMount() {
    if (_.isEmpty(this.props.allAddress)) {
      this.props.loadAllAddress();
    }
    if (this.props.currentScpEncounterLoaded == false) {
      this.props.loadCurrentSCPEncounter();
    }
    this.navigateToHomeIfnotValidEncounter();
  }

  componentDidUpdate() {
    this.navigateToHomeIfnotValidEncounter();
  }

  navigateToHomeIfnotValidEncounter = () => {
    const {
      currentScpEncounterLoaded,
      currentScpEncounter,
      gotoHome,
      gotoTestOrderRequestConfirm,
    } = this.props;
    if (currentScpEncounterLoaded && currentScpEncounter) {
      if (!currentScpEncounter.isTestOrderSubType) {
        gotoHome();
      }
      if (!currentScpEncounter.needConsent) {
        gotoTestOrderRequestConfirm();
      }
    }
  };

  get onBack() {
    const { gotoShippingAddress, selectedStage } = this.props;

    switch (selectedStage) {
      case 'STAGE_SHIPPING_ADDRESS':
        return () => gotoShippingAddress();
      case 'STAGE_TEST_CONFIRM':
        return () => gotoShippingAddress();
    }
    return null;
  }

  submitShippingAddressInfo = (values) => {
    const { dispatch, allAddress } = this.props;
    const homeAddress = getHomeAddress(allAddress);
    if (homeAddress && homeAddress.addressUUID) {
      return updateAddress(values, dispatch).then(() => {
        this.props.gotoTestReviewConfirm();
      });
    } else {
      return createAddress(values, dispatch).then(() => {
        this.props.gotoTestReviewConfirm();
      });
    }
  };

  submitTestConsentInfo = (values) => {
    const { dispatch, currentScpEncounter } = this.props;
    const obj = Object.assign(values);

    return updateSCPEncounters(currentScpEncounter.id, obj, dispatch);
  };

  render() {
    const {
      selectedStage,
      homeAddress,
      shippingAddress,
      billingAddress,
      allAddressLoaded,
      currentScpEncounter,
      user,
      currentScpEncounterLoaded,
      allAddress,
    } = this.props;
    const valid =
      currentScpEncounterLoaded &&
      currentScpEncounter &&
      currentScpEncounter.isTestOrderSubType &&
      currentScpEncounter.needConsent;
    const addressType = _.get(homeAddress, 'addressType', []);
    return (
      <div className="scp-shippingwizard-container">
        <div className="scp-shippingwizard-page full-height no-footer">
          <PageTitle title="Test Request" />
          {valid && (
            <Fragment>
              <BreadcrumbProgress activeStage={selectedStage} workflow={workflow} />
              {selectedStage === 'STAGE_SHIPPING_ADDRESS' && allAddressLoaded && (
                <ShippingAddressForm
                  //toPreviousStep={this.onBack}
                  onSubmit={this.submitShippingAddressInfo}
                  user={user}
                  billingAddress={billingAddress}
                  allAddress={allAddress}
                  initialValues={{
                    ...homeAddress,
                    shippingAddressLine1: _.get(shippingAddress, 'addressLine1', ''),
                    shippingAddressLine2: _.get(shippingAddress, 'addressLine2', ''),
                    shippingAddressCity: _.get(shippingAddress, 'addressCity', ''),
                    shippingAddressState: _.get(shippingAddress, 'addressState', ''),
                    shippingAddressZipCode: _.get(shippingAddress, 'addressZipCode', ''),
                    shippingSameAsHomeAddress:
                      addressType.includes(HOME) && addressType.includes(SHIPPING),
                  }}
                />
              )}
              {selectedStage == 'STAGE_TEST_CONFIRM' && (
                <ScpTestReviewConfirm
                  toPreviousStep={this.onBack}
                  onSubmit={this.submitTestConsentInfo}
                  currentScpEncounter={currentScpEncounter}
                  shippingAddress={shippingAddress}
                  personalInfo={_.get(user, 'me', {})}
                />
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, scpEncounters } = state;
  return {
    user,
    scpEncounters,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { history } = ownProps;
  const { user, scpEncounters } = stateProps;
  const { dispatch } = dispatchProps;

  let { allAddress, allAddressLoaded } = user;

  const homeAddress = getHomeAddress(allAddress);
  const shippingAddress = getShippingAddress(allAddress);
  const billingAddress = getBillingAddress(allAddress);

  let { currentScpEncounter, currentScpEncounterLoaded } = scpEncounters;
  const defaultStage = workflow.find((s) => s.default == true);
  const currentStage = workflow.find((s) => s.path === ownProps.match.params.step);
  const selectedStage = currentStage ? currentStage.id : defaultStage.id;
  return {
    selectedStage,
    user,
    allAddress,
    homeAddress,
    shippingAddress,
    billingAddress,
    currentScpEncounter,
    allAddressLoaded,
    currentScpEncounterLoaded,
    loadAllAddress: () => dispatch(loadAllAddress()),
    loadCurrentSCPEncounter: () => dispatch(loadCurrentSCPEncounter()),
    gotoShippingAddress: () => history.push(`/patient/testrequest/address`),
    gotoTestReviewConfirm: () => history.push(`/patient/testrequest/review`),
    gotoTestOrderRequestConfirm: () => history.push('/patient/testorder'),
    gotoHome: () => history.push('/patient/home'),
    dispatch,
  };
};

ShippingWizardPage.propTypes = {
  selectedStage: PropTypes.string,
  allAddress: PropTypes.array,
  allAddressLoaded: PropTypes.bool,
  currentScpEncounterLoaded: PropTypes.bool,
  currentScpEncounter: PropTypes.object,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  allHomeAddress: PropTypes.func.isRequired,
  loadCurrentSCPEncounter: PropTypes.func.isRequired,
  gotoShippingAddress: PropTypes.func.isRequired,
  gotoTestReviewConfirm: PropTypes.func.isRequired,
  gotoTestOrderRequestConfirm: PropTypes.func.isRequired,
  gotoHome: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null, mergeProps)(ShippingWizardPage);
