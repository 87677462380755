import PropTypes from 'prop-types';
import React from 'react';
import { Link as IndexLink } from 'react-router-dom';

import Link from 'components/widgets/Link/Link';
import MailtoLink from 'components/widgets/Link/MailtoLink';
import Button from 'components/forms/controls/Button';
import PageTitle from 'components/widgets/PageTitle/PageTitle';
import { isReferralIframe } from 'services/utils';
import './ThankYouPage.scss';

const ThankYouPage = ({ referNewPatient }) => (
  <div className="thank-you-page">
    <PageTitle title="Thank you for your referral" />
    <div className="content">
      <p>
        Our Genetic Care Coordinator will reach out to your patient to schedule an appointment.{' '}
      </p>
      <p>
        If your patient would like to connect with our practice immediately, please ask them to do
        any of the following:
      </p>
      <ul className="ref-actions">
        <li>
          Send an email to <MailtoLink />
        </li>
        <li>
          Call us at{' '}
          <span className="phone">
            <a href="tel:+18776880992">877-688-0992</a>
          </span>
        </li>
        <li>
          Go to <Link to="/">genomemedical.com</Link> and self-schedule an appointment with us
        </li>
      </ul>
      <div className="buttons-container">
        {!isReferralIframe && (
          <IndexLink className="back-button" to="/">
            BACK TO HOMEPAGE
          </IndexLink>
        )}
        <Button className="refer-new" type="submit" onClick={referNewPatient}>
          REFER NEW PATIENT
        </Button>
      </div>
    </div>
  </div>
);

ThankYouPage.propTypes = {
  referNewPatient: PropTypes.func,
};

export default ThankYouPage;
