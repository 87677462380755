import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import SelectField from 'components/forms/controls/V2/SelectField';
import InputField from 'components/forms/controls/V2/InputField';
import Button from 'components/forms/controls/ButtonV2';
import CheckBoxField from 'components/forms/controls/V2/CheckBoxField';
import { required, validPostalOrZipCode } from 'services/validate';
import './ShippingAddressForm.scss';
import { GetStates } from 'services/geo';
import gaTrack, { GA_TR_TESTREQUEST_ADDRESS_PAGE } from 'services/gaTrack';
import { HOME, SHIPPING, BILLING, getShippingAddress } from 'services/utils';
import classnames from 'classnames';
import { get, isEmpty } from 'lodash';
import {
  hydrateHomeAddressFields,
  hydrateShippingAddressFields,
} from '../../../../../services/api/transformers/addressTransformers';
export const SCP_SHIPPING_ADDRESS_FORM_NAME = 'scp-shipping-address-form';

const states = GetStates();

const validateAddressLine1 = required('Address line 1');
const validateCity = required('City');
const validateState = required('State');
const requiredZipCode = required('Zip Code');
const validPostalCode = validPostalOrZipCode('Zip Code', 'US');

class ScpShippingAddressForm extends Component {
  state = {
    submissionError: null,
    isShippingAsHomeAddress: false,
  };
  componentDidMount() {
    gaTrack(GA_TR_TESTREQUEST_ADDRESS_PAGE);
    const { initialValues } = this.props;
    if (initialValues) {
      this.setState({
        initialState: initialValues,
        isShippingAsHomeAddress: get(initialValues, 'shippingSameAsHomeAddress', false),
      });
    }
  }

  onSubmit = async (values) => {
    let { user, allAddress, initialValues } = this.props;

    let newAddress = [...allAddress];
    let addresses = [];

    let homeAddress = hydrateHomeAddressFields({
      ...values,
      fullName: get(user, 'me.fullName', ''),
    });

    const savedShippingAddress = getShippingAddress(allAddress);
    let shippingAddress = hydrateShippingAddressFields({
      ...values,
      addressId:
        get(initialValues, 'shippingSameAsHomeAddress', false) != this.state.isShippingAsHomeAddress
          ? undefined
          : get(savedShippingAddress, 'addressId', undefined),
      fullName: get(user, 'me.fullName', ''),
    });

    const billingAddress = newAddress.find(
      (el) => el.addressType && el.addressType.includes(BILLING)
    );
    const bilingSameAsHome = newAddress.find(
      (el) => el.addressType && el.addressType.includes(HOME) && el.addressType.includes(BILLING)
    );
    const bilingSameAsShipping = newAddress.find(
      (el) =>
        el.addressType && el.addressType.includes(SHIPPING) && el.addressType.includes(BILLING)
    );
    const bilingSameAsHomeAndShippingBoth = newAddress.find(
      (el) =>
        el.addressType &&
        el.addressType.includes(HOME) &&
        el.addressType.includes(SHIPPING) &&
        el.addressType.includes(BILLING)
    );

    if (!isEmpty(billingAddress)) {
      if (this.state.isShippingAsHomeAddress) {
        if (bilingSameAsHome || bilingSameAsShipping || bilingSameAsHomeAndShippingBoth) {
          addresses.push({ ...homeAddress, addressType: [HOME, SHIPPING, BILLING] });
        } else {
          addresses.push({ ...homeAddress, addressType: [HOME, SHIPPING] }, { ...billingAddress });
        }
      } else {
        if (bilingSameAsHomeAndShippingBoth || bilingSameAsHome) {
          addresses.push(
            { ...homeAddress, addressType: [HOME, BILLING] },
            { ...shippingAddress, addressType: [SHIPPING] }
          );
        } else if (bilingSameAsShipping) {
          addresses.push(
            { ...homeAddress, addressType: [HOME] },
            { ...shippingAddress, addressType: [SHIPPING, BILLING] }
          );
        } else {
          addresses.push(
            { ...homeAddress, addressType: [HOME] },
            { ...shippingAddress, addressType: [SHIPPING] },
            { ...billingAddress }
          );
        }
      }
    } else {
      if (this.state.isShippingAsHomeAddress) {
        addresses.push({ ...homeAddress, addressType: [HOME, SHIPPING] });
      } else {
        addresses.push(
          { ...homeAddress, addressType: [HOME] },
          { ...shippingAddress, addressType: [SHIPPING] }
        );
      }
    }
    await this.props.onSubmit(addresses);
  };

  setHomeAsShippingAddress = (e, value) => {
    const { initialValues } = this.props;
    this.setState({ isShippingAsHomeAddress: value }, () => {
      if (value) {
        this.updateShippingAddress();
      } else {
        this.props.initialize({
          ...this.state.initialState,
          shippingSameAsHomeAddress: false,
          shippingAddressLine1: (initialValues && initialValues.shippingAddressLine1) || '',
          shippingAddressLine2: (initialValues && initialValues.shippingAddressLine2) || '',
          shippingAddressCity: (initialValues && initialValues.shippingAddressCity) || '',
          shippingAddressState: (initialValues && initialValues.shippingAddressState) || '',
          shippingAddressZipCode: (initialValues && initialValues.shippingAddressZipCode) || '',
        });
      }
    });
  };

  updateShippingAddress = () => {
    const { addressLine1, addressLine2, addressState, addressCity, addressZipCode } =
      this.state.initialState;
    this.props.initialize({
      ...this.state.initialState,
      shippingSameAsHomeAddress: this.state.isShippingAsHomeAddress,
      shippingAddressLine1: addressLine1 || '',
      shippingAddressLine2: addressLine2 || '',
      shippingAddressCity: addressCity || '',
      shippingAddressState: addressState || '',
      shippingAddressZipCode: addressZipCode || '',
    });
  };

  handleChange = (value, key) => {
    this.setState(
      { initialState: Object.assign({}, this.state.initialState, { [key]: value }) },
      () => {
        const addressFields = [
          'addressLine1',
          'addressLine2',
          'addressCity',
          'addressState',
          'addressZipCode',
        ];
        if (key && addressFields.includes(key) && this.state.isShippingAsHomeAddress) {
          this.updateShippingAddress();
        }
      }
    );
  };

  render() {
    const { submitting, handleSubmit, valid, user } = this.props;
    const countryIsNonUS = get(user, ['me', 'countryIsNonUS'], false);
    const zipCodeLabel = countryIsNonUS ? 'Postal Code' : 'Zip Code';

    return (
      <div className="scp-shipping-wizard scp-shipping-address-stage">
        <h2 className="gm-select-service__header">Your Address</h2>
        <form className="scp-shipping-address-form" onSubmit={handleSubmit(this.onSubmit)}>
          <div className="main-fields-container">
            <div className="narrow-fields-container">
              <div className="form-row address-title">Home Address</div>
              <div className="form-row">
                <div className="form-col">
                  <div className="label-section required">Address Line 1</div>
                  <Field
                    name="addressLine1"
                    placeholder="123 Main Street"
                    type="text"
                    component={InputField}
                    validate={[validateAddressLine1]}
                    onChange={(e, value) => this.handleChange(value, 'addressLine1')}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-col">
                  <div className="label-section">Address Line 2</div>
                  <Field
                    name="addressLine2"
                    placeholder="Apt 123"
                    type="text"
                    component={InputField}
                    onChange={(e, value) => this.handleChange(value, 'addressLine2')}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-col city-col">
                  <div className="label-section required">City</div>
                  <Field
                    name="addressCity"
                    type="text"
                    placeholder="Enter"
                    component={InputField}
                    validate={[validateCity]}
                    onChange={(e, value) => this.handleChange(value, 'addressCity')}
                  />
                </div>
                <div className="form-col state-col">
                  <div className="label-section required">State</div>
                  <Field
                    name="addressState"
                    component={SelectField}
                    placeholder="Select"
                    label="State"
                    options={(states || []).map((obj) => {
                      return {
                        label: obj.value,
                        value: obj.value,
                        key: obj.value,
                      };
                    })}
                    validate={[validateState]}
                    onChange={(e, value) => this.handleChange(value, 'addressState')}
                  />
                </div>
                <div className="form-col zip-col">
                  <div className="label-section required">Zip Code</div>
                  <Field
                    name="addressZipCode"
                    type="text"
                    placeholder="Enter"
                    component={InputField}
                    label="Zipcode"
                    validate={[requiredZipCode, validPostalCode]}
                    onChange={(e, value) => this.handleChange(value, 'addressZipCode')}
                  />
                </div>
              </div>
            </div>
            <div className="narrow-fields-container">
              <div className="form-row address-title">Shipping Address</div>
              <div
                className={classnames(
                  'form-row shipping-checkbox',
                  this.state.isShippingAsHomeAddress ? 'active' : ''
                )}
              >
                <Field
                  name="shippingSameAsHomeAddress"
                  component={CheckBoxField}
                  type="checkbox"
                  onChange={this.setHomeAsShippingAddress}
                  label={
                    <Fragment>
                      <span className="checkmark" />
                      <div className="label-section">Same as Home Address</div>
                    </Fragment>
                  }
                />
              </div>
              <div className="form-row">
                <div className="form-col">
                  <div className="label-section required">Address Line 1</div>
                  <Field
                    name="shippingAddressLine1"
                    placeholder="123 Main Street"
                    type="text"
                    component={InputField}
                    validate={[validateAddressLine1]}
                    disabled={this.state.isShippingAsHomeAddress}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-col">
                  <div className="label-section">Address Line 2</div>
                  <Field
                    name="shippingAddressLine2"
                    placeholder="Apt 123"
                    type="text"
                    component={InputField}
                    disabled={this.state.isShippingAsHomeAddress}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-col city-col">
                  <div className="label-section required">City</div>
                  <Field
                    name="shippingAddressCity"
                    type="text"
                    placeholder="Enter"
                    component={InputField}
                    validate={[validateCity]}
                    disabled={this.state.isShippingAsHomeAddress}
                  />
                </div>
                <div className="form-col state-col">
                  <div className="label-section required">State</div>
                  <Field
                    name="shippingAddressState"
                    component={SelectField}
                    placeholder="Select"
                    label="State"
                    options={(states || []).map((obj) => {
                      return {
                        label: obj.value,
                        value: obj.value,
                        key: obj.value,
                      };
                    })}
                    validate={[validateState]}
                    disabled={this.state.isShippingAsHomeAddress}
                  />
                </div>
                <div className="form-col zip-col">
                  <div className="label-section required">Zip Code</div>
                  <Field
                    name="shippingAddressZipCode"
                    type="text"
                    placeholder="Enter"
                    component={InputField}
                    label="Zipcode"
                    validate={[requiredZipCode, validPostalCode]}
                    disabled={this.state.isShippingAsHomeAddress}
                  />
                </div>
              </div>
            </div>

            {this.state.submissionError && (
              <div style={{ marginLeft: '44px', clear: 'both' }} className="error">
                {this.state.submissionError}
              </div>
            )}
            <div className="buttons">
              <Button className="button button-next" type="submit" disabled={submitting || !valid}>
                Next
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ScpShippingAddressForm.propTypes = {
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  //toPreviousStep: PropTypes.function,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  user: PropTypes.object,
  allAddress: PropTypes.array,
};

export default reduxForm({
  form: SCP_SHIPPING_ADDRESS_FORM_NAME,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(ScpShippingAddressForm);
