import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import CheckBoxField from '../../forms/controls/V2/CheckBoxField';

const renderCheckboxes = ({ fields, options, parentStr, onChange }) => (
  <Fragment>
    {options.map((o) => {
      const { label, value } = o;
      return (
        <div className="profile-page-checkbox" key={`${fields.name}-option-${value}`}>
          <Field
            name={`${parentStr}${value}`}
            type="checkbox"
            label={
              <Fragment>
                <span className="checkmark" />
                <div className="label-section">{label}</div>
              </Fragment>
            }
            component={CheckBoxField}
            onChange={onChange}
          />
        </div>
      );
    })}
  </Fragment>
);

const CheckboxGroup = ({ field, onChange = () => {}, options, parentField, validators }) => {
  const parentStr = parentField ? `${parentField}.` : '';

  return (
    <FieldArray
      name={`checkboxes_${field}`}
      component={renderCheckboxes}
      props={{
        options,
        parentStr,
        onChange,
      }}
      validate={validators}
    />
  );
};

CheckboxGroup.propTypes = {
  field: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  parentField: PropTypes.string,
  validators: PropTypes.array,
};

export default CheckboxGroup;
