export default {
  appointment: 'appointment',
  phoneAppointment: 'phoneAppointment',
  videoAppointment: 'videoAppointment',
  cancelAppointment: 'cancelAppointment',
  couponInput: 'couponInput',
  serviceInfo: 'serviceInfo',
  uploadDocuments: 'uploadDocuments',
  videoModal: 'videoModal',
  bookAppointmentErrorModal: 'bookAppointmentErrorModal',
  PioneerConfigrationModal:'PioneerConfigrationModal',
  AppointmentReschedulingFailedModal:'AppointmentReschedulingFailedModal'
};
