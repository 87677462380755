import React from 'react';
import { Field } from 'redux-form';
import InputField from 'components/forms/controls/V2/InputField';
import SelectField from 'components/forms/controls/V2/SelectField';

import { required, validPostalOrZipCode } from 'services/validate';
import { getCountriesOption, getCountryStates } from 'services/utils';

const validateCountry = required('Country');
const validateAddressLine1 = required('Address Line 1');
const validateCity = required('City');
const validateState = required('State');
const requiredZipCode = required('Zip Code');
const validPostalCode = validPostalOrZipCode('Zip Code', 'US');

const BillingAddress = ({ handleChange, countries, states, initialState, countryIsNonUS }) => {
  return (
    <div className="bill-add-section">
      <div className="section-header">Billing Address</div>
      <div className="form-row">
        {countryIsNonUS && (
          <div className="form-col full-col">
            <div className="label-section required">Country</div>
            <Field
              name="addressCountry"
              component={SelectField}
              placeholder="Select"
              options={getCountriesOption(countries, ['US'])} //TODO
              validate={[validateCountry]}
              onChange={(e, value) => handleChange(value, 'addressCountry')}
            />
          </div>
        )}
      </div>
      <div className="form-row">
        <div className="form-col full-col">
          <div className="label-section required">Address Line 1</div>
          <Field
            name="addressLine1"
            placeholder="123 Main Street"
            type="text"
            component={InputField}
            validate={[validateAddressLine1]}
            onChange={(e, value) => handleChange(value, 'addressLine1')}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-col full-col">
          <div className="label-section">Address Line 2</div>
          <Field
            name="addressLine2"
            placeholder="Apt 123"
            type="text"
            component={InputField}
            onChange={(e, value) => handleChange(value, 'addressLine2')}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-col city-col">
          <div className="label-section required">City</div>
          <Field
            name="addressCity"
            type="text"
            placeholder="Enter"
            component={InputField}
            validate={[validateCity]}
            onChange={(e, value) => handleChange(value, 'addressCity')}
          />
        </div>
        <div className="form-col state-col">
          <div className="label-section required">State</div>
          <Field
            name="addressState"
            component={SelectField}
            placeholder="Select"
            options={getCountryStates(states, initialState.addressCountry, 'code', 'code')}
            validate={[validateState]}
            onChange={(e, value) => handleChange(value, 'addressState')}
            typeahead={true}
            clearable={true}
          />
        </div>
        <div className="form-col zip-col">
          <div className="label-section required">Zip Code</div>
          <Field
            name="addressZipCode"
            type="text"
            placeholder="Enter"
            component={InputField}
            label="Zipcode"
            validate={[requiredZipCode, validPostalCode]}
            onChange={(e, value) => handleChange(value, 'addressZipCode')}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingAddress;
