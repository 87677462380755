import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, setSubmitFailed, stopSubmit } from 'redux-form';
import SelectField from 'components/forms/controls/V2/SelectField';
import InputField from 'components/forms/controls/V2/InputField';
import RadioButtonGroup from 'components/forms/controls/V2/RadioButtonGroup';

import { required, dateIsPast } from 'services/validate';
import './LabInsuranceForm.scss';
import classnames from 'classnames';
import InputMaskField from '../../controls/V2/InputMaskField';
import CheckBoxField from '../../controls/V2/CheckBoxField';

export const LAB_INSURANCE_FORM_NAME = 'lab-insurance-form';
const validateRelationToPatient = required('Relationship to policyholder');
const validateFirstName = required('First name');
const validateLastName = required('Last name');
const validateInsurance = required('Insurance');
const validateInsuranceID = required('Insurance ID');
const validateGroupNumber = required('Group number');
const validatePolicyOrDepedent = required('Policyholder or dependent');
const validateBiological = required('Biological sex');
const validateDateIsPast = dateIsPast('Policyholder’s Date of Birth is invalid');

const biologicalSexVariants = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];

const RelationVariants = [
  { label: 'Self', value: true },
  { label: 'Dependent', value: false },
];

const DependantVariants = [
  { label: 'Spouse or Partner', value: 'spouse' },
  { label: 'Child', value: 'child' },
  { label: 'Parent', value: 'parent' },
  { label: 'Other', value: 'other' },
];

const LabInsuranceForm = ({
  initialValues,
  errors,
  dispatch,
  onSubmit,
  submitting,
  toPreviousStep,
  handleSubmit,
  valid,
}) => {
  const [initialStates, setInitialStates] = useState({});

  useEffect(() => {
    setInitialStates(initialValues);
    if (errors && errors.insurance) {
      dispatch(stopSubmit(LAB_INSURANCE_FORM_NAME, errors.insurance));
      dispatch(setSubmitFailed(LAB_INSURANCE_FORM_NAME, Object.keys(errors.insurance)));
    }
  }, []);

  const handleChange = (value, key) => {
    let refinitialValues = _.cloneDeep(initialStates);
    refinitialValues[key] = value;
    setInitialStates(refinitialValues);
  };

  const showDependentField = () => {
    if (!_.isEmpty(initialStates) && !_.isNil(initialStates.isPolicyholder)) {
      return !initialStates.isPolicyholder;
    }
  };

  const handleFormSubmit = (data) => {
    const req = {
      ...data,
      insuranceType: 'primary',
      visitInsurance: false,
      labInsurance: true,
    };
    onSubmit(req);
  };

  const renderDependentFields = () => {
    const _insuranceIsSelfPay = insuranceIsSelfPay();
    const labelRequiredClassName = !_insuranceIsSelfPay
      ? 'label-section required'
      : 'label-section';
    return (
      <Fragment>
        <div className="form-row">
          <div className="form-col">
            <div className={labelRequiredClassName}>Your Relationship To Policyholder</div>
            <Field
              name="policyholderRelationship"
              component={SelectField}
              className="narrow-field"
              placeholder="Select"
              options={(DependantVariants || []).map((obj) => {
                return {
                  label: obj.label,
                  value: obj.value,
                  key: obj.value,
                };
              })}
              validate={!_insuranceIsSelfPay ? [validateRelationToPatient] : []}
              onChange={(e, value) => handleChange(value, 'policyholderRelationship')}
              disabled={_insuranceIsSelfPay}
            />
          </div>
          <div className="form-col">
            <div className="label-section">Policyholder’s Date of Birth</div>
            <Field
              name="policyholderDob"
              type="text"
              placeholder="MM/DD/YYYY"
              component={InputMaskField}
              onChange={(e, value) => handleChange(value, 'policyholderDob')}
              mask="99/99/9999"
              maskChar={null}
              validate={[validateDateIsPast]}
              disabled={_insuranceIsSelfPay}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-col">
            <div className={labelRequiredClassName}>Policyholder’s First Name</div>
            <Field
              name="policyholderFirstName"
              type="text"
              placeholder="John"
              component={InputField}
              validate={!_insuranceIsSelfPay ? [validateFirstName] : []}
              onChange={(e, value) => handleChange(value, 'policyholderFirstName')}
              disabled={_insuranceIsSelfPay}
            />
          </div>
          <div className="form-col">
            <div className={labelRequiredClassName}>Policyholder’s Last Name</div>
            <Field
              name="policyholderLastName"
              type="text"
              placeholder="Doe"
              component={InputField}
              validate={!_insuranceIsSelfPay ? [validateLastName] : []}
              onChange={(e, value) => handleChange(value, 'policyholderLastName')}
              disabled={_insuranceIsSelfPay}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const insuranceIsSelfPay = () => {
    const { isSelfPay } = initialStates;
    let _isSelfPay = initialValues?.isSelfPay;
    if (typeof isSelfPay == 'boolean') {
      _isSelfPay = isSelfPay;
    }
    return !!_isSelfPay;
  };

  const _insuranceIsSelfPay = insuranceIsSelfPay();
  const labelRequiredClassName = !_insuranceIsSelfPay ? 'label-section required' : 'label-section';
  const disbaledClass = _insuranceIsSelfPay ? 'disabled' : '';

  return (
    <div className="scp-schedule-wizard">
      <h2 className="gm-select-service__header">Enter Insurance Information</h2>
      <form className="insurance-form" onSubmit={handleSubmit(handleFormSubmit)}>
        <>
          <div className="scp-insurance-fields">
            <div className="insurance-group">
              <div className="scp-insurance-sections">
                <div className="scp-insurance-section insurance-name-section">
                  <div className={labelRequiredClassName}>
                    <span>Insurance Name</span>
                  </div>
                  <div className="form-row insurance-form-form">
                    <Field
                      name="companyName"
                      component={InputField}
                      label="Insurance Name"
                      placeholder="Ex Cigna"
                      validate={!_insuranceIsSelfPay ? [validateInsurance] : []}
                      onChange={(e, value) => {
                        handleChange(value, 'companyName');
                      }}
                      disabled={_insuranceIsSelfPay}
                    />
                  </div>
                  <Field
                    name="isSelfPay"
                    type="checkbox"
                    extraclassName="self-pay-checkbox"
                    label={
                      <Fragment>
                        <span className="checkmark" />
                        <div className="label-section">Don't bill insurance</div>
                      </Fragment>
                    }
                    component={CheckBoxField}
                    onChange={(e, value) => {
                      handleChange(!!value, 'isSelfPay');
                    }}
                  />
                  <div className={labelRequiredClassName}>
                    <span>Insurance ID #</span>
                  </div>
                  <div className="form-row">
                    <Field
                      name="insuranceNo"
                      type="text"
                      placeholder="1234-567890"
                      component={InputField}
                      validate={!_insuranceIsSelfPay ? [validateInsuranceID] : []}
                      onChange={(e, value) => handleChange(value, 'insuranceNo')}
                      disabled={_insuranceIsSelfPay}
                    />
                  </div>
                  <div className={labelRequiredClassName}>
                    <span>Group #</span>
                  </div>
                  <div className="form-row">
                    <Field
                      name="groupNo"
                      type="text"
                      placeholder="1234-567890"
                      component={InputField}
                      validate={!_insuranceIsSelfPay ? [validateGroupNumber] : []}
                      onChange={(e, value) => handleChange(value, 'groupNo')}
                      disabled={_insuranceIsSelfPay}
                    />
                  </div>

                  <div className={`${labelRequiredClassName} ${disbaledClass}`}>
                    <span>Are you the primary policyholder or a dependent?</span>
                  </div>
                  <div className={`form-row ${disbaledClass}`}>
                    <Field
                      name="isPolicyholder"
                      className={classnames('insurance-form-radiogroup fz15px')}
                      component={RadioButtonGroup}
                      children={RelationVariants}
                      validate={!_insuranceIsSelfPay ? [validatePolicyOrDepedent] : []}
                      onChange={(e, value) => handleChange(value, 'isPolicyholder')}
                      disabled={_insuranceIsSelfPay}
                    />
                  </div>

                  <div className={labelRequiredClassName}>
                    <span>Policyholder’s Biological Sex</span>
                  </div>
                  <div className="form-row">
                    <Field
                      name="policyholderBiolocialSex"
                      component={SelectField}
                      className={classnames('narrow-field')}
                      placeholder="Select"
                      options={(biologicalSexVariants || []).map((obj) => {
                        return {
                          label: obj.label,
                          value: obj.value,
                          key: obj.value,
                        };
                      })}
                      validate={!_insuranceIsSelfPay ? [validateBiological] : []}
                      onChange={(e, value) => handleChange(value, 'policyholderBiolocialSex')}
                      disabled={_insuranceIsSelfPay}
                    />
                  </div>
                  {showDependentField() && renderDependentFields()}
                </div>
              </div>
            </div>
            <div className="lab-insurance-description">
              Natera will use this information to bill your insurance for Horizon™ carrier screening
              if approved by the Genome Medical physician reviewing your request.
              <br />
              <br /> Select "Don't bill insurance" if you wish to self pay for the test ($249). The
              self pay price does not include the upfront $15 fee for a Genome Medical physician to
              review your request for testing and will be collected separately regardless of whether
              you choose to bill your insurance or self pay.
            </div>
          </div>
        </>

        <div className="action-buttons">
          {toPreviousStep && (
            <button onClick={toPreviousStep} className="btn-back">
              Back
            </button>
          )}
          <button disabled={submitting || !valid} type="submit" className="btn-next">
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

LabInsuranceForm.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default reduxForm({
  form: LAB_INSURANCE_FORM_NAME,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(LabInsuranceForm);
