// @flow
import api, { X_API_AUTHORIZATION_HEADER } from './api';
import type {
  GetMyProfileResponse,
  UpdateMyProfileRequest,
  GetMyPaymentMethodResponse,
  UpdateMyPaymentMethodRequest,
  UpdateMyAddress,
  UpdateMyPartner,
} from './usersApiTypes';

export default class UsersAPI {
  getMyProfile(): Promise<GetMyProfileResponse> {
    return api.get('/me');
  }

  updateMyProfile(params: UpdateMyProfileRequest, accessToken: ?string): Promise<any> {
    return api.patch('/me', params, {
      headers: {
        [X_API_AUTHORIZATION_HEADER]: accessToken,
      },
    });
  }

  getMyPaymentMethod(): Promise<GetMyPaymentMethodResponse> {
    return api.get('/me/payment-method');
  }

  updateMyPaymentMethod(params: UpdateMyPaymentMethodRequest, accessToken: ?string): Promise<any> {
    return api.patch('/me/payment-method', params, {
      headers: {
        [X_API_AUTHORIZATION_HEADER]: accessToken,
      },
    });
  }

  getMyInsurance() {}

  updateMyInsurance() {}

  createMyAddress(params: UpdateMyAddress, accessToken: ?string): Promise<any> {
    return api.post('me/addresses', params, {
      headers: {
        [X_API_AUTHORIZATION_HEADER]: accessToken,
      },
    });
  }

  updateMyAddress(params: UpdateMyAddress, accessToken: ?string): Promise<any> {
    return api.patch('me/addresses', params, {
      headers: {
        [X_API_AUTHORIZATION_HEADER]: accessToken,
      },
    });
  }

  getMyAddress(): Promise {
    return api.get('/me/addresses');
  }

  updateMyPartner(params: UpdateMyPartner, accessToken: ?string): Promise<any> {
    return api.patch('/me/partner', params, {
      headers: {
        [X_API_AUTHORIZATION_HEADER]: accessToken,
      },
    });
  }

  getHomeInfo(): Promise {
    return api.get('me/home-info');
  }

  getMeOutreach(): Promise<Array> {
    return api.get('/me/appointment/outreach');
  }

  createTROEncounter(params): Promise<any> {
    return api.post('/tro/encounters', params);
  }

  grailQuesionnaire(params): Promise<any> {
    return api.post('/me/grail/questionnaire', params);
  }

  updateConsentStatus(params): Promise<any> {
    return api.patch('/me/consent/status', params);
  }

  setupStripeIntent(){
    return api.post('/me/stripe-setup-intent')
  }

  setupStripeIntentFromEmail(params){
    return api.post('/user/stripe-setup-intent',params);
  }
  
  matchedPatient(email) {
    return api.get(`/matched-patient?email=${encodeURIComponent(email)}`);
  }
  
  createGeneTestOrder = (params) =>{
    return api.post('/user/gene-test-order',params);
  }
}
