import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import BsButton from 'react-bootstrap/lib/Button';
import { Link } from 'react-router-dom';
import './ButtonV2.scss';

class ButtonV2 extends Component {
  render() {
    const { disabled, onClick, type, href, className, target, size, children, path } = this.props;

    const extra = { disabled };
    const classes = [];
    if (onClick) {
      extra.onClick = onClick;
      classes.push(className);
    } else if (href) {
      classes.push('button-link');
      extra.href = href;
      extra.target = target;
    }
    if (type === 'submit' || type === 'reset') extra.type = type;
    const button = (
      <BsButton
        {...extra}
        className={classnames(classes)}
        data-pp-btn={true}
        data-pp-btn-type={type}
        data-pp-btn-size={size}
      >
        {children}
      </BsButton>
    );
    if (onClick) return button;
    return (
      <div className={classnames('outer-button', className)}>
        {path ? (
          <Link className="button-link" to={path}>
            {button}
          </Link>
        ) : (
          button
        )}
      </div>
    );
  }
}

ButtonV2.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string, // accept, more, signin
  size: PropTypes.string, // large, small
  onClick: PropTypes.func,
  path: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  target: PropTypes.string,
};

export default ButtonV2;
