import React from 'react';
import { withRouter } from 'react-router';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import { addAction, DOCUMENT_VIEW } from 'ducks/misc/redirectActions';
import './PatientCards.scss';
import insurnaceIcon from './insurance.png';

function UploadPhoto(props) {
  const { history } = props;
  const goal = (props && props.goal) || 'appointment';
  const desc =
    goal == 'test'
      ? 'In order to process your test order, we would need a photo of your insurance card.'
      : 'In order to process your appointment, we would need a photo of your insurance card.';
  return (
    <div className="upload-photo-card">
      <div className="content">
        <div className="static">
          <div className="left">
            <div className="title">Upload a photo of your insurance card</div>
            <div className="text" data-hj-suppress>
              {desc}
            </div>
          </div>
          <div className="right">
            <img className="visual-content" src={insurnaceIcon} alt="upload your card" />
          </div>
        </div>
        <div className="action-items left">
          <ButtonV2
            className="outline btn-click-outline"
            onClick={() => {
              props.dispatch(addAction(DOCUMENT_VIEW, 1));
              history.push('/patient/results');
            }}
          >
            Upload Insurance Card
          </ButtonV2>
        </div>
      </div>
    </div>
  );
}

export default withRouter(UploadPhoto);
