import React, { Component } from 'react';
import { connect } from 'react-redux';

import GrailGalleri from './GrailGalleri.js';
import GrailStatusEnums from 'constants/GrailStatus';
import GrailQualificationContainer from 'containers/pages/public/GrailQualificationContainer/GrailQualificationContainer';

class GrailRequestTestContainer extends Component {
  render() {
    const {
      history,
      user: {
        me: { grailStatus },
      },
    } = this.props;
    return (
      <div className="grail-galleri-container">
        {grailStatus == GrailStatusEnums.QUALIFICATION_REQUIRED && <GrailQualificationContainer />}
        {grailStatus == GrailStatusEnums.SHOW_CARD && <GrailGalleri history={history} />}
      </div>
    );
  }
}

export default connect(({ user }) => ({
  user,
}))(GrailRequestTestContainer);
