import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import './PrivacyPolicyForm.scss';
import Privacy from 'components/Policy/Privacy';

const PrivacyPolicyForm = ({ handleSubmit, onSubmit, goBack }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="privacy-policy-form-container">
      <section className="privacy-wrapper">
        <section className="privacy-header">
          <div className="genome-header-logo">
            <img className="genome-header-img" src={require('images/logo.svg')} />
          </div>
        </section>
        <section className="privacy-body">
          <Privacy />
          <div className="agree-container">
            <p>
              By clicking the <span className="agree-continue">"I Agree and Continue"</span> button
              below, you confirm you have reviewed and agree to the above{' '}
              <span className="font-medium">Notice of Privacy Practices</span>
            </p>
            <div className="buttons-containers">
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="action-buttons">
                  <button onClick={goBack} className="btn-back">
                    Back
                  </button>
                  <button type="submit" className="btn-next">
                    I Agree & Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </section>
    </section>
  );
};

PrivacyPolicyForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'privacy-policy-form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PrivacyPolicyForm);
