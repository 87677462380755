import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { isReferralIframe } from 'services/utils';

import Button from 'components/forms/controls/Button';
import CommonField from 'components/forms/controls/CommonField';
import ReferralsScheme, {
  sectionProviderInfo,
  sectionReferralInfo,
} from 'constants/ReferralsScheme';
import { required, optionalEmail, phone, referText } from 'services/validate';
import { PHONE_MASK_BRACKETS } from 'constants/CommonConstants';

const validatePhysicianName = required(
  ReferralsScheme[sectionProviderInfo].questions.physicianName
);
const validateOfficeContactName = required(
  ReferralsScheme[sectionProviderInfo].questions.officeContactName
);
const validateOfficeContactEmail = required(
  ReferralsScheme[sectionProviderInfo].questions.officeContactEmail
);
const validatePhoneRequired = required('Phone');

class ReferProviderInformation extends Component {
  static fieldsOrder = [
    'physicianName',
    'officeContactName',
    'officeContactEmail',
    'providerPhone',
    'npiNumber',
    'alternativeEmail',
  ];

  onSubmit = (data) =>
    this.props.onSubmit({
      data: {
        ...data,
      },
      scheme: ReferralsScheme[sectionProviderInfo],
      nextSection: sectionReferralInfo,
      order: ReferProviderInformation.fieldsOrder,
    });

  render() {
    const { submitting, className } = this.props;
    const { questions } = ReferralsScheme[sectionProviderInfo];

    return (
      <div className={className}>
        <h3 className="section-name">Referring Provider information</h3>

        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field
            name="physicianName"
            type="text"
            component={CommonField}
            label={questions.physicianName + '*'}
            validate={[validatePhysicianName, referText]}
          />

          <Field
            name="officeContactName"
            type="text"
            component={CommonField}
            label={questions.officeContactName + '*'}
            validate={isReferralIframe ? [validateOfficeContactName, referText] : [referText]}
          />

          <Field
            name="officeContactEmail"
            type="text"
            component={CommonField}
            label={questions.officeContactEmail + '*'}
            validate={[validateOfficeContactEmail, optionalEmail, referText]}
          />

          <Field
            name="providerPhone"
            type="phone"
            component={CommonField}
            mask={PHONE_MASK_BRACKETS}
            placeholder="+1-(XXX) XXX-XXXX"
            label={questions.providerPhone + '*'}
            validate={[validatePhoneRequired, phone, referText]}
          />

          <Field
            name="npiNumber"
            type="text"
            component={CommonField}
            label={questions.npiNumber}
            validate={[referText]}
          />

          <p className="mb20 mt20">
            Consultation summaries, test results and communication from our providers will be sent
            to the email address provided above.
          </p>

          <p className="mb20">
            If you prefer an alternate email address or fax number, please indicate here:{' '}
          </p>

          <Field
            name="alternativeEmail"
            type="text"
            component={CommonField}
            label={questions.alternativeEmail}
            validate={[optionalEmail, referText]}
          />

          <div className="buttons-block start-block">
            <Button className="button-next" type="submit" disabled={submitting}>
              Next
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

ReferProviderInformation.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default reduxForm({ form: 'refer-provider-information', forceUnregisterOnUnmount: true })(
  ReferProviderInformation
);
