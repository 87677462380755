import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames';
import {
  required,
  email,
  patientName,
  matchPassword,
  phoneOrEmpty,
  dateOfBirth,
  strongPassword,
  shouldBeBoolean,
} from 'services/validate';
import Alert from 'components/widgets/Alert/Alert';
import warn from 'components/forms/PasswordStrength/warn';
import Button from '../../controls/ButtonV2';
import InputField from '../../controls/V2/InputField';
import SelectField from '../../controls/V2/SelectField';
import CheckBoxField from '../../controls/V2/CheckBoxField';
import PhoneNumberField from '../../controls/V2/PhoneNumberField';
import InputMaskField from '../../controls/V2/InputMaskField';
import 'element-theme-default';
import track, { TR_VISIT_REGISTRATION_PAGE } from 'services/track';
import gaTrack, { GA_TR_VISIT_REGISTRATION_PAGE } from '../../../../services/gaTrack';
import PasswordRequirementsTooltip from '../../PasswordStrength/PasswordRequirementsTooltip';
import { Tooltip } from 'gm-element-react';
import './SignupFormV2.scss';
import RadioButtonGroup from '../../controls/RadioButtonGroup';
import schedulingService from '../../../../services/api/schedulingService';
import { withRouter } from 'react-router';

const relationshipVarients = [
  { label: 'Child', value: 'Child' },
  { label: 'Parent', value: 'Parent' },
  { label: 'Spouse/Domestic Partner', value: 'Spouse/Domestic Partner' },
  { label: 'Sibling', value: 'Sibling' },
  { label: 'Legal Guardian', value: 'Legal Guardian' },
  { label: 'Other', value: 'Other' },
];

const sexAssignedAtBirthVariants = [
  { label: 'Female', value: 'female' },
  { label: 'Male', value: 'male' },
  { label: 'Intersex', value: 'intersex' },
];

const validateFirstNameRequired = required('First Name');
const validateLastNameRequired = required('Last Name');
const validateEmailRequired = required('Email');
const validatePhoneRequired = required('Phone Number');
const validateDOBRequired = required('Date of Birth');
const validateSexAssignAtBirthRequired = required('Sex Assigned at birth');
const validateDOB = dateOfBirth();
const validatePasswordRequired = required('Password');
const validateConfirmPasswordRequired = required('Confirm Password');
const validateMedicare = shouldBeBoolean('Medicare Beneficiary');

const validateYourFirstNameRequired = required('Your First Name');
const validateYourLastNameRequired = required('Your Last Name');
const validateYourPhoneRequired = required('Your Phone Number');
const validateRelationRequired = required('Relationship to patient');

const SignUpForm = ({
  change,
  untouch,
  isPatientValue,
  handleSubmit,
  submitting,
  formError,
  valid,
  showMedicareQuestion,
  onSubmit,
}) => {
  const [isNotPatient, setIsNotPatient] = useState(false);
  const [guardianRelationship, setGuardianRelationship] = useState();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    track(TR_VISIT_REGISTRATION_PAGE);
    gaTrack(GA_TR_VISIT_REGISTRATION_PAGE);
    window.scrollTo(0, 0);
    setIsNotPatient(_.isUndefined(isPatientValue) ? isNotPatient : isPatientValue);
    setIsMobile(schedulingService.isMobile());
    window.addEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    let isShowMedicareQuestion = true;
    if (showMedicareQuestion) {
      isShowMedicareQuestion = Boolean(showMedicareQuestion?.toLowerCase() === 'true');
      if (!isShowMedicareQuestion) {
        change('isMedicareBeneficiary', false);
      }
    }
  }, [showMedicareQuestion]);

  const handleIsNotPatient = (value) => {
    setIsNotPatient(value);
    if (value) {
      resetPatientPhoneField();
    }
    resetGuardianFields();
  };

  const handleGuardianRelationship = (value) => {
    setGuardianRelationship(value);
  };

  const resetGuardianFields = () => {
    change('guardianFirstName', '');
    change('guardianLastName', '');
    change('guardianPrimaryPhone', '');
    change('guardianRelationship', '');
    change('guardianDob', '');
    untouch('guardianPrimaryPhone', '');
    untouch('guardianFirstName');
    untouch('guardianLastName');
    untouch('guardianRelationship');
    untouch('guardianDob');
  };

  const resetPatientFields = () => {
    change('firstName', '');
    change('lastName', '');
    change('dob', '');
    change('primaryPhone', '');
    change('sexAssignedAtBirth', '');
    untouch('firstName', '');
    untouch('lastName', '');
    untouch('dob', '');
    untouch('primaryPhone', '');
    untouch('sexAssignedAtBirth', '');
  };

  const resetPatientPhoneField = () => {
    change('primaryPhone', '');
    untouch('primaryPhone', '');
  };

  const onResize = () => {
    setViewMode();
  };

  const setViewMode = () => {
    if (isMobile && !schedulingService.isMobile()) {
      setIsMobile(false);
    } else if (!isMobile && schedulingService.isMobile()) {
      setIsMobile(true);
    }
  };

  const consentToTextMessage = () => {
    return 'By checking this box, I agree to receive automated text notifications from Genome Medical. These notifications may be about appointments, test results, and more.';
  };

  const patientLabelPrefix = isNotPatient ? "Patient's" : '';
  let isShowMedicareQuestion = true;
  if (showMedicareQuestion) {
    isShowMedicareQuestion = Boolean(showMedicareQuestion?.toLowerCase() === 'true');
  }
  const relationPatientIsSelectedAndNotPatient =
    guardianRelationship && guardianRelationship !== 'Parent';

  return (
    <section className="signup-container">
      <section className="signup-wrapper">
        <section className="signup-header">
          <div className="genome-header-logo">
            <img className="genome-header-img" src={require('images/logo.svg')} />
          </div>
          <div className="genome-header-title">
            <h2 className="signup-title">Get Started</h2>
            <h3 className="signup-desc">
              Tell us about yourself so you can access the services that are right for you.
            </h3>
            <div className="login-here">
              Already have an account?{' '}
              <Link className="privacy-link" to="/login">
                Sign In
              </Link>
            </div>
          </div>
        </section>
        <section className="signup-body">
          <form
            className="signup-form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div className="form-section">
              <div className="info-section">
                <h3 className="header">Login Details</h3>
              </div>
              <div className="body-section">
                <div className="row-section">
                  <div className="col-section">
                    <div className="label-section required">Email Address</div>
                    <div className="input-section">
                      <Field
                        name="email"
                        type="email"
                        placeholder="Email Address"
                        trim={true}
                        component={InputField}
                        validate={[validateEmailRequired, email]}
                      />
                    </div>
                  </div>
                </div>
                <div className="row-section">
                  <div className="col-section">
                    <div style={{ display: 'flex' }}>
                      <div className="label-section required">Password</div>
                      <Tooltip
                        className="set-pwd-tooltip"
                        effect="dark"
                        content={<PasswordRequirementsTooltip />}
                        placement="top"
                      >
                        <img className="arrow-left" src={require('images/more-info.svg')} />
                      </Tooltip>
                    </div>
                    <div className="input-section">
                      <Field
                        name="password"
                        type="password"
                        placeholder="Password"
                        trim={true}
                        component={InputField}
                        validate={[validatePasswordRequired, strongPassword]}
                      />
                    </div>
                  </div>
                  <div className="col-section">
                    <div className="label-section required">Confirm Password</div>
                    <div className="input-section">
                      <Field
                        name="passwordRepeat"
                        type="password"
                        placeholder="Confirm Password"
                        trim={true}
                        component={InputField}
                        validate={[validateConfirmPasswordRequired]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-section">
              <div className="info-section">
                <h3 className="header">Patient Details</h3>
              </div>
              <div className="body-section">
                <Fragment>
                  <div className="row-section">
                    <div className="col-section">
                      <div className="label-section required">{`${patientLabelPrefix} First Name`}</div>
                      <div className="input-section">
                        <Field
                          name="firstName"
                          type="text"
                          placeholder={`${patientLabelPrefix} First Name`}
                          trim={true}
                          component={InputField}
                          validate={[validateFirstNameRequired, patientName]}
                        />
                        <div className="child-input-section">
                          <Field
                            name="isNotPatient"
                            type="checkbox"
                            label={
                              <Fragment>
                                <span className="checkmark" />
                                <div className="label-section">
                                  <span>
                                    Check if you <b>are not</b> the patient{' '}
                                  </span>
                                </div>
                              </Fragment>
                            }
                            component={CheckBoxField}
                            onChange={(e, value) => {
                              handleIsNotPatient(value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-section">
                      <div className="label-section required">{`${patientLabelPrefix} Last Name`}</div>
                      <div className="input-section">
                        <Field
                          name="lastName"
                          type="text"
                          placeholder={`${patientLabelPrefix} Last Name`}
                          trim={true}
                          component={InputField}
                          validate={[validateLastNameRequired, patientName]}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row-section patient-detail-section">
                    {!isNotPatient && (
                      <div className="col-section">
                        <div className="label-section required">Phone Number</div>
                        <div className="input-section">
                          <Field
                            name="primaryPhone"
                            type="text"
                            placeholder="Phone Number"
                            component={PhoneNumberField}
                            mask="+1 999 999-9999"
                            maskChar={null}
                            validate={[phoneOrEmpty, validatePhoneRequired]}
                          />
                          {isMobile && (
                            <div className="child-input-section">
                              <Field
                                name={'primaryPhoneConsentToText'}
                                type="checkbox"
                                label={
                                  <Fragment>
                                    <span className="checkmark" />
                                    <div className="label-section">
                                      <span> {consentToTextMessage()}</span>
                                    </div>
                                  </Fragment>
                                }
                                component={CheckBoxField}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="col-section">
                      <div className="label-section required">{`${patientLabelPrefix} Date of Birth`}</div>
                      <div className="input-section">
                        <Field
                          name="dob"
                          placeholder="MM/DD/YYYY"
                          component={InputMaskField}
                          mask="99/99/9999"
                          maskChar={null}
                          validate={[validateDOBRequired, validateDOB]}
                        />
                      </div>
                    </div>
                    {!isMobile && !isNotPatient && (
                      <div className="col-section full-col consent-text">
                        <div className="label-section">Consent to Text</div>
                        <div className="input-section consent-to-text">
                          <Field
                            name={'guardianPrimaryPhoneConsentToText'}
                            type="checkbox"
                            label={
                              <Fragment>
                                <span className="checkmark" />
                                <div className="label-section">{consentToTextMessage()}</div>
                              </Fragment>
                            }
                            component={CheckBoxField}
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-section">
                      <div className="label-section required">{`${patientLabelPrefix} Sex Assigned at Birth`}</div>
                      <div className="input-section">
                        <Field
                          name="sexAssignedAtBirth"
                          type="select"
                          placeholder="Select"
                          component={SelectField}
                          options={sexAssignedAtBirthVariants}
                          validate={[validateSexAssignAtBirthRequired]}
                        />
                      </div>
                    </div>

                    {isShowMedicareQuestion === true ? (
                      <div className="col-section">
                        <div className="label-section required">
                          {!isNotPatient
                            ? 'Are you covered by Medicare?'
                            : 'Is the patient covered by Medicare?'}
                        </div>
                        <div className="input-section">
                          <Field
                            name="isMedicareBeneficiary"
                            component={RadioButtonGroup}
                            children={[
                              { label: 'Yes', value: true },
                              { label: 'No', value: false },
                            ]}
                            validate={validateMedicare}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </Fragment>
              </div>
            </div>
            {isNotPatient && (
              <div className="form-section">
                <div className="info-section">
                  <h3 className="header">Your Details</h3>
                  <div className="body">
                    You indicated that you are not the patient. In order to schedule an appointment
                    for someone else you must be their parent/legal guardian, or if they are an
                    incapacitated adult you must hold a medical power of attorney and be legally
                    authorized to make medical decisions on their behalf.
                  </div>
                </div>
                <div className="body-section">
                  <div className="row-section">
                    <div className="col-section">
                      <div className="label-section required">Your First Name</div>
                      <div className="input-section">
                        <Field
                          name="guardianFirstName"
                          type="text"
                          placeholder="Your First Name"
                          trim={true}
                          component={InputField}
                          validate={[validateYourFirstNameRequired]}
                        />
                      </div>
                    </div>
                    <div className="col-section">
                      <div className="label-section required">Your Last Name</div>
                      <div className="input-section">
                        <Field
                          name="guardianLastName"
                          type="text"
                          placeholder="Your Last Name"
                          trim={true}
                          component={InputField}
                          validate={[validateYourLastNameRequired]}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={classnames('row-section', {
                      'no-margin': relationPatientIsSelectedAndNotPatient,
                    })}
                  >
                    <div className="col-section">
                      <div className="label-section required">Your Phone Number</div>
                      <div className="input-section">
                        <Field
                          name="guardianPrimaryPhone"
                          type="text"
                          placeholder="Phone Number"
                          component={PhoneNumberField}
                          mask="+1 999 999-9999"
                          maskChar={null}
                          validate={[phoneOrEmpty, validateYourPhoneRequired]}
                        />
                        {isMobile && (
                          <div className="child-input-section">
                            <Field
                              name={'guardianPrimaryPhoneConsentToText'}
                              type="checkbox"
                              label={
                                <Fragment>
                                  <span className="checkmark" />
                                  <div className="label-section">
                                    <span> {consentToTextMessage()}</span>
                                  </div>
                                </Fragment>
                              }
                              component={CheckBoxField}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={classnames('col-section', {
                        'no-margin': relationPatientIsSelectedAndNotPatient,
                      })}
                    >
                      <div className="label-section required">Relationship to Patient</div>
                      <div className="input-section">
                        <Field
                          name="guardianRelationship"
                          type="select"
                          placeholder="Select"
                          component={SelectField}
                          options={relationshipVarients}
                          validate={[validateRelationRequired]}
                          onChange={(e, value) => handleGuardianRelationship(value)}
                        />
                      </div>
                    </div>
                  </div>
                  {relationPatientIsSelectedAndNotPatient && (
                    <div className="row-section">
                      <div className="col-section rltnshp-text full-col">
                        <div>
                          You must provide documentation before the patient’s visit to validate this
                          relationship. Please upload the documentation in the patient portal after
                          scheduling.
                        </div>
                      </div>
                    </div>
                  )}

                  {!isMobile && (
                    <div className="row-section">
                      <div className="col-section full-col consent-text">
                        <div className="label-section">Consent to Text</div>
                        <div className="input-section consent-to-text">
                          <Field
                            name={'guardianPrimaryPhoneConsentToText'}
                            type="checkbox"
                            label={
                              <Fragment>
                                <span className="checkmark" />
                                <div className="label-section">{consentToTextMessage()}</div>
                              </Fragment>
                            }
                            component={CheckBoxField}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="signup-footer">
              <Alert message={formError} />
              <div className="sign-button-wrapper">
                <div className="login-here">
                  <Link className="privacy-link" to="/login">
                    Sign In
                  </Link>{' '}
                  if you already have an account
                </div>
                <div className="btn-section">
                  <Button className="forgot-button" type="submit" disabled={submitting || !valid}>
                    <span>Continue</span>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </section>
      </section>
    </section>
  );
};

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
  showMedicareQuestion: PropTypes.string,
};

const reduxSignUpForm = reduxForm({
  form: 'sign-up-form',
  validate: (values) => {
    const errors = {
      ...matchPassword(values),
    };

    return errors;
  },
  warn,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(SignUpForm);

// Decorate with connect to read form values
const selector = formValueSelector('sign-up-form'); // <-- same as form name
export default connect((state) => {
  const isPatientValue = selector(state, 'isNotPatient');
  return {
    isPatientValue,
  };
})(withRouter(reduxSignUpForm));
