import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import InputField from 'components/forms/controls/V2/InputField';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import warn from '../PasswordStrength/warn';
import { required, matchPassword, email, strongPassword } from 'services/validate';
import PasswordRequirementsTooltip from '../PasswordStrength/PasswordRequirementsTooltip';
import Alert from 'components/widgets/Alert/Alert';
import './SetPasswordForm.scss';

import { Tooltip } from 'gm-element-react';

const validateEmailRequired = required('Email');
const validatePasswordRequired = required('Password');
const validateConfirmPasswordRequired = required('Confirm Password');

const SetPasswordForm = ({
  emailValue,
  handleSubmit,
  submitting,
  valid,
  showTos,
  onSubmit,
  formError,
}) => {
  return (
    <div className="set-password-page-container">
      <div className="form-wrapper">
        <i className="genome-logo" />
        <span className="set-password-title">Set New Password</span>
        <div className="set-password-desc">
          Access your new Genome Medical account by setting a new password below
        </div>
        <form className="set-password-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" value="something" />

          {emailValue && (
            <Fragment>
              <div className="label-field required">Email Address</div>
              <div className="input-section">
                <Field
                  name="email"
                  type="email"
                  placeholder="example@example.com"
                  trim={true}
                  component={InputField}
                  validate={[validateEmailRequired, email]}
                  disabled={true}
                />
              </div>
            </Fragment>
          )}
          <div style={{ display: 'flex' }}>
            <div className="label-field required">New Password </div>
            <Tooltip
              className="set-pwd-tooltip"
              effect="dark"
              content={<PasswordRequirementsTooltip />}
              placement="top"
            >
              <img className="arrow-left" src={require('images/more-info.svg')} />
            </Tooltip>
          </div>
          <div className="input-section">
            <Field
              name="password"
              type="password"
              placeholder="New Password"
              trim={true}
              component={InputField}
              validate={[validatePasswordRequired, strongPassword]}
            />
          </div>

          <div className="label-field required">Confirm New Password</div>
          <div className="input-section">
            <Field
              name="passwordRepeat"
              type="password"
              placeholder="Confirm New Password"
              trim={true}
              component={InputField}
              validate={validateConfirmPasswordRequired}
            />
          </div>

          <Alert message={formError} />
          <ButtonV2 className="submit-button" type="submit" disabled={submitting || !valid}>
            {showTos ? 'Continue' : 'Set Password'}
          </ButtonV2>
        </form>
      </div>
    </div>
  );
};

SetPasswordForm.propTypes = {
  emailValue: PropTypes.string,
  buttonTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
  showTos: PropTypes.bool,
  valid: PropTypes.bool,
};

export default reduxForm({
  form: 'SetPasswordForm',
  validate: matchPassword,
  warn,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(SetPasswordForm);
