import PropTypes from 'prop-types';
import React, { Component } from 'react';
import gaTrack, { GA_TR_SCHEDULING_BILLING_PAGE } from '../../../../../services/gaTrack';
import PaymentFormV2 from './PaymentFormV2';
import './PaymentInformationFormV2.scss';

export const PAYMENT_FORM_NAME = 'scp-payment-information-form';

class PaymentInformationForm extends Component {
  componentDidMount() {
    gaTrack(GA_TR_SCHEDULING_BILLING_PAGE);
    window.scrollTo(0, 0);
  }

  render() {
    const { loading } = this.props;

    if (loading) return null;

    return (
      <div className="scp-schedule-wizard scp-payment-stage">
        <h2 className="gm-select-service__header">Enter Payment Information</h2>
        <PaymentFormV2 {...this.props} />
      </div>
    );
  }
}

PaymentInformationForm.propTypes = {
  loading: PropTypes.bool,
  newPrice: PropTypes.number,
  initialValues: PropTypes.object,
  onSkip: PropTypes.func,
  toPreviousStep: PropTypes.func,
  insurance: PropTypes.object,
  companies: PropTypes.object,
};

export default PaymentInformationForm;
