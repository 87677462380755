import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { activateEmail } from 'ducks/auth/email';
import api from 'services/api/api';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';
import _ from 'lodash';

class AccountActivate extends Component {
  componentDidMount() {
    const {
      dispatch,
      history,
      match: { params },
    } = this.props;
    const { activationToken } = params;
    if (activationToken) {
      // Activate account immediately if activation token provided,
      // or display login dialog on error
      dispatch(activateEmail(activationToken))
        .then(() => {
          if (api.hasToken()) history.push('/patient/home');
        })
        .catch(() => {
          history.push('/');
        });
    } else history.push('/');
  }

  render() {
    return <GlobalLoader />;
  }
}

export default connect()(AccountActivate);
