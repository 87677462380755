import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ZingTreeShell from 'components/scp/zingtreeshell/ZingTreeShell';
import { completeGCNSurvey } from 'ducks/appointment';

class QuestionarieZingtreeForm extends Component {
  componentDidMount() {
    const { surveys, history } = this.props;
    const survey = _.get(surveys, ['0'], {});
    if (_.get(survey, ['isZingtreeCompleted'], false)) {
      history.push('/');
    }
  }
  onResultNodeReach = () => {
    const { appointmentId, dispatch, history } = this.props;
    dispatch(completeGCNSurvey(appointmentId, true))
      .then(() => {
        history.push('/');
      })
      .catch(() => {
        history.push('/');
      });
  };

  onFailureNodeReach = (data) => {
    console.log('do nothing with data', data);
  };
  render() {
    const { surveys } = this.props;
    const survey = _.get(surveys, ['0'], {});
    const zingtreeSessionId = _.get(survey, 'zingtreeSessionId', '');
    const zingtreeTreeId = _.get(survey, 'zingtreeTreeId', '');
    return (
      <div className="questionrie-zingtree">
        {
          <ZingTreeShell
            tree_id={zingtreeTreeId}
            session_id={zingtreeSessionId}
            onResultNodeReach={this.onResultNodeReach}
            onFailureNodeReach={this.onFailureNodeReach}
            className={'min-height'}
            initialSessionData={{ hideScheduleAppointment: true }}
          />
        }
      </div>
    );
  }
}
const mapStateToProps = ({ answers, appointment }) => ({
  appointmentId: appointment.data.id,
  surveyInfoLoaded: answers.loaded,
  surveys: answers.surveys,
});

export default connect(mapStateToProps, (dispatch) => {
  return { dispatch };
})(QuestionarieZingtreeForm);
